import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import { apiGet } from "../../../service/apiService";



  const initialState = {
    Loading:false,
    dashboardData: [],
  
  };

export const getDashboard = createAsyncThunk(
    'dashboard/getDashboard',
    async (_,{rejectWithValue}) => {
        try {
            const response = await apiGet(`dashboard`);
            return response.data;
        } catch (error) {
          return rejectWithValue(error);
        }
    }
);


const Dashboard = createSlice({
    name:'Dashboard',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
      builder
      .addCase(getDashboard.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getDashboard.fulfilled, (state,action) => {
        state.Loading = false;
        state.dashboardData = action.payload
      })
      .addCase(getDashboard.rejected, (state) => {
        state.Loading = false;
      })

    }
  })

  
export default Dashboard.reducer;