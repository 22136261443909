import { createSlice} from '@reduxjs/toolkit'
import { staffGet, addGroup, groupGet, groupGetId, addMsg, groupDelId, editGroupId, deleteMsgInd, deleteMsgGroup } from '../middleware/ChatMiddleware';


const ChatSlice = createSlice({
    name:'Chat',
    initialState:{
        loading:false,
        data:[],
        group:[],
        groupid: {},
        addMessage: "",
        error:null
    },
    extraReducers:(builder)=>{
        builder
    .addCase(staffGet.pending,(state)=>{
        state.loading = true;

    })
    .addCase(staffGet.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.data=action.payload;
        state.error=null;
    })
    
    .addCase(staffGet.rejected,(state,action)=>{
        state.loading = false;        
        state.data = [];    
        state.error=action.message
    })
    .addCase(addGroup.pending,(state)=>{
        state.loading = true;

    })
    .addCase(addGroup.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.data=action.payload;
        state.error=null;
    })
    
    .addCase(addGroup.rejected,(state,action)=>{
        state.loading = false;        
        state.group = [];    
        state.error=action.message
    })
    .addCase(groupGet.pending,(state)=>{
        state.loading = true;

    })
    .addCase(groupGet.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.group=action.payload;
        state.error=null;
    })
    
    .addCase(groupGet.rejected,(state,action)=>{
        state.loading = false;        
        state.group = [];    
        state.error=action.message
    })
    .addCase(groupGetId.pending,(state)=>{
        state.loading = true;
    })
    .addCase(groupGetId.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.groupid=action.payload;
        state.error=null;
    })
    .addCase(groupGetId.rejected,(state,action)=>{
        state.loading = false;        
        state.groupid = {};    
        state.error=action.message
    })
    .addCase(addMsg.pending,(state)=>{
        state.loading = true;
    })
    .addCase(addMsg.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.addMessage=action.payload;
        state.error=null;
    })
    .addCase(addMsg.rejected,(state,action)=>{
        state.loading = false;        
        state.addMessage = "";    
        state.error=action.message
    })
    .addCase(groupDelId.pending,(state)=>{
        state.loading = true;
    })
    .addCase(groupDelId.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.error=null;
    })
    .addCase(groupDelId.rejected,(state,action)=>{
        state.loading = false;        
        state.error=action.message
    })
    .addCase(editGroupId.pending,(state)=>{
        state.loading = true;
    })
    .addCase(editGroupId.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.error=null;
    })
    .addCase(editGroupId.rejected,(state,action)=>{
        state.loading = false;        
        state.error=action.message
    })
    .addCase(deleteMsgInd.pending,(state)=>{
        state.loading = true;
    })
    .addCase(deleteMsgInd.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.error=null;
    })
    .addCase(deleteMsgInd.rejected,(state,action)=>{
        state.loading = false;        
        state.error=action.message
    })
    .addCase(deleteMsgGroup.pending,(state)=>{
        state.loading = true;
    })
    .addCase(deleteMsgGroup.fulfilled,(state,action)=>{
       
        state.loading = false;            
        state.error=null;
    })
    .addCase(deleteMsgGroup.rejected,(state,action)=>{
        state.loading = false;        
        state.error=action.message
    })
}

})

export default ChatSlice.reducer


