import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import { apiGet } from "../../../service/apiService";
import { admissionLabel } from "../../../config/labels/ErrorLabel";



  const initialState = {
    Loading:false,
    dashboardData: [],
    singaporeAdmissionData:[],
    japanAdmissionData:[],
    candidateData: null
  
  };

export const getDashboard = createAsyncThunk(
    'dashboard/getDashboard',
    async (_,{rejectWithValue}) => {
        try {
            const response = await apiGet(`dashboard`);
            return response.data;
        } catch (error) {
          return rejectWithValue(error);
        }
    }
);
export const getCandidateDetails = createAsyncThunk(
    'dashboard/getCandidateDetails',
    async (data,{rejectWithValue}) => {
        try {
            const response = await apiGet(`jot/students?year=${data.filter}&result=${data.result_type}`);
            return response.data;
        } catch (error) {
          return rejectWithValue(error);
        }
    }
);

export const getSingaporeAdmissionData = createAsyncThunk(
  'dashboard/getSingaportAdmissionData', 
  async (data,{rejectWithValue}) => {
    try {
        const response = await apiGet(`overall_admission?year=${data.filter}`);
        return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
}
)

export const getJapanAdmissionData = createAsyncThunk(
  'dashboard/getJapanAdmissionData', 
  async (data,{rejectWithValue}) => {
    try {
        const response = await apiGet(`overall_japaneseadmission?year=${data.filter}`);
        return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
}
)




const Dashboard = createSlice({
    name:'Dashboard',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
      builder
      .addCase(getDashboard.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getDashboard.fulfilled, (state,action) => {
        state.Loading = false;
        state.dashboardData = action.payload
      })
      .addCase(getDashboard.rejected, (state) => {
        state.Loading = false;
      })

      .addCase(getCandidateDetails.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getCandidateDetails.fulfilled, (state,action) => {
        state.Loading = false;
        state.candidateData = action.payload
      })
      .addCase(getCandidateDetails.rejected, (state) => {
        state.Loading = false;
      })

      .addCase(getSingaporeAdmissionData.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getSingaporeAdmissionData.fulfilled, (state,action) => {
        state.Loading = false;
        state.singaporeAdmissionData = action.payload
      })
      .addCase(getSingaporeAdmissionData.rejected, (state) => {
        state.Loading = false;
      })
      
      .addCase(getJapanAdmissionData.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getJapanAdmissionData.fulfilled, (state,action) => {
        state.Loading = false;
        state.japanAdmissionData = action.payload
      })
      .addCase(getJapanAdmissionData.rejected, (state) => {
        state.Loading = false;
      })

    }
  })

  
export default Dashboard.reducer;