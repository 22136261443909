import { ErrorMessage, Field, Formik, Form } from "formik";
import Header from "../../../Navbar/Header";
import Sidebar from "../../sidebar/Sidebar";
import { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { addCompany, deleteCompany, deletecompanyPayroll, getCompany, submitPaySlip, updateCompany } from "../hrmsSlice";
import { Button, Spinner } from "react-bootstrap";
import StyledDataTable from "../../../../component/resuable/StyledComponent";
import { toast } from "react-toastify";
import DeleteDialog from "../../../../component/resuable/deleteDialog";
import { useLocation, useNavigate } from "react-router-dom";
import RunPayroll from "./RunPayroll";
import RunPayrollModal from "./RunPayroll";
import Holiday from "./Holiday";
import ReactDatePicker from "react-datepicker";

export default function Company() {
    const [initialValues, setInitialValues] = useState({
        company_name: '',
    });
    const navigate = useNavigate();
    const [commonLoad, setCommonLoad] = useState(false)
    const [showPayrollDelete, setShowPayrollDelete] = useState(false)
    const dispatch = useDispatch()
    const [rowData, setRowData] = useState([]);
    const companyData = useSelector(state => state.hrms.companyData);
    const location = useLocation();
    const [editData, setEditData] = useState("");
    const [deleteId, setDeleteId] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedData, setselectedData] = useState("");
    const [showHoliday, setShowHoliday] = useState('')

    const validationSchema = Yup.object().shape({
        company_name: Yup.string().required("Company Name is required"),
    });
    const getPreviousMonth = () => {
        const date = new Date(); // Get the current date
        date.setMonth(date.getMonth() - 1); // Set to the previous month
        date.setDate(1); // Optional: Set to the first day of the month
        return date;
    };
    const [filterdate, setFilterdate] =  useState(getPreviousMonth());

    useEffect(() => {
        if (location.pathname === "/hrms") {
            setShowHoliday(''); // Reset to table view when navigating to Resume
        }
    }, [location]);
    const [openPayroll, setOpenPayroll] = useState(false)

    const handleDeleteClick = (data) => {
        setselectedData(data.company_name)
        setDeleteId(data.company_id)
        setEditData(data);
        setShowDeleteDialog(true);
    };
    const deletePayroll = (data) => {
        setShowPayrollDelete(true)
        setDeleteId(data.payroll_id)
        setselectedData(data.company_name)
    }
    const runpayroll = () => {
        setOpenPayroll(true)
    }
    const closePayRoll = () => {
        setOpenPayroll(false)
    }
    const handleHoliday = () => {
        setShowHoliday('addHoliday')
    }
    useEffect(() => {
        if (companyData && Array.isArray(companyData.companies)) {
            const formattedData = companyData.companies.map((data) => ({
                company_name: data.company_name,
                spending_amount: data.spending_amount,
                total_amount: data.total_amount,
                company_id: data.company_id,
                download_link: data.download_link,
                payslip_shared: data.payslip_shared,

                payroll_id: data.payroll_id
            }));
            setRowData(formattedData);
        }
    }, [companyData]);
    const handleSubmit = async (values, { resetForm }) => {
        if (editData && editData.company_id) {
            const response = await dispatch(updateCompany({ ...values, company_id: editData.company_id })).unwrap();
            if (response.status === 200) {
                resetForm();
                setEditData(null);
                setInitialValues({ company_name: '' });
                toast.success('Company Updated Successfully');
            } else {
                toast.error(response?.data?.message);
            }

        } else {
            const response = await dispatch(addCompany(values)).unwrap();
            if (response.status === 201) {
                resetForm()
                toast.success('Company Added Successfully');
            } else {
                toast.error(response?.data?.message)
            }
        }
    };

    function formatDate(date) {
        if (date) {
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return { month, year };
        }
        return { month: '', year: '' };
    }
    useEffect(() => {
        const fetchData = async () => {
            const { month, year } = filterdate ? formatDate(filterdate) : { month: '', year: '' };
            await dispatch(getCompany({
                month: month,
                year: year
            }));
        };

        fetchData();
    }, [dispatch, filterdate]);

    const handleEditClick = (data) => {
        setInitialValues({
            company_name: data.company_name
        });
        setEditData(data);
    };
    const sendPaySlip = async (data) => {
        setCommonLoad(true)
        // const response = await dispatch(submitPaySlip({ company_id: data.company_id }))
        // if (response.payload.status === 200) {
        //     toast.success('Payslip send successfully')
        //     setCommonLoad(false)
        //     window.location.reload();
        // }
        // else {
        //     toast.error('Payslip Not Send')
        //     setCommonLoad(false)
        // }
    }
    const columns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            width: '6%',
        },
        {
            name: 'Name',
            selector: (row) => row.company_name,
            width: '25%',
        },
        {
            name: 'Total Salary',
            selector: (row) => row.total_amount,
            width: '10%',
        },
        {
            name: 'Spend Salary',
            selector: (row) => row.spending_amount,
            width: '11%',
        },
        {
            name: 'Download ',
            selector: (row) => (
                row.download_link ? (
                    <a href={row.download_link} target="_blank" rel="noopener noreferrer">
                        Download
                    </a>
                ) : (
                    <span>Payroll not run</span>
                )
            ),
            width: '10%',
        },
        {
            name: 'Send Payslip',
            cell: (row) => (
                <>
                    {row?.download_link ? (
                        row?.payslip_shared === 0 ? (
                            <Button variant="primary" className="table-custom-button1 px-2" onClick={() => sendPaySlip(row)}>
                                Send Payslips
                            </Button>
                        ) : (
                            <p className="table-viewcustom-withouthover px-2">Payslip sent</p>
                        )
                    ) : (
                        'Payroll not run'
                    )}
                </>
            ),
        },
        // Conditional "Delete Payroll" column
        ...(rowData.some(row => row.download_link) ? [{
            name: "Delete Payroll",
            cell: (row) => (
                <>
                    {row?.download_link && (
                        <Button
                            variant="primary"
                            className="table-custom-button px-2"
                            onClick={() => deletePayroll(row)}
                            style={{ marginRight: '8px' }}
                        >
                            Delete Payroll
                        </Button>
                    )}
                </>
            ),
            button: true,
            width: '11%',
        }] : []),
        {
            name: "Action",
            cell: (row) => (
                <>
                    <Button variant="primary" className="table-custom-button1" onClick={() => handleEditClick(row)}>
                        <i className="fas fa-edit px-2"></i>
                    </Button>
                    <Button variant="danger" className="table-viewcustom-button" onClick={() => navigate('/hrms/employee', { state: { row, filterdate }})}>
                        <i className="fas fa-eye px-2"></i>
                    </Button>
                    <Button variant="primary" className="table-custom-button" onClick={() => handleDeleteClick(row)} style={{ marginRight: '8px' }}>
                        <i className="fas fa-trash px-2"></i>
                    </Button>
                </>
            ),
            button: true,
            width: '15%',
        },
    ];


    const handleDelete = async (id) => {
        const response = await dispatch(deleteCompany(id));
        console.log(response)
        if (response.payload.status === 200) {

            window.location.reload();
            toast.success("Company Data Deleted successfully");
        } else {
            toast.error(response.payload.response.data.message)
        }
    };
    const handledeletePayroll = async (id) => {
        console.log(id)
        const response = await dispatch(deletecompanyPayroll(id));
        console.log(response)
        if (response.payload.status === 200) {

            window.location.reload();
            toast.success("Company Data Deleted successfully");
        } else {
            toast.error(response.payload.response.data.message)
        }
    };

    const handleBack = () => {
        setShowHoliday('')
    }
    const renderMonthContent = (month, shortMonth, longMonth) => {
        const tooltipText = `Tooltip for month: ${longMonth}`;
        return <span title={tooltipText}>{shortMonth}</span>;
    };
    return (
        <>

            {commonLoad && (
                <div className="spinner-container"> {/* Wrap the spinner with a div */}
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>)}
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    {showHoliday === '' && (
                        <div className='col-10 mt-2 px-3'>
                            <div className='card custom-card p-3'>
                                <h4 >Add Your Company</h4>
                                <div className='row justify-content-center'>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                        enableReinitialize={true}
                                    >
                                        {({ values,
                                            setFieldValue, }) => (
                                            <Form>
                                                <div className="row">
                                                    <div className="col-md-4 px-3 py-2">
                                                    <label className='form-label'>Company Name</label>

                                                        <div className="d-flex">
                                                        <Field
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='Enter Company Name'
                                                            name='company_name'
                                                        />
                                                        <ErrorMessage name='company_name' component='div' className="text-danger" />
                                                        <button className="btn defult-button ms-2" type='submit'>Submit</button>
                                                        </div>
                                                    </div>
                                                  
                                                    <div className="col-md-8 px-3 py-2 text-end">
                                                        <button className="btn defult-button ms-2" type="button" onClick={runpayroll}>Update Attendance</button>
                                                    </div>

                                                    
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>

                                <div className='d-flex py-2 '>
                                    <ReactDatePicker className='form-control datefilter' placeholderText='Select filter'
                                        renderMonthContent={renderMonthContent}
                                        showMonthYearPicker
                                        dateFormat="MM/yyyy"
                                        selected={filterdate}
                                        onChange={(date) => {
                                            setFilterdate(date);
                                        }}
                                    />
                                    <button className="table-viewcustom-button p-1 btn btn-primary" onClick={() => setFilterdate(null)}><i class="fa fa-retweet" aria-hidden="true"></i></button>
                                    <h4 className="text-center"></h4>
                                </div>
                                <div className="mt-2">
                                    <StyledDataTable
                                        columns={columns}
                                        data={rowData.length > 0 ? rowData : []}
                                        highlightOnHover
                                        responsive
                                        persistTableHead={true}
                                    />
                                </div>
                            </div>
                            {showDeleteDialog && deleteId && (
                                <DeleteDialog
                                    id={deleteId}
                                    data={selectedData}
                                    onDelete={handleDelete}
                                    onClose={() => setShowDeleteDialog(false)}
                                />
                            )}
                            {openPayroll && (
                                <RunPayrollModal closePayRoll={closePayRoll} onClose={() => setOpenPayroll(false)} />
                            )}
                        </div>)}

                    {/* {showHoliday === 'addHoliday' && (
                        <Holiday handleBack={handleBack} />
                    )} */}


                    {showPayrollDelete && (
                        <DeleteDialog
                            id={deleteId}
                            data={selectedData}
                            onDelete={handledeletePayroll}
                            onClose={() => setShowPayrollDelete(false)}
                        />
                    )}


                </div>
            </div>
        </>
    );
}
