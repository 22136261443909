import { createSlice } from "@reduxjs/toolkit";
import { remarkget,  statusupate } from "../middleware/RemarkMiddleware";

const RemarkSlice = createSlice({
    name: 'Remarks',
    initialState: {
        loading: false,
        // remark: null,
        remark: {
            headers: {
                contentLength: null,
                contentType: null,
            },
        },
        error: null
    },
    extraReducers: (builder) => {
        builder
           
            .addCase(remarkget.pending, (state) => {
                state.loading = true;
            })
            .addCase(remarkget.fulfilled, (state, action) => {
                state.loading = false
                state.error = null;
                state.remark = action.payload
                
            })
            .addCase(remarkget.rejected, (state, action) => {
                state.error = action.payload
                state.loading = false
                state.remark = null;
            })





            .addCase(statusupate.pending, (state) => {
                state.loading = true;
            })
            .addCase(statusupate.fulfilled, (state, action) => {
                state.loading = false
                state.error = null;
                state.remark = action.payload
                
            })
            .addCase(statusupate.rejected, (state, action) => {
                state.error = action.payload
                state.loading = false
                state.remark = null;
            })

    }
})



export default RemarkSlice.reducer
