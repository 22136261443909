import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import './Sidebar.css'

export default function Sidebar(props) {
  const location = useLocation();
  const isCandidateActive = location.pathname.includes('/candidate');
  const isuserActive = location.pathname.includes('/user')
  const isHrmsActive = location.pathname.includes('/hrms')
  const isDashboard = location.pathname === '/' || location.pathname.includes('/dashboard');
  const isRemarks = location.pathname.includes('/remarks')
  const isResume = location.pathname.includes('/resume')
  const isJot = location.pathname.includes('/jot')
  const isadmission = location.pathname.includes('/admission')
  const isJapaneseAdmission = location.pathname.includes('/japanese-admission')

  const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";
  let role = Cookies.get("role");


  const navigate = useNavigate()
  const handleSubMenuClick = (path) => {
    navigate(path)
  }


  return (

    <div className={`sidebar-bg d-flex flex-column ${sidebarClass}`}>
      <ul>
        {(role === 'admin' || role === 'administrator' || role === "staff") && (
          <li className={isDashboard ? 'active' : ''}>
            <Link to='/dashboard'><i className='fas fa-home px-2'></i><span>Dashboard</span></Link>
          </li>)}


        {(role === 'admin' || role=== "staff") && (
          <li className={isCandidateActive ? 'active' : ''}>
            <Link to='/candidate'><i className='fa fa-user-plus px-2'></i><span>Candidates (Master Data)</span></Link>
          </li>)}

        {(role === 'admin' || role === 'administrator') && (
          <li className={`text-nowrap ${isadmission ? 'active' : ''}`}>
            <Link to='/admission'><i class="fas fa-id-card px-2"></i><span>Admission (Singapore)</span></Link>
          </li>)}

        {(role === 'admin') && (
          <>

            <li className={`text-nowrap ${isJapaneseAdmission ? 'active' : ''}`}>
              <Link to='/japanese-admission'><i class="fas fa-file-signature px-2"></i><span>Admission (Japan)</span></Link>
            </li>
            <li className={`text-nowrap ${isJot ? 'active' : ''}`}>
              <Link to='/jot'><i class="fas fa-file-signature px-2"></i><span>JOT</span></Link>
            </li>
            <li className={isuserActive ? 'active' : ''}>
              <Link to='/user'><i className='fa fa-user px-2'></i><span>Users</span></Link>
            </li>
            <li className={`text-nowrap ${isRemarks ? 'active' : ''}`}>
              <Link to='/remarks'><i className='fa fa-handshake px-2'></i><span>Placement/Release</span></Link>
            </li>
            <li className={isHrmsActive ? 'active' : ''} onClick={() => handleSubMenuClick("/hrms")}>
              <Link><i className='fa fa-user px-2'></i><span>HRMS</span></Link>
            </li>
          </>
        )}
        {role === 'hrms' && (
              <li className={isHrmsActive ? 'active' : ''} onClick={() => handleSubMenuClick("/hrms")}>
              <Link><i className='fa fa-user px-2'></i><span>HRMS</span></Link>
            </li>
        )}
        {(role === 'hr') && (
          <li className={`text-nowrap ${isResume ? 'active' : ''}`}>
            <Link to='/resume'><i className='fa fa-handshake px-2'></i><span>Resume</span></Link>
          </li>
        )}
        {(role === 'admin' || role === 'intermediate') && (
          <li className={`text-nowrap ${isResume ? 'active' : ''}`}>
            <Link to='/resume'><i className='fa fa-handshake px-2'></i><span>Resume</span></Link>
          </li>
        )}



        {role === 'manager' && (
          <>

            <li className={isRemarks ? 'active' : ''}>
              <Link to='/remarks'><i className='fa fa-users px-2'></i><span>Placement/Release</span></Link>
            </li>

          </>
        )}
      </ul>

    </div>
  )
}
