import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJapanAdmissionData, getSingaporeAdmissionData } from './DashboardSlice';
import DatePicker from "react-datepicker";
import Cookies from 'js-cookie';




export default function DashboardAdmission() {
    const dispatch = useDispatch()
    const role = Cookies.get("role");
    const [filterdate, setFilterdate] = useState(null)
    const [filterDateJapan, setFilterDateJapan] = useState(null)
    const japanAdmissionData = useSelector((state) => state.DashboardResume.japanAdmissionData);
    const singaporeAdmissionData = useSelector((state) => state.DashboardResume.singaporeAdmissionData);

    console.log("japan", japanAdmissionData);
    console.log("singapore", singaporeAdmissionData);


    function formatDate(date) {
        if (date) {
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${year}`;
        }
    }
    const formattedfilterdate = filterdate ? formatDate(filterdate) : '';
    const formattedJapanfilterdate = filterDateJapan ? formatDate(filterDateJapan) : '';

    useEffect(() => {
        const filter = filterdate !== null ? formattedfilterdate : ""
        const params = {
            filter,
        }
        dispatch(getSingaporeAdmissionData(params));
    }, [filterdate, formattedfilterdate])

    useEffect(() => {
        const filter = filterDateJapan !== null ? formattedJapanfilterdate : ""
        const params = {
            filter,
        }
        if(role === "admin"){
            dispatch(getJapanAdmissionData(params));
        }
    }, [filterDateJapan, formattedJapanfilterdate])
    return (
        <>
            <div className='row mb-3'>
                <div className='row'>
                 {role === "admin" && <div className='col-6'>
                        <h3 >Japan Admission</h3>
                        <div className='row d-flex justify-content-end p-3'>
                            <div className="col-12 p-0">
                                <div className='d-flex'>
                                    <div className='d-flex justify-content-end '>
                                        <DatePicker
                                            className="custom-filter-year"
                                            placeholderText="Select year"
                                            showYearPicker
                                            dateFormat="yyyy"
                                            selected={filterDateJapan}
                                            onChange={(date) => {
                                                setFilterDateJapan(date);
                                            }}
                                        />
                                        <button className="table-viewcustom-button p-1 btn btn-primary btn-focus-none" onClick={() => setFilterDateJapan(null)}><i class="fa fa-retweet" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-dashboard-border'>
                            <div className='card card-bg3 py-3'>
                                <div className='row'>
                                    <div className='col-12  col-md-4'>
                                        <div className="p-3 d-md-flex justify-content-center">
                                            <div className="">
                                                <h6 className="mb-3 tx-12 text-white">OVERALL</h6>
                                                <h4 className="tx-20 font-weight-bold mb-1 text-white">{japanAdmissionData?.JapaneseAdmissionCount}</h4>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='col-12  col-md-4'>
                                        <div className="p-3 d-md-flex justify-content-center">
                                            <div className="">
                                                <h6 className="mb-3 tx-12 text-white">PENDING</h6>
                                                <h4 className="tx-20 font-weight-bold mb-1 text-white">{japanAdmissionData?.pending}</h4>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='col-12  col-md-4'>
                                        <div className="py-3 px-md-2 px-3 d-md-flex justify-content-center">
                                            <div className="">
                                                <h6 className="mb-3 tx-12 text-white">COMPLETED</h6>
                                                <h4 className="tx-20 font-weight-bold mb-1 text-white">{japanAdmissionData?.completed}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <div className='col-6'>
                        <h3 >Singapore Admission</h3>
                        <div className='row d-flex justify-content-end p-3'>
                            <div className="col-12 p-0">
                                <div className='d-flex'>
                                    <div className='d-flex justify-content-end '>
                                        <DatePicker
                                            className="custom-filter-year"
                                            placeholderText="Select year"
                                            showYearPicker
                                            dateFormat="yyyy"
                                            selected={filterdate}
                                            onChange={(date) => {
                                                setFilterdate(date);
                                            }}
                                        />
                                        <button className="table-viewcustom-button p-1 btn btn-primary btn-focus-none" onClick={() => setFilterdate(null)}><i class="fa fa-retweet" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-dashboard-border'>
                            <div className='card card-bg py-3'>
                                <div className='row'>
                                    <div className='col-12  col-md-4'>
                                        <div className="p-3 d-md-flex justify-content-center">
                                            <div className="">
                                                <h6 className="mb-3 tx-12 text-white">OVERALL</h6>
                                                <h4 className="tx-20 font-weight-bold mb-1 text-white">{singaporeAdmissionData?.AdmissionCount}</h4>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='col-12  col-md-4'>
                                        <div className="p-3 d-md-flex justify-content-center">
                                            <div className="">
                                                <h6 className="mb-3 tx-12 text-white">PENDING</h6>
                                                <h4 className="tx-20 font-weight-bold mb-1 text-white">{singaporeAdmissionData?.pending}</h4>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='col-12  col-md-4'>
                                        <div className="py-3 px-md-2 px-3 d-md-flex justify-content-center">
                                            <div className="">
                                                <h6 className="mb-3 tx-12 text-white">COMPLETED</h6>
                                                <h4 className="tx-20 font-weight-bold mb-1 text-white">{singaporeAdmissionData?.completed}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}