import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGet, apiPut } from "../../../service/apiService";
import axios from "axios";
import { baseUrl } from "../../../service/apiService";

const initialState = {
    loading: false,
    appliedExamsList: null,
    getUserExamDetailsById: null,
    userExamDetailsUpdate: null,
    getUserExamDetailsPdf: null
};

export const appliedExamsListGet = createAsyncThunk(
    'jot/appliedExamsListGet',
    async (params, { rejectWithValue }) => {
        try {
            const { page, search, filter, exam_level, result_type } = params;
            const response = await apiGet(`jot/applied/exams?page=${page}&search=${search}&filter=${filter}&exam_level=${exam_level}&result_type=${result_type}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const userExamDetailsGetById = createAsyncThunk(
    'jot/userExamDetailsGetById',
    async (params, { rejectWithValue }) => {
        const {id, applied_date} = params
        try {
            const response = await apiGet(`jot/applied/exams/${id}/${applied_date}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const userExamDetailsUpdate = createAsyncThunk(
    'jot/userExamDetailsUpdate',
    async ({id, data}, { rejectWithValue }) => {
        try {
            const response = await apiPut(`jot/exam/${id}`, data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const userExamDetailsPdfGetById = createAsyncThunk(
    'jot/userExamDetailsPdfGetById',
    async (params, { rejectWithValue }) => {
        const {id, applied_date} = params
        try {
            const response = await axios.get(`${baseUrl}jot/generate_pdf/${id}/${applied_date}`, {
          responseType: 'arraybuffer', 
        });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const JotSlice = createSlice({
    name: "JotSlice",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(appliedExamsListGet.pending, (state) => {
                state.loading = true;
            })
            .addCase(appliedExamsListGet.fulfilled, (state, action) => {
                state.loading = false;
                state.appliedExamsList = action.payload;
            })
            .addCase(appliedExamsListGet.rejected, (state) => {
                state.loading = false;
            })

            .addCase(userExamDetailsGetById.pending, (state) => {
                state.loading = true;
            })
            .addCase(userExamDetailsGetById.fulfilled, (state, action) => {
                state.loading = false
                state.getUserExamDetailsById = action.payload
            })
            .addCase(userExamDetailsGetById.rejected, (state) => {
                state.loading = false;
            })

            .addCase(userExamDetailsUpdate.pending, (state) => {
                state.loading = true
            })
            .addCase(userExamDetailsUpdate.fulfilled, (state, action) => {
                state.loading = false;
                state.userExamDetailsUpdate = action.payload
            })
            .addCase(userExamDetailsUpdate.rejected, (state) => {
                state.loading = false;
            })

            .addCase(userExamDetailsPdfGetById.pending, (state) => {
                state.loading = true;
            })
            .addCase(userExamDetailsPdfGetById.fulfilled, (state, action) => {
                state.loading = false
                state.getUserExamDetailsPdf = action.payload
            })
            .addCase(userExamDetailsPdfGetById.rejected, (state) => {
                state.loading = false;
            })
    }
})

export default JotSlice.reducer