import { createAsyncThunk } from '@reduxjs/toolkit';
import { endPoint } from '../config/endPoint';
import { apiPost } from '../service/apiService';


export const bulkadd = createAsyncThunk("bulkadd", async (payload) => {
    const response = await apiPost(endPoint.bulkadd , payload, "");
  
    return response;
});



