import React from 'react'
import './App.css'

const PageNotFound = () => {
  return (
    <div className='page_not_found_container'>
      <div className='text-center page_not_found_card'>
          <h1 className='not_found_code'>4<span className='not_found_code_middle_digit'>0</span>4</h1>
          <p className='not_found_text'>THE PAGE YOU REQUESTED COULD NOT FOUND</p>
      </div>
    </div>
  )
}

export default PageNotFound