import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appliedExamsListGet } from './JotSlice';
import Pagination from '../../../component/Pagination';
import StyledDataTable from '../../../component/resuable/StyledComponent';
import { format } from 'date-fns';
import LoadingSpinner from '../../../component/resuable/LoadingSpinner';


const User_list = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchdata = props.search
    const filterdate = props.filter
    const filterByExamLevelValue = props.filterByExamLevelValue
    const filterByResultValue = props.filterByResultValue

    const [currentPage, setCurrentPage] = useState(1);
    const [rowData, setRowData] = useState([]);

    const JOT_DATA = useSelector(state => state.jot);
    const { appliedExamsList } = useSelector(state => state.jot);
    const appliedExamListData = appliedExamsList?.data;

    const pageSize = 10;

    useEffect(() => {
        const filter = filterdate !== null ? filterdate : ""
        const search = searchdata !== undefined ? searchdata : ""
        const exam_level = filterByExamLevelValue
        const result_type = filterByResultValue
        const params = {
            page: currentPage,
            search,
            filter,
            exam_level,
            result_type
        }
        dispatch(appliedExamsListGet(params));
    }, [currentPage, searchdata, filterdate, filterByExamLevelValue, filterByResultValue])

    const handleEditClick = (id, applied_date) => {
        navigate(`edit/${id}/${applied_date}`)
    }

    const columns = [

        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "7%"

        },
        {
            name: 'Name',
            selector: (row) => row.first_name, sortable: true,
            width: "10%"
        },
        {
            name: 'Email',
            selector: (row) => row.email, sortable: true,
        },
        {
            name: 'Exam Level',
            selector: (row) => row.exam_level, sortable: true,
            width: "11%"
        },
        {
            name: 'Applied Date',
            selector: (row) => row.applied_at !== null ? format(new Date(row.applied_at), 'dd/MM/yyyy') : "N/A", sortable: true,
            width: "15%"
        },
        {
            name: 'Exam Date',
            selector: (row) => row.exam_date !== null ? format(new Date(row.exam_date), 'dd/MM/yyyy') : "N/A", sortable: true,
            width: "15%"
        },
        {
            name: 'Result',
            selector: (row) => row.result !== null ? row.result : "N/A", sortable: true,
            width: "15%"
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    {/* <Button className='table-viewcustom-button ' onClick={() => handleViewClick(row.user_id, row.applied_at)}><i class="fas fa-eye px-2"></i></Button> */}
                    <Button className='table-viewcustom-button ' onClick={() => handleEditClick(row.user_id, row.applied_at)}><i class="fas fa-edit px-2"></i></Button>
                </>
            ),
            button: true,
            width: "7%"
        },


    ];

    useEffect(() => {
        if (appliedExamListData && Array.isArray(appliedExamListData)) {
            const formattedData = appliedExamListData.map((data) => ({
                first_name: data.first_name + " " + data.last_name,
                email: data.email,
                gender: data.gender,
                exam_level: data.exam_level,
                applied_at: data.applied_at,
                exam_date: data.exam_date,
                result: data.result,
                applied_at: data.applied_at,
                user_id: data.user_id,
            }));
            setRowData(formattedData);
        }
    }, [appliedExamsList]);

    return (
        <>
            {JOT_DATA?.loading && <LoadingSpinner />}
            <div className='' >
                <div className="mt-2">
                    <StyledDataTable
                        columns={columns}
                        data={rowData.length > 0 ? rowData : []}
                        highlightOnHover
                        responsive
                        persistTableHead={true}
                    />
                </div>
            </div>


            <div >
                {appliedExamListData?.length > 0 ?
                    <Pagination
                        className="pagination-bar"
                        currentPage={appliedExamsList?.current_page}
                        totalCount={appliedExamsList?.total_pages}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                    :
                    ''
                }
            </div></>
    )
}

export default User_list