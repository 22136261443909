import React, { useEffect, useState } from 'react'
import Header from '../../Navbar/Header'
import Sidebar from '../sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { staffGet, addGroup, groupGet, groupGetId, groupDelId, editGroupId } from '../../../middleware/ChatMiddleware';
import { json } from 'react-router-dom';
import ChatMessage from './ChatMessage';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import './chat.css';
import Cookies from 'js-cookie';

export default function ChatPage() {

    const role = Cookies.get("role");
    const dispatch = useDispatch();
    const { loading, error, data, group, groupid } = useSelector((state) => state.Chat);
    const [groupList, setGroup] = useState([]);
    const [searchInput, setSearchInput] = useState();
    const [showModal, setShowModal] = useState(false);
    const [addForm, setAddForm] = useState({ "groupName": "", "participants": [] })
    const [members, setMembers] = useState([]);
    const [chatObj, setChatObj] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null);
    const [show, setShow] = useState(false);
    const [editGroupCon, setGroupCon] = useState(false);
    const [isActive, setIsActive] = useState();
    const [selectMebStr, setSelectMebStr] = useState("");
    useEffect(() => {
        dispatch(staffGet())
        dispatch(groupGet())
    }, [])

    useEffect(() => {
        if (data?.status === 200) {
            setMembers(data.data);
        }
    }, [data])

    useEffect(() => {
        if (group?.status === 200) {
            setGroup(group.data)
        }
    }, [group])

    const subAddChatGroup = async () => {
        if (addForm?.groupName.trim() == "") {
            toast("Add Group Name", {
                type: "error",
                autoClose: 2000
            });
            return false;
        }
        if (addForm?.participants && addForm?.participants.length == 0) {
            toast("Select Members", {
                type: "error",
                autoClose: 2000
            });
            return false;
        }
        const formData = new FormData();
        formData.append('logo', selectedFile);
        formData.append('groupName', addForm?.groupName);
        //formData.append('description', addForm?.description); 
        formData.append('participants', addForm?.participants.join(","));
        if (editGroupCon) {
            let editGPdetails = {
                "groupName": addForm?.groupName,
                "data": formData
            }
            let res = await dispatch(editGroupId(editGPdetails));
            setGroupCon(false);
            closeModal();
        } else {
            let res = await dispatch(addGroup(formData))
            if (res.payload.status == 201) {
                closeModal();
                toast(res.payload.data.message, {
                    type: "success"
                });
            } else {
                toast(res.payload.data.error, {
                    type: "error"
                });
            }
        }
    }

    const openChat = async (val) => {
        setIsActive(val);
        setChatObj(null);
        let res = await dispatch(groupGetId(val));
        if (res.payload.status === 200) {
            setChatObj(res.payload.data)
        } else {
            toast(res.payload.data.error, {
                type: "error"
            });
        }
    }

    const addMemberList = (eve) => {
        let val = eve.target.name;
        let checked = eve.target.checked;
        let temArr = addForm["participants"].map((v) => {
            return v;
        });
        if (checked) {
            if (!addForm["participants"].includes(val)) {
                temArr.push(val);
                setSelectMebStr(temArr.join(" "))
            }
        } else {
            let index = addForm["participants"].indexOf(val);
            temArr.splice(index, 1)
            setSelectMebStr(temArr.join(" "))
        }
        setAddForm({ ...addForm, "participants": temArr })
    }

    const removeSelectedMem = (index) => {
        let list = addForm["participants"].map((v) => {
            return v;
        });
        list.splice(index, 1)
        setAddForm({ ...addForm, "participants": list })
        setSelectMebStr(list.join(" "))
    }


    const openModal = () => {
        setAddForm({ "groupName": "", "participants": [], "logo": "" })
        setShowModal(true);
    };

    const activeClass = (val) => {
        setIsActive(val);
        openChat(val);
    }

    const closeModal = async () => {
        setShowModal(false);
        setAddForm({ "groupName": "", "participants": [], "logo": "" })
        await dispatch(staffGet())
        //   setMembers(data.data);
        await dispatch(groupGet())
        setAddForm({ "groupName": "", "participants": [], "logo": "" })
        setSelectMebStr('')
    };

    const updateFormValue = (val) => {
        setAddForm({ ...addForm, "groupName": val })
    }

    const editGroup = async (val) => {
        setGroupCon(true)
        let res = await dispatch(groupGetId(val));
        if (res.payload.status == 200) {
            let resData = res.payload.data;
            openModal()
            setSelectMebStr(resData?.participants.join(" "))
            setAddForm({ "groupName": resData?.groupName, "participants": resData?.participants })
        } else {
            toast(res.payload.data.error, {
                type: "error",
                autoClose: 2000
            });
        }
    }
    const deleteGroup = (val) => {
        setShow(true)
    }
    const onDelete = async () => {
        let data = {
            "id": chatObj?.groupName
        }
        let res = await dispatch(groupDelId(data))
        if (res.payload.status === 200) {
            setShow(false);
            await dispatch(groupGet());
            setChatObj(null)
            toast(res.payload.data.message, {
                type: "success",
                autoClose: 2000
            });
        } else {
            toast(res.payload.data.error, {
                type: "error",
                autoClose: 2000
            });
        }
    }

    const searchGroupName = (val) => {
        if (chatObj && !chatObj.groupName.includes(val)) {
            setChatObj(null);
        }
        setSearchInput(val);
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            {role === 'admin' ?

                                <div className='row header-bar chat-options my-3'>
                                    <div className='col-12 col-sm-4'>
                                        {chatObj ? <div className=''>
                                            <button type="button" className="table-viewcustom-button m-1 btn btn-primary" onClick={() => editGroup(chatObj.groupName)} >
                                                <i className="fa fa-edit px-2"></i> <span>Edit</span> </button>
                                            <button type="button" className="table-custom-button m-1 btn btn-primary" onClick={() => deleteGroup(chatObj.groupName)} >
                                                <i className="fa fa-trash px-2"></i> <span>Delete</span></button>
                                        </div> : ""}
                                    </div>
                                    <div className='col-12 col-sm-4'>
                                        <input className="form-control me-2" onChange={(eve) => searchGroupName(eve.target.value)} type="search" placeholder="Search" aria-label="Search" />
                                    </div>
                                    <div className='col-12 col-sm-4 d-flex justify-content-end align-items-center'>
                                        <button className='btn defult-button defult-button-head m-2 ' onClick={() => { openModal() }} >Add New</button>
                                    </div>
                                </div> : ''}
                            <div className='row g-3 w-100 px-3 chat-msg'>
                                <div className='col-4 col-md-3 chat-user-list'>
                                    <h3 className='text-center'>Chat List</h3>
                                    {groupList.map((val, index) => (
                                        <div key={index} className={`d-flex align-items-center justify-content-between chat-user ${searchInput ? (val?.groupName.includes(searchInput) ? "" : "d-none") : ""} ${chatObj && isActive === val?.groupName ? "active" : ""}`} onClick={() => { openChat(val?.groupName) }}>
                                            <div className='d-flex align-items-center'>
                                                <div className='cus-logo-grp  m-3'>
                                                    
                                                    {val?.logo ? <div className='chatborder'><img src={val?.logo} alt='logo' /></div> : <div className="placeholder-icon"> 
                                                        { val?.groupName.split(' ').slice(0, 2).map(word => word.charAt(0)).join('').toUpperCase()}
                                                    </div>}
                                                </div>
                                                <div className='single-chat-user' key={index} >{val?.groupName}</div>
                                            </div>
                                            {val?.count !== 0 ?
                                                <div className='single-chat-user px-2 me-2 bg-danger text-white rounded-circle'  >{val?.count}</div> : ''}
                                        </div>
                                    ))}


                                </div>
                                <div className={`col-8 col-md-9 chat-box ${chatObj ? '' :
                                    'chart-start-msg'}`}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            {chatObj ? <ChatMessage probs={chatObj}></ChatMessage>
                                                : "Continue To Start Chat"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* for modal box start */}

                        <div className={`modal ${showModal ? 'show' : ''}`}
                            tabIndex="-1"
                            role="dialog"
                            style={{ display: showModal ? 'block' : 'none' }} >
                            <div className="modal-dialog modal-dialog-centered chat-list" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Chat List</h5>
                                        <button type="button" className="btn-close" onClick={closeModal}>
                                            {/* <span aria-hidden="true">&times;</span> */}
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group my-2">
                                            <label className='form-label' htmlFor="groupname">GroupName: </label>
                                            <input type="text" className="form-control" onChange={(eve) => updateFormValue(eve.target.value)} value={addForm['groupName']} id="groupname" placeholder="Enter Group Name" />
                                        </div>

                                        <div className="form-group my-2">
                                            <label className='form-label'>Logo: </label>
                                            <input type="file" onChange={(eve) => setSelectedFile(eve.target.files[0])} className="form-control form-control-file" />
                                        </div>
                                        <div className="form-group my-2 group-member">
                                            <label htmlFor="groupmembers">Members</label>
                                            <ul className='d-flex flex-wrap'>
                                                {addForm["participants"].map((val, index) => (
                                                    <li className='m-1' key={index}>{val} <span onClick={() => { removeSelectedMem(index) }} className='text-danger'>X</span></li>
                                                ))}
                                            </ul>
                                            <label className='form-label pe-2'>Select an option: </label>

                                            {members.map((val, index) => (
                                                <div key={index} className="form-check form-check-inline">
                                                    <input className="form-check-input" checked={selectMebStr.indexOf(val) >= 0 ? true : false} type="checkbox" name={val} onChange={(e) => { addMemberList(e) }} />

                                                    <label className="form-check-label">{val}</label>
                                                </div>
                                            ))}


                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="cancel-button ms-2" onClick={closeModal}>
                                            Close
                                        </button>
                                        <button type="submit" onClick={() => subAddChatGroup()} className="btn defult-button ms-2" >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {showModal && <div className="modal-backdrop show"></div>}

                        <Modal show={show}>
                            <Modal.Header>
                                <Modal.Title>Confirm Deletion</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete this <b>{chatObj?.groupName}</b>?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="btn btn-secondary" onClick={() => { setShow(false) }}>
                                    Cancel
                                </Button>
                                <Button variant="danger" className='model-save-button btn btn-primary' onClick={() => onDelete()} >
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* for modal box end */}
                    </div>
                </div>
            </div>
        </>
    )
}
