import React, { useRef, useState } from 'react'

import Sidebar from '../sidebar/Sidebar'
import Header from '../../Navbar/Header'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { admissionLabel, candidateErrorLabel } from '../../../config/labels/ErrorLabel';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import LoadingSpinner from '../../../component/resuable/LoadingSpinner';
import { postAdmission } from './admissionSlice';
export default function AdmissionAdd() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const fileInputRef = useRef(null);

    const { Loading } = useSelector((state) => state.admission);
    const [initialState] = useState({
        name: '',
        admission_number: "",
        ad_fees: "",
        date_of_admission: new Date(),
        dob: null,
        passport_no: '',
        height: '',
        weight: '',
        contact: '',
        trade: "",
        marital_status: "",
        nationality: "",
        religion: "",
        communication: "",
        reference: "",
        date_of_issue: null,
        date_of_expiry: null,
        place_of_issue: "",
        educational_qualification: "",
        additional_qualification: "",
        english_knowledge: "",
        working_experience: "",
        profile_pic: "",
        sign: "",
        remarks: "",
        educationalCertificate: '',
        medicalReport: '',
        passportCopy: '',
        vaccinationCertificate: '',
        fatherName: '',
        tshirtSize: '',
        shoeSize: '',
        email: '',
        csoc: '',
        emergencyContactNumber: '',
        agentContactNumber: '',
    })
    const admissionSchema = Yup.object().shape({
        name: Yup.string().required(candidateErrorLabel.name_required),
        admission_number: Yup.string().required(admissionLabel.ad_no_required),
        passport_no: Yup.string().required(candidateErrorLabel.passport_number_required),
    })
    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${year}-${month}-${day}`;
    }

    const AddAdmission = (values) => {
        const formattedDob = values.dob ? formatDate(values.dob) : '';
        const formattedPassportExpiry = values.date_of_expiry ? formatDate(values.date_of_expiry) : '';
        const formattedDateOfIssue = values.date_of_issue ? formatDate(values.date_of_issue) : '';
        const formattedDateOfAdmission = values.date_of_admission ? formatDate(values.date_of_admission) : '';

        const formData = new FormData();

        formData.append('name', values.name);
        formData.append('admission_number', values.admission_number)
        formData.append('fatherName', values.fatherName)
        formData.append('ad_fees', values.ad_fees)
        formData.append('date_of_admission', formattedDateOfAdmission)
        formData.append('dob', formattedDob)
        formData.append('nationality', values.nationality)
        formData.append('religion', values.religion)
        formData.append('place_of_issue', values.place_of_issue)
        formData.append('educational_qualification', values.educational_qualification)
        formData.append('additional_qualification', values.additional_qualification)
        formData.append('english_knowledge', values.english_knowledge)
        formData.append('working_experience', values.working_experience)
        formData.append('reference', values.reference)
        formData.append('passport_no', values.passport_no);
        formData.append('date_of_expiry', formattedPassportExpiry);
        formData.append('height', values.height);
        formData.append('weight', values.weight);
        formData.append('contact', values.contact);
        formData.append('marital_status', values.marital_status);
        formData.append('trade', values.trade);
        formData.append('remarks', values.remarks);
        formData.append('communication', values.communication);
        formData.append('date_of_issue', formattedDateOfIssue)
        formData.append('profile_pic', values.profile_pic);
        formData.append('sign', values.sign);
        formData.append('passportCopy', values.passportCopy);
        formData.append('vaccinationCertificate', values.vaccinationCertificate);
        formData.append('educationalCertificate', values.educationalCertificate);
        formData.append('medicalReport', values.medicalReport);
        formData.append("applied", "Submit");
        formData.append('tshirtSize', values.tshirtSize);
        formData.append('shoeSize', values.shoeSize);


        formData.append('csoc', values.csoc);
        formData.append('email', values.email);
        formData.append('emergencyContactNumber', values.emergencyContactNumber);
        formData.append('agentContactNumber', values.agentContactNumber);

        dispatch(postAdmission(formData)).then(res => {
            if (res?.payload?.status === 201) {
                toast.success(res?.payload?.data?.message)
                navigate(-1);
            } else {
                toast.error(res?.payload?.data?.message)
            }
        });


    };


    const cancelsubmit = () => {
        navigate(-1);
    }

    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />

                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row mb-3 mt-2'>
                                <Formik
                                    initialValues={initialState}
                                    validationSchema={admissionSchema}
                                    onSubmit={AddAdmission} >
                                    {({
                                        values,
                                        setFieldValue,
                                    }) => (
                                        < Form >
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Admission Number</label>
                                                    <Field className='form-control' placeholder='Enter Admission Number' name="admission_number" />
                                                    <ErrorMessage name='admission_number' component='div' className="text-danger" />
                                                </div>

                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Date of Admission</label>
                                                    <DatePicker className='form-control' placeholderText='Select Your Date of Admission'
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        dateFormat='dd/MM/yyyy'
                                                        selected={values.date_of_admission}
                                                        onChange={(date) => { setFieldValue('date_of_admission', date) }}
                                                        // readOnly
                                                        name="date_of_admission"
                                                    />
                                                </div>
                                            </div>
                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-4 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Name</label>
                                                    <Field type='text' className='form-control' placeholder='Enter Your Name' name='name' />
                                                    <ErrorMessage name='name' component='div' className="text-danger" />
                                                </div>
                                                <div className='col-sm-4 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Date of Birth</label>
                                                    <DatePicker className='form-control' placeholderText='Select Your Date of Birth'
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        dateFormat='dd/MM/yyyy'
                                                        selected={values.dob} onChange={(date) => { setFieldValue('dob', date) }} />
                                                </div>

                                                <div className='col-sm-4 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Father Name</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Father Name Number' name="fatherName" />
                                                    <ErrorMessage name='fatherName' component='div' className="text-danger" />
                                                </div>

                                            </div>




                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-3 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport Number</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Passport Number' name="passport_no" />
                                                    <ErrorMessage name='passport_no' component='div' className="text-danger" />
                                                </div>
                                                <div className='col-sm-3 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport Issued Date</label>
                                                    <DatePicker className='form-control' placeholderText='Select Passport Issued Date'
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        dateFormat='dd/MM/yyyy'
                                                        selected={values.date_of_issue} onChange={(date) => { setFieldValue('date_of_issue', date) }} />
                                                </div>
                                                <div className='col-sm-3 mb-2 mb-sm-0 mt-sm-2'>
                                                    <label className='form-label'>Passport Expiry Date</label>
                                                    <DatePicker className='form-control' placeholderText='Select Your Passport Expiry Date'
                                                        dateFormat='dd/MM/yyyy'

                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        selected={values.date_of_expiry} onChange={(date) => setFieldValue('date_of_expiry', date)} />
                                                </div>
                                                <div className='col-sm-3 mb-2 mb-sm-0 mt-sm-2'>
                                                    <label className='form-label'>Passport Issued Place</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Place' name="place_of_issue" />
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Nationality</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Nationality' name="nationality" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Religion</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Religion' name="religion" />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Educational Qualification</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Educational qualification' name="educational_qualification" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Additional Qualification</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Additional Qualification' name="additional_qualification" />
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Marital Status</label>
                                                    <Field as="select" className='form-select' name='marital_status'>
                                                        <option value="" selected hidden label="Select an option" />
                                                        <option value="Married">Married</option>
                                                        <option value="UnMarried">UnMarried</option>
                                                    </Field>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Trade</label>
                                                    <Field type="text" className='form-control' placeholder='Enter trade' name='trade' />
                                                    <ErrorMessage name='trade' component='div' className="text-danger" />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Working Experience</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Working Experience' name='working_experience' />
                                                    <ErrorMessage name='working_experience' component='div' className="text-danger" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>English Knowledge</label>
                                                    <Field as="select" className='form-select' name='english_knowledge'>
                                                        <option value="" selected hidden label="Select an option" />
                                                        <option value="Good">Good</option>
                                                        <option value="Average">Average</option>
                                                        <option value="Poor">Poor</option>
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Contact Number</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Contact Number' name="contact" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Communication Address</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Communication Address' name="communication" />
                                                </div>

                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Reference</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Reference' name="reference" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Remarks</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Remarks' name="remarks" />
                                                </div>
                                            </div>
                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Height</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Height' name="height" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Weight</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Weight' name="weight" />
                                                </div>
                                            </div>


                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Shoe Size</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Shoe Size' name="shoeSize" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>T-Shirt Size</label>
                                                    <Field type="text" className='form-control' placeholder='Enter T-Shirt Size' name="tshirtSize" />
                                                </div>
                                            </div>


                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>CSOC</label>
                                                    <Field as="select" className='form-select' name='csoc'>
                                                        <option value="" selected hidden label="Select an option" />
                                                        <option value="TAMIL">Tamil</option>
                                                        <option value="Engli">English</option>
                                                    </Field>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Email</label>
                                                    <Field className='form-control' placeholder='Enter Your Email' name='email' />
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Emergency Contact Number</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Emergency Contact Number' name="emergencyContactNumber" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Agent Contact Number</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Agent Contact Number' name="agentContactNumber" />
                                                </div>
                                            </div>


                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Admission Fee</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Admission Fee' name="ad_fees" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Full size photo</label>
                                                    <input type="file" className='form-control' placeholder='Enter your photo' onChange={(event) => {
                                                        setFieldValue('profile_pic', event.currentTarget.files[0]);
                                                    }} />

                                                    {values.profile_pic && (
                                                        <div className='mt-2 profileimage'>
                                                            {values.profile_pic.type.includes('image') ? (
                                                                <img
                                                                    src={URL.createObjectURL(values.profile_pic)}
                                                                    alt="Selected Profile "
                                                                />
                                                            ) : (
                                                                <embed
                                                                    src={URL.createObjectURL(values.profile_pic)}

                                                                    type="application/pdf"
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>

                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Signature</label>
                                                    <input type="file" className='form-control' placeholder='Enter your signature' onChange={(event) => {
                                                        setFieldValue('sign', event.currentTarget.files[0]);
                                                    }} />

                                                    {values.sign && (
                                                        <div className='mt-2 profileimage'>
                                                            {values.sign.type.includes('image') ? (
                                                                <img
                                                                    src={URL.createObjectURL(values.sign)}
                                                                    alt="Selected Signature"
                                                                />
                                                            ) : (
                                                                <embed
                                                                    src={URL.createObjectURL(values.sign)}

                                                                    type="application/pdf"
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <div className='col'>
                                                        <label className='form-label'>Passport</label>
                                                        <input
                                                            type="file"
                                                            className='form-control'
                                                            onChange={(event) => {
                                                                setFieldValue('passportCopy', event.currentTarget.files[0]);
                                                            }}
                                                        />
                                                        {values.passportCopy && (
                                                            <div className='mt-2 profileimage'>
                                                                {values.passportCopy.type.includes('image') ? (
                                                                    <img
                                                                        src={URL.createObjectURL(values.passportCopy)}
                                                                        alt="Selected passportCopy"
                                                                    />
                                                                ) : (
                                                                    <embed
                                                                        src={URL.createObjectURL(values.passportCopy)}

                                                                        type="application/pdf"
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Educational Certificate</label>
                                                    <input type="file" className='form-control' placeholder='Enter your name'

                                                        onChange={(event) => {
                                                            setFieldValue('educationalCertificate', event.currentTarget.files[0]);
                                                        }} />

                                                    {values.educationalCertificate && (
                                                        <div className='mt-2 profileimage'>
                                                            {values.educationalCertificate.type.includes('image') ? (
                                                                <img
                                                                    src={URL.createObjectURL(values.educationalCertificate)}
                                                                    alt="Selected educationalCertificate"
                                                                />
                                                            ) : (
                                                                <embed
                                                                    src={URL.createObjectURL(values.educationalCertificate)}

                                                                    type="application/pdf"
                                                                />
                                                            )}
                                                        </div>
                                                    )}



                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Medical Report</label>
                                                    <input type="file" className='form-control' placeholder='Enter your name' onChange={(event) => {
                                                        setFieldValue('medicalReport', event.currentTarget.files[0]);
                                                    }} />
                                                    {values.medicalReport && (
                                                        <div className='mt-2 profileimage'>
                                                            {values.medicalReport.type.includes('image') ? (
                                                                <img
                                                                    src={URL.createObjectURL(values.medicalReport)}
                                                                    alt="Selected medicalReport"
                                                                />
                                                            ) : (
                                                                <embed
                                                                    src={URL.createObjectURL(values.medicalReport)}

                                                                    type="application/pdf"
                                                                />
                                                            )}
                                                        </div>
                                                    )}



                                                </div>

                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Vaccination Certificate</label>
                                                    <input ref={fileInputRef} type="file" className='form-control' placeholder='Enter your name'
                                                        onChange={(event) => {
                                                            setFieldValue('vaccinationCertificate', event.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    {values.vaccinationCertificate && (
                                                        <div className='mt-2 profileimage'>
                                                            {values.vaccinationCertificate.type.includes('image') ? (
                                                                <img
                                                                    src={URL.createObjectURL(values.vaccinationCertificate)}
                                                                    alt="Selected vaccinationCertificate"
                                                                />
                                                            ) : (
                                                                <embed
                                                                    src={URL.createObjectURL(values.vaccinationCertificate)}

                                                                    type="application/pdf"
                                                                />
                                                            )}
                                                        </div>
                                                    )}



                                                </div>

                                            </div>

                                            <div className='px-4 mt-3'>
                                                <button className='cancel-button ms-2' type='button' onClick={cancelsubmit}>Cancel</button>
                                                <button className="btn defult-button ms-2" type='submit' disabled={Loading}>Submit</button>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                                {Loading ?
                                    <LoadingSpinner /> : ""}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}