import React, { useEffect, useState } from 'react'

import Sidebar from '../sidebar/Sidebar'
import Header from '../../Navbar/Header';
import { useDispatch, useSelector } from 'react-redux';
import { overallcount, overallcountget } from '../../../middleware/CountMiddleware';

import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import DashboardResume from './DashboardResume';
import DashboardCandidate from './DashboardCandidate';
import DashboardAdmission from './DashboardAdmission';
export default function Dashboard() {
	const dashboardData = useSelector(state => state.Dashboard);

	const [dashboardModifideData, setDashboardModifideData] = useState('');

	const dispatch = useDispatch()

	const count = useSelector(state => state.Count)

	const dashboardcount = useSelector(state => state.Dashboard)
	const role = Cookies.get("role");

	// staff data
	const [staffoverallcount, setStaffoverallcount] = useState('')
	const [staffpendingcount, setStaffpendingcount] = useState('')
	const [staffcompletedcount, setStaffcompletedcount] = useState('')


	// manager data
	const [getoverallcount, setGetoverallcount] = useState('')
	const [getpendingcount, setGetPendingcount] = useState('')
	const [getcompletedcount, setGetCompletedcount] = useState('')
	const [ipacount, setIpacount] = useState('')
	const [releasecount, setReleasecount] = useState('')
	const [pendingcount, setPendingcount] = useState('')

	//const dispatch = useDispatch()


	useEffect(() => {
		if (role === "admin" || role === "staff") {
			dispatch(overallcount())
			dispatch(overallcountget())
		}
	}, [dispatch])

	useEffect(() => {
		if (count?.count?.status === 200) {
			//staff data
			setStaffoverallcount(count?.count?.data?.staff_data?.staff_all_data)
			setStaffpendingcount(count?.count?.data?.staff_data?.staff_pending_data)
			setStaffcompletedcount(count?.count?.data?.staff_data?.staff_completed_data)

			// manager data
			setGetoverallcount(count?.count?.data?.manager_data?.manager_all_data)
			setGetPendingcount(count?.count?.data?.manager_data?.manager_non_filling_data)
			setGetCompletedcount(count?.count?.data?.manager_data?.manager_completed_data)
			setIpacount(count?.count?.data?.manager_data?.managerIPA_data)
			setReleasecount(count?.count?.data?.manager_data?.manager_release_data)
			setPendingcount(count?.count?.data?.manager_data?.manager_pending_data)

		}

		if (dashboardData?.overall?.status == 200) {
			setDashboardModifideData(dashboardData?.overall?.data)

		}
	}, [count, dashboardData])

	return (
		<div>
			<div className='container-fluid'>
				<div className='row'>
					<Header />
					<div className='col-2 p-0'>
						<Sidebar />
					</div>
					<div className='col-10 my-3'>
						<div className='row px-md-3'>
							<div className='card custom-card'>
								<div className='row mb-3 mt-2'>
									{role === 'admin' || role === "staff" ?
										<div className='col-12 col-md-4 d-flex align-items-center '>
											<div>
												<div className='mt-2 text-center'>
													<h3 >{role === "staff" ? "Candidate Dashboard" :"Enrollment Dashboard"}</h3>
												</div>

												<div className='card-dashboard-border mt-4'>
													<div className='card card-bg py-3'>
														<div className='row'>
															<div className='col-12  col-md-4'>
																<div className="p-3 d-md-flex justify-content-center">
																	<div className="">
																		<h6 className="mb-3 tx-12 text-white">OVERALL</h6>
																		<h4 className="tx-20 font-weight-bold mb-1 text-white">{staffoverallcount ? staffoverallcount : 0}</h4>
																	</div>

																</div>
															</div>

															<div className='col-12  col-md-4'>
																<div className="p-3 d-md-flex justify-content-center">
																	<div className="">
																		<h6 className="mb-3 tx-12 text-white">PENDING</h6>
																		<h4 className="tx-20 font-weight-bold mb-1 text-white">{staffpendingcount ? staffpendingcount : 0}</h4>
																	</div>

																</div>
															</div>

															<div className='col-12  col-md-4'>
																<div className="py-3 px-md-2 px-3 d-md-flex justify-content-center">
																	<div className="">
																		<h6 className="mb-3 tx-12 text-white">COMPLETED</h6>
																		<h4 className="tx-20 font-weight-bold mb-1 text-white">{staffcompletedcount ? staffcompletedcount : 0}</h4>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

										</div> : ''}
									{role === 'manager' ? (
										<div className='col-12 col-md-8' >
											<div className='mt-2 text-center'>
												<h3>Manager Dashboard</h3>
											</div>
											<div className='row'>
												<div className='col-12 col-md-6 card-dashboard-border mt-3'>
													<div className='card card-bg2 py-3'>
														<div className='row'>
															<div className='col-12 col-md-6'>
																<div className="p-3 d-md-flex justify-content-center">
																	<div className="">
																		<h6 className="mb-3 tx-12 text-white text-uppercase">IPA</h6>
																		<h4 className="tx-20 font-weight-bold mb-1 text-white">{ipacount}</h4>
																	</div>
																</div>
															</div>

															<div className='col-12 col-md-6'>
																<div className="p-3 d-md-flex justify-content-center">
																	<div className="">
																		<h6 className="mb-3 tx-12 text-white text-uppercase">Realsed</h6>
																		<h4 className="tx-20 font-weight-bold mb-1 text-white">{releasecount}</h4>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className='col-12 col-md-6 card-dashboard-border mt-3'>
													<div className='card card-bg3 py-3'>
														<div className='row'>
															<div className='col-12 col-md-6'>
																<div className="p-3 d-md-flex justify-content-center">
																	<div className="">
																		<h6 className="mb-3 tx-12 text-white text-uppercase">overall</h6>
																		<h4 className="tx-20 font-weight-bold mb-1 text-white">{getoverallcount}</h4>
																	</div>

																</div>
															</div>

															<div className='col-12 col-md-6'>
																<div className="p-3 d-md-flex justify-content-center">
																	<div className="">
																		<h6 className="mb-3 tx-12 text-white text-uppercase">Pending</h6>
																		<h4 className="tx-20 font-weight-bold mb-1 text-white">{pendingcount}</h4>
																	</div>

																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>) :
										role === 'admin' ? (

											<div className='col-12 col-md-8' >
												<div className='table-responsive border border-1'>
													<table className='table table-striped-custom table-hover'>
														<thead className='table-head'>
															<tr>
																<th className='text-center'>Year/Month</th>
																<th className='text-center'>Over All</th>
																<th className='text-center'>IPA</th>
																<th className='text-center'>Release</th>
																<th className='text-center'>Retest</th>
																<th className='text-center'>Result</th>
																<th className='text-center'>Pending</th>
															</tr>
														</thead>
														<tbody>


															{dashboardModifideData !== '' ?
																(dashboardModifideData.map((data, index) => (
																	<tr key={index}>
																		<td className='text-center'>{data.year}</td>
																		<td className='text-center'>{data.ALL_DATA}</td>
																		<td className='text-center'>{data.IPA}</td>
																		<td className='text-center'>{data.RELEASED}</td>
																		<td className='text-center'>{data.RETEST}</td>
																		<td className='text-center'>{data.RESULT}</td>
																		<td className='text-center'>{data.PENDING}</td>
																	</tr>
																))) :
																<tr>
																	<td colSpan="5" className='text-center'>No data available</td>
																</tr>}
														</tbody>
													</table>
												</div>
											</div>

										) : null}
								</div>
								<>
									{role === 'admin' ?
										<>
											<div className='mt-4 '>
												<h3 >JOT Dashboard</h3>
											</div>
											<DashboardCandidate /></> : ''}
								</>
								<>
									{role === 'admin' || role === "administrator" ?
										<>
											<DashboardAdmission /></> : ''}
								</>

								<>
									{role === 'admin' || role === 'hr' || role === 'intermediate' ?
										<>
											<div className='mt-2 '>
												<h3 >HR Resume Dashboard</h3>
											</div>
											<DashboardResume /></> : ''}
								</>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}
