import { useEffect, useState } from 'react';
import icon1 from '../../../assests/images/icon1.png';
import icon2 from '../../../assests/images/icon2.png';
import icon3 from '../../../assests/images/icon3.png';
import icon4 from '../../../assests/images/icon4.png';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboard } from './DashboardSlice';
import StyledDataTable from '../../../component/resuable/StyledComponent';



export default function DashboardResume() {
    const dispatch = useDispatch()
    const dashboardData = useSelector((state) => state.DashboardResume.dashboardData);

    useEffect(() => {
        dispatch(getDashboard())
    }, [])
    console.log(dashboardData)

    const columns = [

        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: '25%'

        },
        {
            name: 'Skill',
            selector: (row) => row.skill, sortable: true,
            width: '50%'
        },
        {
            name: 'Count',
            selector: (row) => row.candidate_count, sortable: true,
            width: '25%'
        },

    ];

    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    const [rowData, setRowData] = useState([]);

    const chunkedData = chunkArray(rowData, 10);

    useEffect(() => {
        if (dashboardData && Array.isArray(dashboardData.skill_wise_candidates)) {
            const formattedData = dashboardData.skill_wise_candidates.map((user) => ({
                candidate_count: user.candidate_count,
                skill: user.skill,
            }));
            setRowData(formattedData);
        }
    }, [dashboardData]);
    console.log(dashboardData?.status_counts?.[1]?.count)
    return (
        <>
            <div className='row'>
                <div className="col-md-3">
                    <div className="card dashboard1 p-3 d-flex flex-row align-items-center">
                        <img src={icon1} alt="image" className="img-fluid me-3" style={{ width: '50px', height: '50px' }} />
                        <div>
                            <h5>{dashboardData?.resume_count}</h5>
                            <p>Total Resume</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card dashboard2 p-3 d-flex flex-row align-items-center">
                        <img src={icon2} alt="image" className="img-fluid me-3" style={{ width: '50px', height: '50px' }} />
                        <div>
                            <h5>{dashboardData?.current_month_resume_count}</h5>
                            <p>Current Month Resume</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card dashboard3 p-3 d-flex flex-row align-items-center">
                        <img src={icon3} alt="image" className="img-fluid me-3" style={{ width: '50px', height: '50px' }} />
                        <div>
                            <h5>{dashboardData?.status_counts?.[0]?.count !== undefined ? dashboardData.status_counts[0].count : 0}</h5>
                          
                            <p>Immediate Joiner Resume</p>
                        </div>
                        
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card dashboard4 p-3 d-flex flex-row align-items-center">
                        <img src={icon4} alt="image" className="img-fluid me-3" style={{ width: '50px', height: '50px' }} />
                        <div>
                            <h5>{dashboardData?.status_counts?.[1]?.count !== undefined ? dashboardData.status_counts[1].count : 0}</h5>
                            <p>Notice period Resume</p>
                        </div>

                    </div>
                </div>
            </div>
            <div className='row mt-5' >
                <div className='col-md-12'>
                    <div className="mt-2  mb-3" >

                        <div className='d-flex gap-3' >
                            {chunkedData.map((dataChunk, index) => (
                                <div style={{ width: '33%', border: '1px solid rgba(0, 0, 0, .12)' }}>
                                    <StyledDataTable
                                        key={index}
                                        columns={columns}
                                        data={dataChunk}
                                        highlightOnHover
                                        paginationServer
                                        responsive
                                        persistTableHead={true}
                                    />
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>




        </>
    )
}