import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { userExamDetailsPdfGetById } from './JotSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../../component/resuable/LoadingSpinner';
import Header from '../../Navbar/Header';
import Sidebar from '../sidebar/Sidebar';

const ViewUserInfo = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id, applied_date } = useParams()
    const [pdfUrl, setPdfUrl] = useState()

    const JOT_DATA = useSelector(state => state.jot);
    const { getUserExamDetailsPdf } = useSelector(state => state.jot);

    const handleBackClick = () => {
        navigate(-1)
    }

    useEffect(() => {
        if (!getUserExamDetailsPdf) {
            const params = {
                id: id,
                applied_date: applied_date
            }
            dispatch(userExamDetailsPdfGetById(params))
        } else {
            const blob = new Blob([getUserExamDetailsPdf?.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            setPdfUrl(url);
        }
    }, [dispatch, id, applied_date, getUserExamDetailsPdf])
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                  {JOT_DATA?.loading ? <LoadingSpinner /> :  <div className='col-10 mt-2 px-3'>
                        <div className='pdfContainer'>
                            <div className='back-btn-container'>
                                <button className='back-btn' onClick={handleBackClick}>Back</button>
                            </div>
                            <embed src={`${pdfUrl}`} type='application/pdf' width='100%' className='pdf' />
                        </div>
                    </div>}
                </div>
            </div>

        </>
    )
}

export default ViewUserInfo