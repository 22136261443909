import { ErrorMessage, Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { employeeBulkAdd } from '../hrmsSlice';
import { toast } from 'react-toastify';
export default function AddBulkemployee({ closeBulkEmployee,companydetails }) {
  const dispatch = useDispatch()

  const fileInputRef = useRef(null);
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    closeBulkEmployee();
  };
  const validationSchema = Yup.object().shape({
    csvFile: Yup.mixed().required('CSV file is required'),
});
  const handleSubmit = async (values, { resetForm }) => {
    console.log(values)
    const formData = new FormData();
    formData.append('file', values.csvFile);
    const response = await dispatch(employeeBulkAdd({ 
      formData, 
      companyId: companydetails?.company_id 
    }));
    
    if(response.payload.status === 201 ){
      toast.success('Employee added successfully')
      handleClose()
      window.location.reload();


    }else{
      toast.error(response.payload.data.message)
    }
    // resetForm()
}

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Bulk Employee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ csvFile: null }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}

        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
            resetForm
          }) => (
            <Form>
              <div className='col-md-12  mt-2'>
                <label className='form-label'>Upload the Excel </label>
                <input ref={fileInputRef}
                  type='file'
                  accept='.xlsx, .xls'
                  className='form-control'
                  placeholder='Enter your name'
                  name='csvFile'
                  onChange={(event) => {
                    setFieldValue('csvFile', event.currentTarget.files[0]);
                  }}
                />
                <ErrorMessage name='csvFile' component='div' className='text-danger' />


              </div>
              <div className='mt-3'>
               <button className='cancel-button ms-2' onClick={handleClose}>Cancel</button>

                <button className='btn defult-button ms-2' type='submit' disabled={!values.csvFile}>
                  Submit
                </button>
              </div>

            </Form>
          )}
        </Formik>
      </Modal.Body>
      
    </Modal>
  )
}