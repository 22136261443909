import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/account/Login";

import './App.css'
import Candidatelist from "./pages/layouts/candidate/Candidatelist";
import CandidateAdd from "./pages/layouts/candidate/CandidateAdd";
import BulkAdd from "./pages/layouts/bulkadd/BulkAdd";
import Staff from "./pages/layouts/staff/Staff";
import StaffAdd from "./pages/layouts/staff/StaffAdd";
import Dashboard from "./pages/layouts/dashboard/Dashboard"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
import { useEffect, useState } from "react";
import StaffEditForm from "./pages/layouts/staff/StaffEditForm";
import CandidateEdit from "./pages/layouts/candidate/CandidateEdit";

import Cookies from 'js-cookie';
import ChatPage from "./pages/layouts/chat/Chat";
import Remarks from "./pages/layouts/remarks/Remarks";
import Profile from "./pages/account/profile";
import NotificationModal from "./pages/modal/NotificationModal";
import Resume from "./pages/layouts/resume/Resume";
import Company from "./pages/layouts/hrms/company/Company";
import Employee from "./pages/layouts/hrms/employee/Employee";
import IntermediateResume from "./pages/layouts/resume/Intermediate_resume";
import EditUserInfo from "./pages/layouts/jot/EditUserInfo";
import Jot from "./pages/layouts/jot/Jot";




function App() {
  const [showModal, setShowModal] = useState(false);
  const role = Cookies.get("role");

  const navigate = useNavigate()

  const onClose = () => {
    setShowModal(false);
  }
  axios.interceptors.request.use(
    (request) => {
        const token = Cookies.get("access_token")
        if (!token) {
          navigate('/login')
        }
        request.headers.Authorization = `${token}`;
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (request) => {
      return request;
    },
    (error) => {

      return Promise.reject(error);
    }
  );


  const token = Cookies.get("access_token");
  useEffect(() => {

    if (!token) {
      navigate("/login");
    }


  }, [token]);


  useEffect(() => {
    const triggerEvery30Minutes = () => {
      if (!window.location.href.includes('login')) {
        setShowModal(true); // Replace with your desired action
      }
    };

    const calculateTimeUntilNextInterval = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinutes = now.getMinutes();

      let timeUntilNextInterval = 0;

      // Calculate the minutes remaining for the next half-hour interval
      if (currentMinutes < 30) {
        timeUntilNextInterval = (30 - currentMinutes) * 60 * 1000;
      } else {
        // If it's already past 30 minutes, calculate time until the next hour
        timeUntilNextInterval = (60 - currentMinutes) * 60 * 1000;
      }
      return timeUntilNextInterval;
    };

    // Calculate the time until the next interval
    const timeUntilNextInterval = calculateTimeUntilNextInterval();

    // Set up the initial timeout to align with the next interval
    const initialTimeoutId = setTimeout(() => {
      triggerEvery30Minutes();

      // Set up the interval to run every 30 minutes
      const intervalId = setInterval(triggerEvery30Minutes, 30 * 60 * 1000);

      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }, timeUntilNextInterval);

    return () => {
      clearTimeout(initialTimeoutId);
    };
  }, []);

  console.log(role)
  return (
    <div>

      <Routes>
        <Route path="login" element={<Login />} />
        <Route path='profile' element={<Profile />} />

        <Route path="" >
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard">
            <Route path="" element={<Dashboard />} />
          </Route>
          <Route path="candidate">
            <Route path="" element={<Candidatelist />} />
            <Route path="new" element={<CandidateAdd />} />
            <Route path="edit/:id" element={<CandidateEdit />} />
          </Route>

          {role !== 'staff' ?
            <>
              <Route path="user">
                <Route path="" element={<Staff />} />
                <Route path="new" element={<StaffAdd />} />
                <Route path="edit/:id" element={<StaffEditForm />} />
              </Route>


              <Route path="hrms">
                <Route path="" element={<Company />} />
                <Route path="employee" element={<Employee />} />
              </Route>
            </>

            : ''}
          <Route path="bulkadd">
            <Route path="" element={<BulkAdd />} />

          </Route>
          <Route path="chat">
            <Route path="" element={<ChatPage />} />
          </Route>
          {role === 'hr' ? (
            <Route path="resume" element={<Resume />} />
          ) : null}
          {role === 'admin' || role === 'intermediate' ? (
            <>
              <Route path="resume" element={<IntermediateResume />} />
              {/* <Route path="jot">
                <Route path="" element={<Jot />} />
                <Route path="edit/:id" element={<EditUserInfo />} />
              </Route> */}
            </>
          ) : null}

          {role === 'admin' ? (
            <>
              <Route path="jot">
                <Route path="" element={<Jot />} />
                <Route path="edit/:id" element={<EditUserInfo />} />
              </Route>
            </>
          ) : null}




          {role !== 'staff' ?
            <>
              <Route path="remarks">
                <Route path="" element={<Remarks />} />
              </Route>

            </>
            : ''}

          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Route>
      </Routes>




      <ToastContainer position="top-right" theme="colored" autoClose={2000} />
      <NotificationModal show={showModal} onClose={onClose} />

    </div>
  );
}

export default App;
