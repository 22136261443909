import React, { useEffect, useState } from 'react'

import Sidebar from '../sidebar/Sidebar'
import '../sidebar/Sidebar.css'
import Header from '../../Navbar/Header'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { LoginErrorLabel, candidateErrorLabel, staffErrorLabel } from '../../../config/labels/ErrorLabel'
import { useDispatch, useSelector } from 'react-redux'
import { staffadd } from '../../../middleware/StaffMiddleware'
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import NotificationModal from '../../modal/NotificationModal'

export default function StaffAdd() {
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const [initialState] = useState({
        id: '', name: '', password: '',
        contactNumber: '', profileImage: '',
        role:''
    })

    const { staff, error } = useSelector((state) => state.Staffdata);
    const staffSchema = Yup.object().shape({
        id: Yup.string().required(staffErrorLabel.userid_required),
        name: Yup.string().required(candidateErrorLabel.name_required),
        password: Yup.string().required(LoginErrorLabel.password_required),
    })
    const Addstaff = (values) => {
        const formData = new FormData();
        formData.append('id', values.id);
        formData.append('name', values.name);
        formData.append('password', values.password);
        formData.append('contactNumber', values.contactNumber);

        formData.append('profileImage', values.profileImage);
        formData.append('role',values.role)

        dispatch(staffadd(formData));

    }
    useEffect(() => {
        if (staff?.status === 201) {
            toast(staff?.data?.message, {
                type: "success"
            });
            navigate(-1);
        } else {
            if (staff?.status === 400) {
                toast(staff?.data?.message, {
                    type: "error"
                });
            }
        }
    }, [staff])


    const cancelsubmit = () => {
        navigate(-1);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };


    useEffect(() => {
        const modalInterval = setInterval(() => {
            setShowModal(true);
        }, 60 * 1000);

        return () => {
            clearInterval(modalInterval);
        };
    }, [])
    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row mb-3 mt-2'>
                                <Formik
                                    initialValues={initialState}
                                    validationSchema={staffSchema}
                                    onSubmit={Addstaff}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleSubmit,
                                        handleChange,
                                        setFieldValue,
                                    }) => (
                                        < Form >
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>User id</label>
                                                    <Field className='form-control' placeholder='Enter the User Id' name="id" />
                                                    <ErrorMessage name='id' component='div' className="text-danger" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Staff Name</label>
                                                    <Field className='form-control' placeholder='Enter the Staff Name' name="name" />
                                                    <ErrorMessage name='name' component='div' className="text-danger" />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                <label className='form-label'>Role</label>
                                                <Field as="select" className='form-select' name="role">
                                                        <option value="" selected hidden label="Select User Role" />
                                                        <option value="manager">Manager</option>
                                                        <option value="staff">Enrolment</option>
                                                        <option value="administrator">Administrator</option>
                                                        <option value="hr">Hr</option>
                                                        <option value="intermediate">Interpreter</option>
                                                        <option value="hrms">Hrms</option>
                                                    </Field >
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Password</label>
                                                    <Field className='form-control' placeholder='Enter the Staff password' name="password" />
                                                    <ErrorMessage name='password' component='div' className="text-danger" />
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Photo</label>
                                                    <input
                                                        type='file'
                                                        className='form-control'
                                                        placeholder='Select an Image'
                                                        onChange={(event) => {
                                                            setFieldValue('profileImage', event.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    {values.profileImage && (
                                                        <div className='mt-2 profileimage'>
                                                            <img src={URL.createObjectURL(values.profileImage)} alt="Selected Image"   />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                <label className='form-label'>Contact Number</label>
                                                    <Field type="number" className='form-control' placeholder='Enter the staff contact Number' name="contactNumber" />
                                                </div>
                                                

                                            </div>

                                            <div className='px-4 mt-3'>
                                                <button className='cancel-button ms-2' onClick={cancelsubmit}>Cancel</button>
                                                <button className="btn defult-button ms-2" type='submit'>Submit</button>

                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <NotificationModal show={showModal} onClose={handleCloseModal} /> */}
        </div>
    )
}
