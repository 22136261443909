import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGet, apiPut } from "../../../service/apiService";

const initialState = {
    Loading: false,
    appliedExamsList: null,
    getUserExamDetailsById: null,
    userExamDetailsUpdate: null
};

export const appliedExamsListGet = createAsyncThunk(
    'jot/appliedExamsListGet',
    async (params, { rejectWithValue }) => {
        try {
            const { page, search, filter } = params;
            const response = await apiGet(`jot/applied/exams?page=${page}&search=${search}&filter=${filter}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const userExamDetailsGetById = createAsyncThunk(
    'jot/userExamDetailsGetById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await apiGet(`jot/applied/exams/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const userExamDetailsUpdate = createAsyncThunk(
    'jot/userExamDetailsUpdate',
    async ({id, data}, { rejectWithValue }) => {
        try {
            const response = await apiPut(`jot/exam/${id}`, data);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const JotSlice = createSlice({
    name: "JotSlice",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(appliedExamsListGet.pending, (state) => {
                state.loading = true;
            })
            .addCase(appliedExamsListGet.fulfilled, (state, action) => {
                state.loading = false;
                state.appliedExamsList = action.payload;
            })
            .addCase(appliedExamsListGet.rejected, (state) => {
                state.loading = false;
            })

            .addCase(userExamDetailsGetById.pending, (state) => {
                state.loading = true;
            })
            .addCase(userExamDetailsGetById.fulfilled, (state, action) => {
                state.loading = false
                state.getUserExamDetailsById = action.payload
            })
            .addCase(userExamDetailsGetById.rejected, (state) => {
                state.loading = false;
            })

            .addCase(userExamDetailsUpdate.pending, (state) => {
                state.loading = true
            })

            .addCase(userExamDetailsUpdate.fulfilled, (state, action) => {
                state.loading = false;
                state.userExamDetailsUpdate = action.payload
            })
            .addCase(userExamDetailsUpdate.rejected, (state) => {
                state.loading = false;
            })
    }
})

export default JotSlice.reducer