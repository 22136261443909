import React, { useEffect, useRef, useState } from 'react'

import Sidebar from '../sidebar/Sidebar'
import Header from '../../Navbar/Header'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { candidateErrorLabel } from '../../../config/labels/ErrorLabel';
import { candidateadd } from '../../../middleware/CandidateMiddleware';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import NotificationModal from '../../modal/NotificationModal';
import Cookies from 'js-cookie';
import LoadingSpinner from '../../../component/resuable/LoadingSpinner';
export default function CandidateAdd() {

    const [showModal, setShowModal] = useState(false);
    const role = Cookies.get("role");

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { loading, candidate, error } = useSelector((state) => state.Candidate);

    const fileInputRef = useRef(null);

    const [initialState] = useState({
        name: '', email: '', passport_number: '',
        fatherName: '', dob: null, passportExpiryDate: null,
        height: '', weight: '', tshirtSize: '',
        shoeSize: '', contactNumber: '',
        csoc: '', emergencyContactNumber: '', agentContactNumber: '',
        passportCopy: '', vaccinationCertificate: '', educationalCertificate: '',
        medicalReport: '', fullSizePhoto: ''

    })
    const candidateschema = Yup.object().shape({
        name: Yup.string().required(candidateErrorLabel.name_required),
        passport_number: Yup.string().required(candidateErrorLabel.passport_number_required),
        email: Yup.string().email(candidateErrorLabel.invalid_format)
    })
    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${year}-${month}-${day}`;
    }

    const Addcandidate = (values) => {
        const formattedDob = values.dob ? formatDate(values.dob) : '';
        const formattedPassportExpiry = values.passportExpiryDate ? formatDate(values.passportExpiryDate) : '';
        const testmonthdate = values.testMonth ? formatDate(values.testMonth) : '';

        const formData = new FormData();

        formData.append('name', values.name);
        formData.append('fatherName', values.fatherName)
        formData.append('dob', formattedDob)
        formData.append('passportNumber', values.passport_number);
        formData.append('passportExpiryDate', formattedPassportExpiry);
        formData.append('height', values.height);
        formData.append('weight', values.weight);
        formData.append('tshirtSize', values.tshirtSize);
        formData.append('shoeSize', values.shoeSize);
        formData.append('contactNumber', values.contactNumber);
        formData.append('emergencyContactNumber', values.emergencyContactNumber);
        formData.append('agentContactNumber', values.agentContactNumber);
        formData.append('email', values.email);
        formData.append('csoc', values.csoc);
        formData.append('testMonth', testmonthdate)
        formData.append('passportCopy', values.passportCopy);
        formData.append('vaccinationCertificate', values.vaccinationCertificate);
        formData.append('educationalCertificate', values.educationalCertificate);
        formData.append('medicalReport', values.medicalReport);
        formData.append('fullSizePhoto', values.fullSizePhoto);

        dispatch(candidateadd(formData));
    };

    useEffect(() => {
        if (candidate?.status === 201) {
            toast(candidate?.data?.message, {
                type: "success"
            });
            navigate(-1);
        } else {
            if (candidate?.status === 400) {
                toast(candidate?.data?.message, {
                    type: "error"
                });
            }
        }

    }, [candidate])


    const cancelsubmit = () => {
        navigate(-1);
    }


  


    const renderMonthContent = (month, shortMonth, longMonth) => {
        const tooltipText = `Tooltip for month: ${longMonth}`;
        return <span title={tooltipText}>{shortMonth}</span>;
      };

    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />

                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row mb-3 mt-2'>
                                <Formik
                                    initialValues={initialState}
                                    validationSchema={candidateschema}
                                    onSubmit={Addcandidate} >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleSubmit,
                                        handleChange,
                                        setFieldValue,
                                    }) => (
                                        < Form >
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Name</label>
                                                    <Field type='text' className='form-control' placeholder='Enter Your Name' name='name' />
                                                    <ErrorMessage name='name' component='div' className="text-danger" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Father Name</label>
                                                    <input type='text' className='form-control' placeholder='Enter Father Name' name='fatherName' value={values.fatherName} onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Date of Birth</label>
                                                    <DatePicker className='form-control' placeholderText='Select Your Date of Birth'
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        dateFormat='dd/MM/yyyy'
                                                        selected={values.dob} onChange={(date) => { setFieldValue('dob', date) }} />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport Number</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Passport Number' name="passport_number" />
                                                    <ErrorMessage name='passport_number' component='div' className="text-danger" />
                                                </div>
                                            </div>
                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport Expiry date</label>
                                                    <DatePicker className='form-control' placeholderText='Select Your Passport Expiry Date'
                                                        dateFormat='dd/MM/yyyy'

                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        selected={values.passportExpiryDate} onChange={(date) => setFieldValue('passportExpiryDate', date)} />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Height</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Height' name="height" />
                                                </div>
                                            </div>
                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Weight</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Weight' name="weight" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>T-Shirt Size</label>
                                                    <Field type="text" className='form-control' placeholder='Enter T-Shirt Size' name="tshirtSize" />
                                                </div>
                                            </div>
                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Shoe Size</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Shoe Size' name="shoeSize" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Contact Number</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Contact Number' name="contactNumber" />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Emergency Contact Number</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Emergency Contact Number' name="emergencyContactNumber" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Agent Contact Number</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Agent Contact Number' name="agentContactNumber" />
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Email</label>
                                                    <Field className='form-control' placeholder='Enter Your Email' name='email' />
                                                    <ErrorMessage name='email' component='div' className="text-danger" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>CSOC</label>
                                                    <Field as="select" className='form-select' name='csoc'>
                                                        <option value="" selected hidden label="Select an option" />
                                                        <option value="TAMIL">Tamil</option>
                                                        <option value="Engli">English</option>
                                                    </Field>
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Test Month Date</label>
                                                    <DatePicker className='form-control' placeholderText='Select Test Month Date'
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        dateFormat='dd/MM/yyyy'
                                                        selected={values.testMonth} onChange={(date) => { setFieldValue('testMonth', date) }} />
                                                    {/* <DatePicker
                                                    className='form-control'
                                                        placeholderText='Select Test Month Date'
                                                        renderMonthContent={renderMonthContent}
                                                        showMonthYearPicker
                                                        dateFormat="MM/yyyy"
                                                        selected={values.testMonth} onChange={(date) => { setFieldValue('testMonth', date) }}
                                                    /> */}
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <div className='col'>
                                                        <label className='form-label'>Passport</label>
                                                        <input
                                                            type="file"
                                                            className='form-control'
                                                            onChange={(event) => {
                                                                setFieldValue('passportCopy', event.currentTarget.files[0]);
                                                            }}
                                                        />
                                                        {values.passportCopy && (
                                                            <div className='mt-2 profileimage'>
                                                                {values.passportCopy.type.includes('image') ? (
                                                                    <img
                                                                        src={URL.createObjectURL(values.passportCopy)}
                                                                        alt="Selected passportCopy"
                                                                    />
                                                                ) : (
                                                                    <embed
                                                                        src={URL.createObjectURL(values.passportCopy)}

                                                                        type="application/pdf"
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Medical Report</label>
                                                    <input type="file" className='form-control' placeholder='Enter your name' onChange={(event) => {
                                                        setFieldValue('medicalReport', event.currentTarget.files[0]);
                                                    }} />
                                                    {values.medicalReport && (
                                                        <div className='mt-2 profileimage'>
                                                            {values.medicalReport.type.includes('image') ? (
                                                                <img
                                                                    src={URL.createObjectURL(values.medicalReport)}
                                                                    alt="Selected medicalReport"
                                                                />
                                                            ) : (
                                                                <embed
                                                                    src={URL.createObjectURL(values.medicalReport)}

                                                                    type="application/pdf"
                                                                />
                                                            )}
                                                        </div>
                                                    )}



                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Full size photo</label>
                                                    <input type="file" className='form-control' placeholder='Enter your name' onChange={(event) => {
                                                        setFieldValue('fullSizePhoto', event.currentTarget.files[0]);
                                                    }} />

                                                    {values.fullSizePhoto && (
                                                        <div className='mt-2 profileimage'>
                                                            {values.fullSizePhoto.type.includes('image') ? (
                                                                <img
                                                                    src={URL.createObjectURL(values.fullSizePhoto)}
                                                                    alt="Selected fullSizePhoto"
                                                                />
                                                            ) : (
                                                                <embed
                                                                    src={URL.createObjectURL(values.fullSizePhoto)}

                                                                    type="application/pdf"
                                                                />
                                                            )}
                                                        </div>
                                                    )}



                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Educational Certificate</label>
                                                    <input type="file" className='form-control' placeholder='Enter your name'

                                                        onChange={(event) => {
                                                            setFieldValue('educationalCertificate', event.currentTarget.files[0]);
                                                        }} />

                                                    {values.educationalCertificate && (
                                                        <div className='mt-2 profileimage'>
                                                            {values.educationalCertificate.type.includes('image') ? (
                                                                <img
                                                                    src={URL.createObjectURL(values.educationalCertificate)}
                                                                    alt="Selected educationalCertificate"
                                                                />
                                                            ) : (
                                                                <embed
                                                                    src={URL.createObjectURL(values.educationalCertificate)}

                                                                    type="application/pdf"
                                                                />
                                                            )}
                                                        </div>
                                                    )}



                                                </div>

                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Vaccination Certificate</label>
                                                    <input ref={fileInputRef} type="file" className='form-control' placeholder='Enter your name'
                                                        onChange={(event) => {
                                                            setFieldValue('vaccinationCertificate', event.currentTarget.files[0]);
                                                        }}
                                                    />
                                                    {values.vaccinationCertificate && (
                                                        <div className='mt-2 profileimage'>
                                                            {values.vaccinationCertificate.type.includes('image') ? (
                                                                <img
                                                                    src={URL.createObjectURL(values.vaccinationCertificate)}
                                                                    alt="Selected vaccinationCertificate"
                                                                />
                                                            ) : (
                                                                <embed
                                                                    src={URL.createObjectURL(values.vaccinationCertificate)}

                                                                    type="application/pdf"
                                                                />
                                                            )}
                                                        </div>
                                                    )}



                                                </div>

                                            </div>

                                            <div className='px-4 mt-3'>
                                                <button className='cancel-button ms-2' type='button' onClick={cancelsubmit}>Cancel</button>
                                                <button className="btn defult-button ms-2" type='submit'  disabled={loading}>Submit</button>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                                {loading ?
                                    <LoadingSpinner/> : ""}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
           
        </div >
    )
}
