import { createAsyncThunk } from '@reduxjs/toolkit';
import { endPoint } from '../config/endPoint';
import { apiGet } from '../service/apiService';


export const pendingdata = createAsyncThunk("pendingdata", async (payload) => {
    const ress = await apiGet(endPoint.notification + "/" + payload, "");
    return ress;
});



