import React, { useEffect, useState } from 'react'
import Sidebar from '../sidebar/Sidebar'
import { Form, Field, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../Navbar/Header';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { userExamDetailsGetById, userExamDetailsUpdate } from './JotSlice';
import * as Yup from "yup"

export default function CandidateEdit() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    const {getUserExamDetailsById} = useSelector(state => state.jot);
    const getUserExamDetails = getUserExamDetailsById?.data;
    
    const [previewCertificateImage, setPreviewCertificateImage] = useState('')

    const [initialState, setInitialState] = useState(
        {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            gender: "",
            address: "",
            country: "",
            state: "",
            city: "",
            postal_code: "",
            applied_at: "",
            dob: "",
            exam_level: "",
            id_photo: null,
            person_photo: null,
            certificate: null,
            result: "",
            updated_at: "",
            user_id: "",
            exam_date: null
        }
    )

    const validationSchema = Yup.object().shape({
        exam_date: Yup.string().required("Exam date required"),
        certificate: Yup.string().required("Certificate required")
    })


    useEffect(() => {
        dispatch(userExamDetailsGetById(id))
    }, [dispatch, id])


    useEffect(() => {
        if (getUserExamDetailsById?.data) {
            const UpdatedDetails = {
                ...getUserExamDetails,
                id_photo: getUserExamDetails?.id_photo || null,
                person_photo: getUserExamDetails?.person_photo || null,
                certificate: getUserExamDetails?.certificate || null,
            }
            setInitialState(UpdatedDetails);
        }
    }, [getUserExamDetailsById])


    const UpdateUserExamDetails = (values) => {
        const formData = new FormData();

        formData.append('first_name', values.first_name || '');
        formData.append('last_name', values.last_name || '')
        if (values.dob) {
            const selectedDate = new Date(values.dob);
            selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
            formData.append('dob', selectedDate.toISOString().split('T')[0]);
        }
        formData.append('phone', values.phone || '');
        formData.append('gender', values.gender || '');
        formData.append('address', values.address || '');
        formData.append('country', values.country || '');
        formData.append('state', values.state || '');
        formData.append('city', values.city || '');
        formData.append('postal_code', values.postal_code || '');
        formData.append('applied_at', values.applied_at || '');
        formData.append('exam_level', values.exam_level || '');
        formData.append('result', values.result || '');
        formData.append('email', values.email || '');
        formData.append('document_id', values.document_id || '');
        formData.append('updated_at', values.updated_at || '');
        formData.append('id_photo', values.id_photo || "");
        formData.append('person_photo', values.person_photo || "");
        if (values.exam_date) {
            const selectedDate = new Date(values.exam_date);
            selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
            formData.append('exam_date', selectedDate.toISOString().split('T')[0]);
        }
        if (values.certificate instanceof File) {
            formData.append('certificate', values.certificate);
        } else {
            formData.append('certificate', values.certificate || "");
        }

        const param = { id: id, data: formData }
        dispatch(userExamDetailsUpdate(param)).then(res => {
            if (res?.payload?.status === 200) {
                toast.success(res?.payload?.data?.message)
                navigate(-1);
            } else {
                toast.error(res?.payload?.error)
            }
        })
    }

    const cancelsubmit = () => {
        navigate(-1);
    }

    const downloadFile = (imageURL) => {
        const newTab = window.open(imageURL, '_blank');
        if (!newTab) {
            const link = document.createElement('a');
            link.href = imageURL;
            link.download = imageURL;
            link.click();
        }
    }


    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row mb-3 mt-2'>
                                <Formik
                                    initialValues={initialState}
                                    enableReinitialize
                                    onSubmit={UpdateUserExamDetails}
                                    validationSchema={validationSchema}
                                >
                                    {({ values, setFieldValue, errors, touched }) => (
                                        <Form>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>First Name</label>
                                                    <Field placeholder='Enter Name' className='form-control' name="first_name" value={values?.first_name} disabled />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Last Name</label>
                                                    <Field placeholder='Enter Last Name' className='form-control' name="last_name" value={values?.last_name} disabled />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Date of Birth</label>
                                                    <DatePicker placeholderText='Select the DOB' className='form-control' name="dob"
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        value={values?.dob ? format(new Date(values.dob), 'dd/MM/yyyy') : ''}
                                                        onChange={(date) => { setFieldValue('dob', date) }}
                                                        disabled
                                                    />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Email</label>
                                                    <Field placeholder='Enter Email' className='form-control' name="email" value={values?.email} disabled />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Phone Number</label>
                                                    <Field placeholder='Enter Passport Number' className='form-control' name="phone" value={values?.phone} disabled />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Gender</label>
                                                    <Field placeholder='Enter gender' className='form-control' name="gender" value={values?.gender} disabled />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Address</label>
                                                    <Field placeholder='Enter address' className='form-control' name="address" value={values?.address} disabled />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Country</label>
                                                    <Field placeholder='Enter country' className='form-control' name="country" value={values?.country} disabled />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>State</label>
                                                    <Field placeholder='Enter state' className='form-control' name="state" value={values?.state} disabled />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>City</label>
                                                    <Field placeholder='Enter city' className='form-control' name="city" value={values?.city} disabled />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Post Code</label>
                                                    <Field placeholder='Enter post code' className='form-control' name="postal_code" value={values?.postal_code} disabled />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Applied Date</label>
                                                    <Field placeholder='Enter applied date' className='form-control' name="applied_at" value={values?.applied_at} disabled />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Exam Level</label>
                                                    <Field placeholder='Enter exam level' className='form-control' name="exam_level" value={values?.exam_level} disabled />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>ID Photo</label>
                                                    <div className='d-flex align-items-center justify-content-center border rounded p-1 bg-light cursor-pointer' onClick={() => downloadFile(values?.id_photo)}>
                                                        <i class="fas fa-cloud-download-alt fs-3 me-3"></i>
                                                        {values?.id_photo ? "View ID" : "ID Unavailable"}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Photo</label>
                                                    <div className='d-flex align-items-center justify-content-center border rounded p-1 bg-light cursor-pointer' onClick={() => downloadFile(values?.person_photo)}>
                                                        <i class="fas fa-cloud-download-alt fs-3 me-3"></i>
                                                        {values?.person_photo ? "View Photo":"Photo Unavailable"}
                                                    </div>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Exam Date</label>
                                                    <DatePicker placeholderText='Select the date' className='form-control' name="exam_date"
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        value={values?.exam_date ? format(new Date(values.exam_date), 'dd/MM/yyyy') : ''}
                                                        onChange={(date) => { setFieldValue('exam_date', date) }}
                                                    />
                                                    {errors?.exam_date && touched.exam_date && 
                                                    <p className='error'>{errors?.exam_date}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Issue Certificate</label>
                                                    <input
                                                        className='form-control'
                                                        type='file'
                                                        name='certificate'
                                                        onChange={(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            if (file instanceof Blob || file instanceof File) {
                                                                setFieldValue('certificate', file);
                                                                const reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    const imagePreviewUrl = e.target.result;
                                                                    setPreviewCertificateImage(imagePreviewUrl);
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }}
                                                    />
                                                    <div className='col-sm-6 mb-2 mt-2 mb-sm-0 profileimage'>
                                                        {previewCertificateImage ? (
                                                            previewCertificateImage.startsWith('data:application/pdf') ? (
                                                                <embed src={previewCertificateImage} type='application/pdf' width='100%' height='500px' />
                                                            ) : (
                                                                <img src={previewCertificateImage} alt='certificate Preview' />
                                                            )
                                                        ) : (
                                                            values.certificate && typeof values.certificate === 'string' ? (
                                                                values.certificate.includes('.pdf') ? (
                                                                    <embed src={values.certificate} type='application/pdf' width='100%' height='500px' />
                                                                ) : (
                                                                    <img src={values.certificate} alt='certificate' />
                                                                )
                                                            ) : null
                                                        )}

                                                    </div>
                                                    {errors?.certificate && touched.certificate && 
                                                    <p className='error'>{errors?.certificate}</p>
                                                    }
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Result</label>
                                                    <Field as="select" placeholder='Select result status' className='form-select' name="result" value={values?.result} disabled={values?.exam_date === null || values?.certificate === null ? true : false}>
                                                        <option value="" hidden>select status</option>
                                                        <option value="Passed">Passed</option>
                                                        <option value="Failed">Failed</option>
                                                        <option value="Awaiting Results">Awaiting Results</option>
                                                        <option value="Withheld">Withheld</option>
                                                        <option value="Absent">Absent</option>

                                                    </Field>
                                                    
                                                    <p className='secondary'>{values?.exam_date === null || values?.certificate === null ? "Note : Exam date and Certificate needed to access result":null}</p>
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>

                                               
                                            </div>
                                            <div className='px-4 mt-3'>
                                                <button className='cancel-button ms-2' type='button' onClick={cancelsubmit}>Cancel</button>
                                                <button className="btn defult-button ms-2" type='submit'>Submit</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
