import React from 'react'

const LoadingSpinner = () => {
    return (
        <div className="overlay">
          <div className="spinner-border text-light bacdrop-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
      </div>
    )
}

export default LoadingSpinner