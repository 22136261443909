import { ErrorMessage, Field, Form, Formik } from "formik";
import Header from "../../../Navbar/Header";
import Sidebar from "../../sidebar/Sidebar";
import { useEffect, useState } from "react";

import * as Yup from 'yup';
import ReactDatePicker from "react-datepicker";
import { addholiday, deleteHoliday, getHoliday, updateHoliday } from "./HolidaySlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import StyledDataTable from "../../../../component/resuable/StyledComponent";
import { Button } from "react-bootstrap";
import DeleteDialog from "../../../../component/resuable/deleteDialog";
export default function Holiday({ handleBack }) {
    const dispatch = useDispatch()
    const [rowData, setRowData] = useState([]);
    const holidayData = useSelector(state => state.holiday.holidayData);

    const [selectedData, setselectedData] = useState("");
    const [deleteId, setDeleteId] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [editData, setEditData] = useState("");
    const [initialValues, setInitialValues] = useState({
        holiday_name: '',
        holiday_date: null
    });

    const validationSchema = Yup.object().shape({
        holiday_name: Yup.string().required("Hoilday is required"),
    });

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${year}-${month}-${day}`;
    }


    useEffect(() => {
        if (holidayData && Array.isArray(holidayData)) {
            const formattedData = holidayData.map((data) => ({
                holiday_name: data.holiday_name,
                holiday_date: data.holiday_date,
                holiday_id:data.holiday_id
            }));
            setRowData(formattedData);
        }
    }, [holidayData]);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getHoliday());
        };

        fetchData();
    }, [dispatch]);
    const handleSubmit = async (values, { resetForm }) => {
        const holidayDate = values.holiday_date ? formatDate(values.holiday_date) : ''; // Use 'holiday_date' instead of 'date'
        const holidayData = {
            holiday_name: values.holiday_name,
            holiday_date: holidayDate
        };
        if(editData && editData.holiday_id){
            const response = await dispatch(updateHoliday({ ...holidayData, holiday_id: editData.holiday_id })).unwrap();
            if (response.status === 200) {
                resetForm();
                setEditData(null);
                setInitialValues({ holiday_name: '',holiday_date:null });
                toast.success('Holiday Updated Successfully');
            } else {
                toast.error(response?.data?.message);
            }
        }else{
        
            const response = await dispatch(addholiday(holidayData)).unwrap();
            if (response.status === 201) {
                toast.success('Holiday added successfully');
                resetForm();
            } else {
                toast.error(response.data.message);
            }
       
        }
    };
    

    const handleEditClick = (data) => {
        setInitialValues({
            holiday_name: data.holiday_name,
            holiday_date: new Date(data.holiday_date)
          
        });
        setEditData(data);
    };
    const handleDeleteClick = (data) => {
        setselectedData(data.holiday_name)
        setDeleteId(data.holiday_id)
        setEditData(data);
        setShowDeleteDialog(true);
    };
    const columns = [
        {
            name: "S.No",

            selector: (row, index) => index + 1,
        },
        {
            name: 'Holiday',
            selector: (row) => row.holiday_name,
        },
        {
            name: 'Holiday Date',
            selector: (row) => row.holiday_date,
        },

        {
            name: "Action",
            cell: (row) => (
                <>
                    <Button variant="primary" className="table-viewcustom-button" onClick={() => handleEditClick(row)}>
                        <i className="fas fa-edit px-2"></i>
                    </Button>

                    <Button variant="primary" className="table-custom-button" onClick={() => handleDeleteClick(row)} style={{ marginRight: '8px' }} >
                        <i className="fas fa-trash px-2"></i>
                    </Button>

                </>
            ),
            button: true,

            width: '20%'
        }
    ];

    const handleDelete = async (id) => {
        const response = await dispatch(deleteHoliday(id));
        if (response.payload.status === 200) {
            toast.success("Holiday Deleted successfully");
        }
    };
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>


                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card p-3'>
                            <div className="d-flex justify-content-between">
                                <h3>Holiday</h3>
                                <button className='cancel-button ms-2' onClick={handleBack}>Back</button>
                            </div>
                            <div className='row justify-content-center'>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                    enableReinitialize={true}
                                >
                                    {({ values,
                                        setFieldValue, }) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-4 px-3 py-2">
                                                    <label className='form-label'>Holiday Name</label>
                                                    <Field
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Holiday Name'
                                                        name='holiday_name'
                                                    />
                                                    <ErrorMessage name='holiday_name' component='div' className="text-danger" />
                                                </div>
                                                <div className="col-md-4 px-3 py-2">
                                                    <label className='form-label'>HoilDay Date</label>
                                                    <ReactDatePicker className='form-control' placeholderText='Select Hoilday Date'
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        selected={values.holiday_date}
                                                        dateFormat='dd/MM/yyyy'
                                                        onChange={(date) => { setFieldValue('holiday_date', date) }} />
                                                </div>
                                                <div className="col-md-4 px-3 py-2">
                                                </div>


                                                <div className="row mt-3">
                                                    <div className="col ">

                                                        <button className="btn defult-button ms-2" type='submit'>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            <div className="mt-2">
                                <StyledDataTable
                                    columns={columns}
                                    data={rowData.length > 0 ? rowData : []}
                                    highlightOnHover
                                    responsive
                                    persistTableHead={true}
                                />
                            </div>
                        </div>
                         {showDeleteDialog && deleteId && (
                                    <DeleteDialog
                                        id={deleteId}
                                        data={selectedData}
                                        onDelete={handleDelete}
                                        onClose={() => setShowDeleteDialog(false)}
                                    />
                                )}
                               {/* {openPayroll && (
                                    <RunPayrollModal closePayRoll={closePayRoll} onClose={() => setOpenPayroll(false)} />
                                )} */}
                    </div>
                </div>
            </div>

        </>

    )
}