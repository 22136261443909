import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from "react-datepicker";
import * as Yup from 'yup';
import { runPayroll, updateattendance } from '../hrmsSlice';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
export default function RunPayrollModal({ closePayRoll }) {
  const dispatch = useDispatch()
  const [commonLoad, setCommonLoad] = useState(false)
  const companyData = useSelector(state => state.hrms.companyData?.companies || []);

  const fileInputRef = useRef(null);
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    closePayRoll();
  };
  const validationSchema = Yup.object().shape({
    csvFile: Yup.mixed().required('CSV file is required'),
  });
  function formatDate(date) {
    if (date) {
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      
      return { month, year }; 
    }
    return { month: '', year: '' }; 
  }
  const handleSubmit = async (values, { resetForm }) => {
    setCommonLoad(true)
    const formData = new FormData();
    formData.append('file', values.csvFile);
    const { month, year } = values.payrollmonth ? formatDate(values.payrollmonth) : { month: '', year: '' };
    const response = await dispatch(updateattendance({ formData, param: { month, year } }))
    if (response.payload.status === 201) {
      setCommonLoad(false)
      toast.success(response.payload.data.message)
      handleClose()
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toast.error(response.payload.data.message)
      handleClose()
    }
    // resetForm()
  }

  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };
  return (
    <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Run you payroll Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {commonLoad && (
            <div className="spinner-container"> {/* Wrap the spinner with a div */}
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>)}
          <Formik
            initialValues={{ csvFile: null,payrollmonth:new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1) }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}

          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              setFieldValue,
              resetForm
            }) => (
              <Form>
                <div className="row">
                <div className='col-md-8  mt-2'>
                  <label className='form-label'>Upload the Excel </label>
                  <input ref={fileInputRef}
                    type='file'
                    accept='.xlsx, .xls'
                    className='form-control'
                    placeholder='Enter your name'
                    name='csvFile'
                    onChange={(event) => {
                      setFieldValue('csvFile', event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage name='csvFile' component='div' className='text-danger' />


                </div>
                <div className='col-md-4  mt-2'>
                  <label className='form-label'>Payroll month</label>
                  <DatePicker  className='form-control'
          placeholderText='Select Your Date of Birth'
          showMonthYearPicker
          peekNextMonth
          dropdownMode='select'
          dateFormat="MM/yyyy"
          selected={values.payrollmonth}
          onChange={(date) => { setFieldValue('payrollmonth', date) }} />
                </div>
                </div>

                <div className='mt-3'>
                  <button className='cancel-button ms-2' onClick={handleClose}>Cancel</button>

                  <button className='btn defult-button ms-2' type='submit' disabled={!values.csvFile}>
                    Submit
                  </button>
                </div>

              </Form>
            )}
          </Formik>
        </Modal.Body>

      </Modal>
    </>
  )
}