import { createSlice} from '@reduxjs/toolkit'
import { bulkadd } from '../middleware/BulkaddMiddleware';


const BulkaddSlice = createSlice({
    name:'Bulkadd',
    initialState:{
        loading:false,
        csv_data:null,
        error:null
    },
    reducers: {
        clearOldState: (state) => {
            state.csv_data = null;
        },
    },
    extraReducers:(builder)=>{
        builder
    .addCase(bulkadd.pending,(state)=>{
        state.loading = true;
        state.csv_data = null
    })
    .addCase(bulkadd.fulfilled,(state,action)=>{
        state.loading = false;            
        state.csv_data=action.payload;
        state.error=null;
    })
    
    .addCase(bulkadd.rejected,(state,action)=>{
        state.loading = false;            
        state.error=action.error.message;        
        state.csv_data = null
    })
}

})

export const { clearOldState } = BulkaddSlice.actions

export default BulkaddSlice.reducer


