import React, { useEffect, useState } from 'react'
import Sidebar from '../sidebar/Sidebar'
import { Form, Field, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import { candidategetbyid, candidateupdate } from '../../../middleware/CandidateMiddleware';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../Navbar/Header';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import Cookies from 'js-cookie';

export default function CandidateEdit() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const role = Cookies.get("role");
  const candidateData = useSelector(state => state.Candidate.getcandidate);

  const [showModal, setShowModal] = useState(false);
  const candidateDataupdate = useSelector(state => state.Candidate.updatecandidate);

  const [previewpassportImage, setPreviewpassportImage] = useState('')
  const [previeweducationImage, setPrevieweducationImage] = useState('')
  const [previewemedicalImage, setPreviewmedicalImage] = useState('')
  const [previewephotoImage, setPreviewphotoImage] = useState('')
  const [previewevaccinationImage, setPreviewvaccinationImage] = useState('')

  const [initialState, setInitialState] = useState(
    {
      name: "",
      fatherName: "",
      dob: new Date(),
      passportNumber: "",
      passportExpiryDate: new Date(),
      height: "",
      weight: "",
      tshirtSize: "",
      shoeSize: "",
      contactNumber: "",
      emergencyContactNumber: "",
      agentContactNumber: "",
      email: '',
      csoc: "",
      passportCopy: null,
      educationalCertificate: null,
      medicalReport: null,
      fullSizePhoto: null,
      vaccinationCertificate: null,
      pdf_page: null
    })



  useEffect(() => {
    dispatch(candidategetbyid(id))

  }, [])
  useEffect(() => {
    if (candidateData?.status === 200) {
      setInitialState(candidateData?.data);
    }
  }, [candidateData])





  useEffect(() => {
    if (candidateDataupdate?.status === 200) {
      toast(candidateDataupdate?.data?.message, {
        type: "success"
      });
      navigate(-1);
    } else {
      if (candidateDataupdate?.response?.status === 400 || !candidateDataupdate?.response?.status === 200) {
        toast(candidateDataupdate?.response?.data?.message, {
          type: "error"
        });
      }
    }
  }, [candidateDataupdate])



  const Updatecandidate = (values) => {
    const formData = new FormData();


    formData.append('name', values.name || '');
    formData.append('fatherName', values.fatherName || '')
    if (values.dob) {
      const selectedDate = new Date(values.dob);
      selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
      formData.append('dob', selectedDate.toISOString().split('T')[0]);
    }
    formData.append('passportNumber', values.passportNumber || '');
    if (values.passportExpiryDate) {
      const selectedpassportDate = new Date(values.passportExpiryDate);
      selectedpassportDate.setMinutes(selectedpassportDate.getMinutes() - selectedpassportDate.getTimezoneOffset());
      formData.append('passportExpiryDate', selectedpassportDate.toISOString().split('T')[0]);
    }

    formData.append('height', values.height || '');
    formData.append('weight', values.weight || '');
    formData.append('tshirtSize', values.tshirtSize || '');
    formData.append('shoeSize', values.shoeSize || '');
    formData.append('contactNumber', values.contactNumber || '');

    formData.append('emergencyContactNumber', values.emergencyContactNumber || '');
    formData.append('agentContactNumber', values.agentContactNumber || '');
    formData.append('email', values.email || '');
    formData.append('csoc', values.csoc || '');
    if (values.testMonth) {
      const testmonthdate = new Date(values.testMonth);
      testmonthdate.setMinutes(testmonthdate.getMinutes() - testmonthdate.getTimezoneOffset());
      formData.append('testMonth', testmonthdate.toISOString().split('T')[0]);
    }
    if (values.passportCopy instanceof File) {
      formData.append('passportCopy', values.passportCopy);
    }
    if (values.educationalCertificate instanceof File) {
      formData.append('educationalCertificate', values.educationalCertificate);
    }

    if (values.medicalReport instanceof File) {
      formData.append('medicalReport', values.medicalReport);
    }
    if (values.fullSizePhoto instanceof File) {
      formData.append('fullSizePhoto', values.fullSizePhoto);
    }
    if (values.vaccinationCertificate instanceof File) {
      formData.append('vaccinationCertificate', values.vaccinationCertificate)
    }


    const param = { id: id, data: formData }
    dispatch(candidateupdate(param))
  }
  const cancelsubmit = () => {
    navigate(-1);
  }
  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const downloadFile = (pdfURL) => {
    if (pdfURL) {
      const newTab = window.open(pdfURL, '_blank');
      if (!newTab) {
        const link = document.createElement('a');
        link.href = pdfURL;
        link.download = pdfURL;
        link.click();
      }
    }
  }

  return (
    <>

      <div className='container-fluid'>
        <div className='row'>

          <Header />
          <div className='col-2 p-0'>
            <Sidebar />
          </div>
          <div className='col-10 mt-2 px-3'>
            <div className='card custom-card'>
              <div className='row mb-3 mt-2'>
                <Formik

                  initialValues={initialState}
                  enableReinitialize
                  onSubmit={Updatecandidate}
                >
                  {({ values, setFieldValue }) => (

                    <Form>
                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Name</label>
                          <Field placeholder='Enter Name' className='form-control' name="name" value={values?.name} />
                        </div>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Father Name</label>
                          <Field placeholder='Enter Father Name' className='form-control' name="fatherName" value={values?.fatherName} />
                        </div>
                      </div>
                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Date of Birth</label>
                          <DatePicker placeholderText='Select the DOB' className='form-control' name="dob"
                            peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                            value={values?.dob ? format(new Date(values.dob), 'dd/MM/yyyy') : ''}
                            onChange={(date) => { setFieldValue('dob', date) }}

                          />
                        </div>

                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Passport Number</label>
                          <Field placeholder='Enter Passport Number' className='form-control' name="passportNumber" value={values?.passportNumber} />
                        </div>
                      </div>
                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Passport Expiry date</label>
                          <DatePicker placeholderText='Select Passport Expiry date' className='form-control' name="passportExpiryDate"
                            peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                            value={values?.passportExpiryDate ? format(new Date(values.passportExpiryDate), 'dd/MM/yyyy') : ''}
                            onChange={(date) => { setFieldValue('passportExpiryDate', date) }}

                          />

                        </div>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Height</label>
                          <Field placeholder='Enter Height' className='form-control' name="height" value={values?.height} />
                        </div>
                      </div>
                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Weight</label>
                          <Field placeholder='Enter Weight' className='form-control' name="weight" value={values?.weight} />
                        </div>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>T-Shirt Size</label>
                          <Field placeholder='Enter T-Shirt Sizes' className='form-control' name="tshirtSize" value={values?.tshirtSize} />
                        </div>
                      </div>
                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Shoe Size</label>
                          <Field placeholder='Enter Shoe Size' className='form-control' name="shoeSize" value={values?.shoeSize} />
                        </div>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Contact Number</label>
                          <Field placeholder='Enter Contact Number' className='form-control' name="contactNumber" value={values?.contactNumber} />
                        </div>
                      </div>
                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Emergency Contact Number</label>
                          <Field placeholder='Enter Emergency Contact Number' className='form-control' name="emergencyContactNumber" value={values?.emergencyContactNumber} />
                        </div>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Agent Contact Number</label>
                          <Field placeholder='Enter Agent Contact Number' className='form-control' name="agentContactNumber" value={values?.agentContactNumber} />
                        </div>
                      </div>
                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Email</label>
                          <Field placeholder='Enter Email' className='form-control' name="email" value={values?.email} />
                        </div>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>CSOC</label>
                          <Field as="select" placeholder='Select CSOC' className='form-select' name="csoc" value={values?.csoc}>
                            <option value="" hidden>select the language</option>
                            <option value="ENGLISH">English</option>
                            <option value="TAMIL">Tamil</option>

                          </Field>

                        </div>
                      </div>
                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Test Month</label>
                          <DatePicker placeholderText='Select Text month date' className='form-control' name="testMonth"
                            peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                            value={values?.testMonth ? format(new Date(values.testMonth), 'dd/MM/yyyy') : ''}
                            onChange={(date) => { setFieldValue('testMonth', date) }}

                          />
                          {/* <DatePicker placeholderText='Select Test Month Date' className='form-control' name="testMonth"
                          showMonthYearPicker
                            renderMonthContent={renderMonthContent}
                            value={values?.testMonth ? format(new Date(values.testMonth), 'MM/yyyy') : ''}
                            selected={values.testMonth ? new Date(values.testMonth) : null}
 
                            onChange={(date) => { setFieldValue('testMonth', date) }}
                            
                            /> */}
                        </div>

                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Passport</label>
                          <input
                            className='form-control'
                            type='file'
                            name='passportCopy'
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file instanceof Blob || file instanceof File) {
                                setFieldValue('passportCopy', file);
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const imagePreviewUrl = e.target.result;
                                  setPreviewpassportImage(imagePreviewUrl);
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                          <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                            {previewpassportImage ? (
                              previewpassportImage.startsWith('data:application/pdf') ? (
                                <embed src={previewpassportImage} type='application/pdf' width='100%' height='500px' />
                              ) : (
                                <img src={previewpassportImage} alt='passportCopy Preview' />
                              )
                            ) : (
                              values.passportCopy && typeof values.passportCopy === 'string' ? (
                                values.passportCopy.includes('.pdf') ? (
                                  <embed src={values.passportCopy} type='application/pdf' width='100%' height='500px' />
                                ) : (
                                  <img src={values.passportCopy} alt='passportCopy' />
                                )
                              ) : null
                            )}

                          </div>
                        </div>
                      </div>

                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Medical Report</label>
                          <input
                            className='form-control'
                            type='file'
                            name='medicalReport'
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file instanceof Blob || file instanceof File) {
                                setFieldValue('medicalReport', file);
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const imagePreviewUrl = e.target.result;
                                  setPreviewmedicalImage(imagePreviewUrl);
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                          <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                            {previewemedicalImage ? (
                              previewemedicalImage.startsWith('data:application/pdf') ? (
                                <embed src={previewemedicalImage} type='application/pdf' width='100%' height='500px' />
                              ) : (
                                <img src={previewemedicalImage} alt='medicalReport Preview' />
                              )
                            ) : (
                              values.medicalReport && typeof values.medicalReport === 'string' ? (
                                values.medicalReport.includes('.pdf') ? (
                                  <embed src={values.medicalReport} type='application/pdf' width='100%' height='500px' />
                                ) : (
                                  <img src={values.medicalReport} alt='medicalReport' />
                                )
                              ) : null
                            )}

                          </div>
                        </div>





                        {/* Full size photo */}

                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Full size photo</label>
                          <input
                            className='form-control'
                            type='file'
                            name='fullSizePhoto'
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file instanceof Blob || file instanceof File) {
                                setFieldValue('fullSizePhoto', file);
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const imagePreviewUrl = e.target.result;
                                  setPreviewphotoImage(imagePreviewUrl);
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                          <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                            {previewephotoImage ? (
                              previewephotoImage.startsWith('data:application/pdf') ? (
                                <embed src={previewephotoImage} type='application/pdf' width='100%' height='500px' />
                              ) : (
                                <img src={previewephotoImage} alt='fullSizePhoto Preview' />
                              )
                            ) : (
                              values.fullSizePhoto && typeof values.fullSizePhoto === 'string' ? (
                                values.fullSizePhoto.includes('.pdf') ? (
                                  <embed src={values.fullSizePhoto} type='application/pdf' width='100%' height='500px' />
                                ) : (
                                  <img src={values.fullSizePhoto} alt='fullSizePhoto' />
                                )
                              ) : null
                            )}

                          </div>
                        </div>



                      </div>


                      {/* Vaccination Certificate image */}


                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Education certificate</label>
                          <input
                            className='form-control'
                            type='file'
                            name='educationalCertificate'
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file instanceof Blob || file instanceof File) {
                                setFieldValue('educationalCertificate', file);
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const imagePreviewUrl = e.target.result;
                                  setPrevieweducationImage(imagePreviewUrl);
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                          <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                            {previeweducationImage ? (
                              previeweducationImage.startsWith('data:application/pdf') ? (
                                <embed src={previeweducationImage} type='application/pdf' width='100%' height='500px' />
                              ) : (
                                <img src={previeweducationImage} alt='educationalCertificate Preview' />
                              )
                            ) : (
                              values.educationalCertificate && typeof values.educationalCertificate === 'string' ? (
                                values.educationalCertificate.includes('.pdf') ? (
                                  <embed src={values.educationalCertificate} type='application/pdf' width='100%' height='500px' />
                                ) : (
                                  <img src={values.educationalCertificate} alt='educationalCertificate' />
                                )
                              ) : null
                            )}

                          </div>
                        </div>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Vaccination Certificate</label>
                          <input
                            className='form-control'
                            type='file'
                            name='vaccinationCertificate'
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              if (file instanceof Blob || file instanceof File) {
                                setFieldValue('vaccinationCertificate', file);
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                  const imagePreviewUrl = e.target.result;
                                  setPreviewvaccinationImage(imagePreviewUrl);
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                          <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                            {previewevaccinationImage ? (
                              previewevaccinationImage.startsWith('data:application/pdf') ? (
                                <embed src={previewevaccinationImage} type='application/pdf' width='100%' height='500px' />
                              ) : (
                                <img src={previewevaccinationImage} alt='vaccinationCertificate Preview' />
                              )
                            ) : (
                              values.vaccinationCertificate && typeof values.vaccinationCertificate === 'string' ? (
                                values.vaccinationCertificate.includes('.pdf') ? (
                                  <embed src={values.vaccinationCertificate} type='application/pdf' width='100%' height='500px' />
                                ) : (
                                  <img src={values.vaccinationCertificate} alt='vaccinationCertificate' />
                                )
                              ) : null
                            )}

                          </div>
                        </div>
                      </div>



                      {/* Generated pdf*/}


                      <div className='row px-4 mt-0 mt-sm-2'>
                        <div className='col-sm-6 mb-2 mb-sm-0'>
                          <label className='form-label'>Candidate Details</label>
                          <div className='d-flex align-items-center justify-content-center border rounded p-1 bg-light cursor-pointer' onClick={() => downloadFile(values?.pdf_page)}>
                            <i class="fas fa-cloud-download-alt fs-3 me-3"></i>
                            {values?.pdf_page ? "View Candidate Details" : "Candidate Information Unavailable"}
                          </div>

                          <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                            {
                              values.pdf_page && typeof values.pdf_page === 'string' ? (
                                values.pdf_page.includes('.pdf') ? (
                                  <embed src={values.pdf_page} type='application/pdf' width='100%' />
                                ) : (
                                  <img src={values.pdf_page} alt='candidatePdf' />
                                )
                              ) : null
                            }
                          </div>
                        </div>
                      </div>

                      <div className='px-4 mt-3'>
                        <button className='cancel-button ms-2' onClick={cancelsubmit}>Cancel</button>
                        <button className="btn defult-button ms-2" type='submit'>Submit</button>

                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
