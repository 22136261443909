import { useSelector } from 'react-redux';
import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import Header from '../../Navbar/Header';
import Sidebar from '../sidebar/Sidebar';
import "./Jot.css"
import User_list from './User_list';

const Jot = () => {
    const [search, setSearch] = useState()
    const [filterdate, setFilterdate] = useState(null)
    const [filterByExamLevelValue, setFilterByExamLevelValue] = useState("")
    const [filterByResultValue, setFilterByResultValue] = useState("")

    const data = useSelector(state => state.Candidate);

    const renderMonthContent = (month, shortMonth, longMonth) => {
        const tooltipText = `Tooltip for month: ${longMonth}`;
        return <span title={tooltipText}>{shortMonth}</span>;
    };
    function formatDate(date) {
        if (date) {
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${year}-${month}`;
        }
    }
    const formattedfilterdate = filterdate ? formatDate(filterdate) : '';

    const Exam_levels = ["N1","N2", "N3", "N4", "N5"];
    const resultOptions = ["Passed","Failed", "Awaiting results"]

    const handleFilterByExamLevel = (e) => {
        setFilterByExamLevelValue(e.target.value)
    }

    const handleFilterByResult = (e) => {
        setFilterByResultValue(e.target.value)
    }

    const handleResetFilterByExamLevel = () => {
        setFilterByExamLevelValue("")
    }
    const handleResetFilterByResult = () => {
        setFilterByResultValue("")
    }



    return (
        <div className='container-fluid'>
            <div className='row'>
                <Header />
                <div className='col-2 p-0'>
                    <Sidebar />
                </div>
                <div className='col-10 mt-2 ps-3'>
                    <div className='card custom-card'>
                        <div className='row d-flex justify-content-end p-3'>
                            <div className="col-12 col-lg-6 p-0">
                            <div className='d-flex justify-content-end'>
                                <div className='me-3 d-flex'>
                                    
                                        <select className="form-select text-secondary" value={filterByExamLevelValue} aria-label="Small select example" onChange={(e) => handleFilterByExamLevel(e)}>
                                            <option value="" selected>Select exam level</option>
                                        {
                                            Exam_levels.map((examLevel) => (
                                                <option key={examLevel} value={examLevel}>{examLevel}</option>
                                            ))
                                        }
                                    </select>
                                      <button className="table-viewcustom-button p-1 btn btn-primary btn-focus-none" onClick={handleResetFilterByExamLevel}><i class="fa fa-retweet" aria-hidden="true"></i></button>
                                </div>

                                <div className='d-flex me-md-3'>
                                        <select className="form-select text-secondary"  value={filterByResultValue} aria-label="Small select example" onChange={(e) => handleFilterByResult(e)}>
                                            <option value="" selected>Select result type</option>
                                        
                                        {
                                            resultOptions.map((result) => (
                                                <option key={result} value={result}>{result}</option>
                                            ))
                                        }
                                    </select>
                                    
                                      <button className="table-viewcustom-button p-1 btn btn-primary btn-focus-none" onClick={handleResetFilterByResult}><i class="fa fa-retweet" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-lg-4 mt-3 mt-lg-0 p-0">
                            <div className='d-flex justify-content-end'>

                            <DatePicker className='form-control datefilter' placeholderText='Select filter'
                                    renderMonthContent={renderMonthContent}
                                    showMonthYearPicker
                                    dateFormat="MM/yyyy"
                                    selected={filterdate}
                                    onChange={(date) => {
                                        setFilterdate(date);
                                    }}
                                />
                                <button className="table-viewcustom-button p-1 btn btn-primary btn-focus-none" onClick={() => setFilterdate(null)}><i class="fa fa-retweet" aria-hidden="true"></i></button>
                                <div className='ms-2'>
                                    <input className='form-control search-btn' placeholder='Search' onChange={(e) => setSearch(e.target.value)} />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='row p-3'>
                            <User_list data={data} filterByExamLevelValue={filterByExamLevelValue} filterByResultValue={filterByResultValue} filter={formattedfilterdate} search={search} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Jot