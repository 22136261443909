import React, { useState } from 'react'
import Header from '../../Navbar/Header';
import Sidebar from '../sidebar/Sidebar';
import AdmissionList from './AdmissionList';
import { useNavigate } from 'react-router-dom';


const Admission = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState()

  const newAdmission = () => {
    navigate('new')
  }
  
  return (
    <div className='container-fluid'>
      <div className='row'>
        <Header />
        <div className='col-2 p-0'>
          <Sidebar />
        </div>
        <div className='col-10 mt-2 ps-3'>
          <div className='card custom-card'>
            <div className='row d-flex justify-content-end p-3'>
              <div className="col-12 col-lg-8 mt-3 mt-lg-0 p-0">
                <div className='d-flex justify-content-end'>
                  <div className='ms-2'>
                    <input className='form-control search-btn' placeholder='Search' onChange={(e) => setSearch(e.target.value)} />
                  </div>
                  <div>
                    <button className='btn defult-button ms-3' onClick={() => newAdmission()}>New Admission</button>
                  </div>
                </div>
              </div>
            </div>
            <div className='row p-3'>
              <AdmissionList  search={search} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Admission