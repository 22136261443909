import axios from "axios";

// export const baseUrl = ' http://127.0.0.1:5000/api/v1/';
export const baseUrl = "https://backend.astrocdms.in/api/v1/"

export async function apiPost(api, params) {
    try {
        const response = await axios.post(baseUrl + api, params);
        return response;
    } catch (error) {
        return error.response;
    }
}

export async function apiGet(api, params) {
   
    try {
        const response = await axios.get(baseUrl + api, {
            params: params
        });
        return response;
    } catch (error) {
        return error.response;
    }

}

export async function apiPut(api, params) {
    try {
        const response = await axios.put(baseUrl + api, params);
        return response;
    } catch (error) {
        return error;
    }

}



export async function apiDelete(api, params) {
    try {
        const response = await axios.delete(baseUrl + api, {
            params: params
        });
        return response;
    } catch (error) {
        return error;
    }

}



