import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



function DeleteDialog({ id, onDelete, onClose,data }) {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleDelete = () => {
    onDelete(id);
    handleClose();
  };
  console.log(data)

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header  closeButton>
        <Modal.Title>Delete Confirmation!</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this <b>{data}</b>?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button className="model-save-button"  onClick={handleDelete}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteDialog;
