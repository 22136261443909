import React, { useEffect, useState } from 'react'
import Sidebar from '../sidebar/Sidebar'
import { Form, Field, Formik, ErrorMessage } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../Navbar/Header';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import * as Yup from "yup"
import LoadingSpinner from '../../../component/resuable/LoadingSpinner';
import { clearUpdateAdmission, getAdmissionDataById, updateAdmission } from './admissionSlice';
import { admissionLabel, candidateErrorLabel } from '../../../config/labels/ErrorLabel';

const admissionSchema = Yup.object().shape({
    name: Yup.string().required(candidateErrorLabel.name_required),
    admission_number: Yup.string().required(admissionLabel.ad_no_required),
    passport_no: Yup.string().required(candidateErrorLabel.passport_number_required),
})

export default function EditAdmissionInfo() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    const ADMISSION_DATA = useSelector(state => state.admission);
    const { admissionDataById } = useSelector(state => state.admission);
    const { updateAdmissionData } = useSelector(state => state.admission);

    const [previewSignImage, setPreviewSignImage] = useState('')
    const [previewProfileImage, setPreviewProfileImage] = useState('')
    const [previewpassportImage, setPreviewpassportImage] = useState('')
    const [previeweducationImage, setPrevieweducationImage] = useState('')
    const [previewemedicalImage, setPreviewmedicalImage] = useState('')
    const [previewevaccinationImage, setPreviewvaccinationImage] = useState('')

    const [initialState, setInitialState] = useState(
        {
            name: '',
            admission_number: "",
            fatherName: '',
            ad_fees: "",
            date_of_admission: new Date(),
            dob: new Date(),
            passport_no: '',
            height: '',
            weight: '',
            contact: '',
            trade: "",
            marital_status: "",
            nationality: "",
            religion: "",
            communication: "",
            reference: "",
            date_of_issue: new Date(),
            date_of_expiry: new Date(),
            place_of_issue: "",
            educational_qualification: "",
            additional_qualification: "",
            english_knowledge: "",
            working_experience: "",
            profile_pic: null,
            sign: null,
            remarks: "",
            pdf_page: null,
            passportCopy: null,
            educationalCertificate: null,
            medicalReport: null,
            vaccinationCertificate: null,
            shoeSize: '',
            tshirtSize: '',

            email: '',
            csoc: '',
            emergencyContactNumber: '',
            agentContactNumber: '',
        }
    )


    useEffect(() => {
        const params = id
        dispatch(getAdmissionDataById(params))
    }, [dispatch, id])

    useEffect(() => {
        if (admissionDataById) {
            setInitialState(admissionDataById);
        }
    }, [admissionDataById])

    useEffect(() => {
        if (updateAdmissionData?.status === 200) {
            toast(updateAdmissionData?.data?.message, {
                type: "success"
            });
            navigate(-1);
            dispatch(clearUpdateAdmission())
        } else {
            if (updateAdmissionData?.response?.status === 400 || !updateAdmissionData?.response?.status === 200) {
                toast(updateAdmissionData?.response?.data?.message, {
                    type: "error"
                });
            }
        }
    }, [updateAdmissionData])

    const handleUpdateAdmission = (values) => {

        const formData = new FormData();

        formData.append('id', id || "");
        formData.append('name', values.name || "");
        formData.append('admission_number', values.admission_number || "")

        formData.append('fatherName', values.fatherName || "")
        formData.append('ad_fees', values.ad_fees || "")
        if (values.date_of_admission) {
            const selectedDate = new Date(values.date_of_admission);
            selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
            formData.append('date_of_admission', selectedDate.toISOString().split('T')[0]);
        }
        if (values.dob) {
            const selectedDate = new Date(values.dob);
            selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
            formData.append('dob', selectedDate.toISOString().split('T')[0]);
        }
        formData.append('nationality', values.nationality || "")
        formData.append('religion', values.religion || "")
        formData.append('place_of_issue', values.place_of_issue || "")
        formData.append('educational_qualification', values.educational_qualification || "")
        formData.append('additional_qualification', values.additional_qualification || "")
        formData.append('english_knowledge', values.english_knowledge || "")
        formData.append('working_experience', values.working_experience || "")
        formData.append('reference', values.reference || "")
        formData.append('passport_no', values.passport_no || "");
        if (values.date_of_expiry) {
            const selectedDate = new Date(values.date_of_expiry);
            selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
            formData.append('date_of_expiry', selectedDate.toISOString().split('T')[0]);
        }
        formData.append('height', values.height || "");
        formData.append('weight', values.weight || "");
        formData.append('contact', values.contact || "");
        formData.append('marital_status', values.marital_status || "");
        formData.append('trade', values.trade || "");
        formData.append('remarks', values.remarks || "");
        formData.append('communication', values.communication || "");
        if (values.date_of_issue) {
            const selectedDate = new Date(values.date_of_issue);
            selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
            formData.append('date_of_issue', selectedDate.toISOString().split('T')[0]);
        }
        formData.append('profile_pic', values.profile_pic);
        formData.append('sign', values.sign);
        formData.append("applied", values.applied || "")
        formData.append('passportCopy', values.passportCopy);
        formData.append('educationalCertificate', values.educationalCertificate);
        formData.append('medicalReport', values.medicalReport);
        formData.append('vaccinationCertificate', values.vaccinationCertificate)

        formData.append('tshirtSize', values.tshirtSize || '');
        formData.append('shoeSize', values.shoeSize || '');



        formData.append('csoc', values.csoc || '');
        formData.append('email', values.email || '');
        formData.append('emergencyContactNumber', values.emergencyContactNumber || '');
        formData.append('agentContactNumber', values.agentContactNumber || '');

        dispatch(updateAdmission({ id, data: formData }))

    };

    const cancelsubmit = () => {
        navigate(-1);
    }

    const downloadFile = (pdfURL) => {
        if (pdfURL) {
            const newTab = window.open(pdfURL, '_blank');
            if (!newTab) {
                const link = document.createElement('a');
                link.href = pdfURL;
                link.download = pdfURL;
                link.click();
            }
        }
    }

    return (
        <>
            {ADMISSION_DATA?.Loading && <LoadingSpinner />}
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row mb-3 mt-2'>
                                <Formik
                                    initialValues={initialState}
                                    onSubmit={handleUpdateAdmission}
                                    validationSchema={admissionSchema}
                                    enableReinitialize >
                                    {({
                                        values,
                                        setFieldValue,
                                    }) => (
                                        < Form >
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Admission Number</label>
                                                    <Field className='form-control' placeholder='Enter Admission Number' name="admission_number" />
                                                    <ErrorMessage name='admission_number' component='div' className="text-danger" />
                                                </div>

                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Date of Admission</label>
                                                    <DatePicker className='form-control' placeholderText='Select Your Date of Admission'
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        value={values?.date_of_admission ? format(new Date(values.date_of_admission), 'dd/MM/yyyy') : ''}
                                                        onChange={(date) => { setFieldValue('date_of_admission', date) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-4 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Name</label>
                                                    <Field type='text' className='form-control' placeholder='Enter Your Name' name='name' />
                                                    <ErrorMessage name='name' component='div' className="text-danger" />
                                                </div>
                                                <div className='col-sm-4 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Date of Birth</label>
                                                    <DatePicker className='form-control' placeholderText='Select Your Date of Birth'
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        dateFormat='dd/MM/yyyy'
                                                        value={values?.dob ? format(new Date(values.dob), 'dd/MM/yyyy') : ''}
                                                        onChange={(date) => { setFieldValue('dob', date) }}
                                                    />
                                                </div>
                                                <div className='col-sm-4 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Father Name</label>
                                                    <Field type='text' className='form-control' placeholder='Enter Your Father Name' name='fatherName' />
                                                    <ErrorMessage name='fatherName' component='div' className="text-danger" />
                                                </div>

                                            </div>



                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-3 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport Number</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Passport Number' name="passport_no" />
                                                    <ErrorMessage name='passport_no' component='div' className="text-danger" />
                                                </div>
                                                <div className='col-sm-3 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport Issued Date</label>
                                                    <DatePicker className='form-control' placeholderText='Select Passport Issued Date'
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        value={values?.date_of_issue ? format(new Date(values.date_of_issue), 'dd/MM/yyyy') : ''}
                                                        onChange={(date) => { setFieldValue('date_of_issue', date) }}
                                                    />
                                                </div>
                                                <div className='col-sm-3 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport Expiry Date</label>
                                                    <DatePicker className='form-control' placeholderText='Select Your Passport Expiry Date'
                                                        peekNextMonth showMonthDropdown showYearDropdown dropdownMode='select'
                                                        value={values?.date_of_expiry ? format(new Date(values.date_of_expiry), 'dd/MM/yyyy') : ''}
                                                        onChange={(date) => { setFieldValue('date_of_expiry', date) }}
                                                    />
                                                </div>
                                                <div className='col-sm-3 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport Issued Place</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Place' name="place_of_issue" />
                                                </div>
                                            </div>


                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Nationality</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Nationality' name="nationality" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Religion</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Religion' name="religion" />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Educational Qualification</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Educational qualification' name="educational_qualification" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Additional Qualification</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Additional Qualification' name="additional_qualification" />
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Marital Status</label>
                                                    <Field as="select" className='form-select' name='marital_status'>
                                                        <option value="" selected hidden label="Select an option" />
                                                        <option value="Married">Married</option>
                                                        <option value="UnMarried">UnMarried</option>
                                                    </Field>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Trade</label>
                                                    <Field type="text" className='form-control' placeholder='Enter trade' name='trade' />
                                                    <ErrorMessage name='trade' component='div' className="text-danger" />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Working Experience</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Working Experience' name='working_experience' />
                                                    <ErrorMessage name='working_experience' component='div' className="text-danger" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>English Knowledge</label>
                                                    <Field as="select" className='form-select' name='english_knowledge'>
                                                        <option value="" selected hidden label="Select an option" />
                                                        <option value="Good">Good</option>
                                                        <option value="Average">Average</option>
                                                        <option value="Poor">Poor</option>
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Contact Number</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Contact Number' name="contact" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Communication Address</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Communication Address' name="communication" />
                                                </div>

                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Reference</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Reference' name="reference" />
                                                </div>

                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Remarks</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Remarks' name="remarks" />
                                                </div>
                                            </div>
                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Height</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Height' name="height" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Weight</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Weight' name="weight" />
                                                </div>
                                            </div>

                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Shoe Size</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Shoe Size' name="shoeSize" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>T-Shirt Size</label>
                                                    <Field type="text" className='form-control' placeholder='Enter T-Shirt Size' name="tshirtSize" />
                                                </div>
                                            </div>




                                            <div className='row  px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>CSOC</label>
                                                    <Field as="select" className='form-select' name='csoc'>
                                                        <option value="" selected hidden label="Select an option" />
                                                        <option value="TAMIL">Tamil</option>
                                                        <option value="Engli">English</option>
                                                    </Field>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Email</label>
                                                    <Field className='form-control' placeholder='Enter Your Email' name='email' />
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Emergency Contact Number</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Emergency Contact Number' name="emergencyContactNumber" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Agent Contact Number</label>
                                                    <Field type="number" className='form-control' placeholder='Enter Agent Contact Number' name="agentContactNumber" />
                                                </div>
                                            </div>


                                            <div className='row px-4 mt-0 mt-sm-2'>

                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Admission Fee</label>
                                                    <Field type="text" className='form-control' placeholder='Enter Admission Fee' name="ad_fees" />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Full size photo</label>
                                                    <input
                                                        className='form-control'
                                                        type='file'
                                                        name='profile_pic'
                                                        onChange={(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            if (file instanceof Blob || file instanceof File) {
                                                                setFieldValue('profile_pic', file);
                                                                const reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    const imagePreviewUrl = e.target.result;
                                                                    setPreviewProfileImage(imagePreviewUrl);
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }}
                                                    />
                                                    <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                                                        {previewProfileImage ? (
                                                            previewProfileImage.startsWith('data:application/pdf') ? (
                                                                <embed src={previewProfileImage} type='application/pdf' width='100%' height='500px' />
                                                            ) : (
                                                                <img src={previewProfileImage} alt='profile_pic Preview' />
                                                            )
                                                        ) : (
                                                            values.profile_pic && typeof values.profile_pic === 'string' ? (
                                                                values.profile_pic.includes('.pdf') ? (
                                                                    <embed src={values.profile_pic} type='application/pdf' width='100%' height='500px' />
                                                                ) : (
                                                                    <img src={values.profile_pic} alt='profile_pic' />
                                                                )
                                                            ) : null
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>

                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Signature</label>
                                                    <input
                                                        className='form-control'
                                                        type='file'
                                                        name='sign'
                                                        onChange={(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            if (file instanceof Blob || file instanceof File) {
                                                                setFieldValue('sign', file);
                                                                const reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    const imagePreviewUrl = e.target.result;
                                                                    setPreviewSignImage(imagePreviewUrl);
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }}
                                                    />
                                                    <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                                                        {previewSignImage ? (
                                                            previewSignImage.startsWith('data:application/pdf') ? (
                                                                <embed src={previewSignImage} type='application/pdf' width='100%' height='500px' />
                                                            ) : (
                                                                <img src={previewSignImage} alt='sign Preview' />
                                                            )
                                                        ) : (
                                                            values.sign && typeof values.sign === 'string' ? (
                                                                values.sign.includes('.pdf') ? (
                                                                    <embed src={values.sign} type='application/pdf' width='100%' height='500px' />
                                                                ) : (
                                                                    <img src={values.sign} alt='sign' />
                                                                )
                                                            ) : null
                                                        )}

                                                    </div>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Passport</label>
                                                    <input
                                                        className='form-control'
                                                        type='file'
                                                        name='passportCopy'
                                                        onChange={(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            if (file instanceof Blob || file instanceof File) {
                                                                setFieldValue('passportCopy', file);
                                                                const reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    const imagePreviewUrl = e.target.result;
                                                                    setPreviewpassportImage(imagePreviewUrl);
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }}
                                                    />
                                                    <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                                                        {previewpassportImage ? (
                                                            previewpassportImage.startsWith('data:application/pdf') ? (
                                                                <embed src={previewpassportImage} type='application/pdf' width='100%' height='500px' />
                                                            ) : (
                                                                <img src={previewpassportImage} alt='passportCopy Preview' />
                                                            )
                                                        ) : (
                                                            values.passportCopy && typeof values.passportCopy === 'string' ? (
                                                                values.passportCopy.includes('.pdf') ? (
                                                                    <embed src={values.passportCopy} type='application/pdf' width='100%' height='500px' />
                                                                ) : (
                                                                    <img src={values.passportCopy} alt='passportCopy' />
                                                                )
                                                            ) : null
                                                        )}

                                                    </div>
                                                </div>

                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Education certificate</label>
                                                    <input
                                                        className='form-control'
                                                        type='file'
                                                        name='educationalCertificate'
                                                        onChange={(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            if (file instanceof Blob || file instanceof File) {
                                                                setFieldValue('educationalCertificate', file);
                                                                const reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    const imagePreviewUrl = e.target.result;
                                                                    setPrevieweducationImage(imagePreviewUrl);
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }}
                                                    />
                                                    <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                                                        {previeweducationImage ? (
                                                            previeweducationImage.startsWith('data:application/pdf') ? (
                                                                <embed src={previeweducationImage} type='application/pdf' width='100%' height='500px' />
                                                            ) : (
                                                                <img src={previeweducationImage} alt='educationalCertificate Preview' />
                                                            )
                                                        ) : (
                                                            values.educationalCertificate && typeof values.educationalCertificate === 'string' ? (
                                                                values.educationalCertificate.includes('.pdf') ? (
                                                                    <embed src={values.educationalCertificate} type='application/pdf' width='100%' height='500px' />
                                                                ) : (
                                                                    <img src={values.educationalCertificate} alt='educationalCertificate' />
                                                                )
                                                            ) : null
                                                        )}

                                                    </div>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Medical Report</label>
                                                    <input
                                                        className='form-control'
                                                        type='file'
                                                        name='medicalReport'
                                                        onChange={(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            if (file instanceof Blob || file instanceof File) {
                                                                setFieldValue('medicalReport', file);
                                                                const reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    const imagePreviewUrl = e.target.result;
                                                                    setPreviewmedicalImage(imagePreviewUrl);
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }}
                                                    />
                                                    <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                                                        {previewemedicalImage ? (
                                                            previewemedicalImage.startsWith('data:application/pdf') ? (
                                                                <embed src={previewemedicalImage} type='application/pdf' width='100%' height='500px' />
                                                            ) : (
                                                                <img src={previewemedicalImage} alt='medicalReport Preview' />
                                                            )
                                                        ) : (
                                                            values.medicalReport && typeof values.medicalReport === 'string' ? (
                                                                values.medicalReport.includes('.pdf') ? (
                                                                    <embed src={values.medicalReport} type='application/pdf' width='100%' height='500px' />
                                                                ) : (
                                                                    <img src={values.medicalReport} alt='medicalReport' />
                                                                )
                                                            ) : null
                                                        )}

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Vaccination Certificate</label>
                                                    <input
                                                        className='form-control'
                                                        type='file'
                                                        name='vaccinationCertificate'
                                                        onChange={(event) => {
                                                            const file = event.currentTarget.files[0];
                                                            if (file instanceof Blob || file instanceof File) {
                                                                setFieldValue('vaccinationCertificate', file);
                                                                const reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    const imagePreviewUrl = e.target.result;
                                                                    setPreviewvaccinationImage(imagePreviewUrl);
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }}
                                                    />
                                                    <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                                                        {previewevaccinationImage ? (
                                                            previewevaccinationImage.startsWith('data:application/pdf') ? (
                                                                <embed src={previewevaccinationImage} type='application/pdf' width='100%' height='500px' />
                                                            ) : (
                                                                <img src={previewevaccinationImage} alt='vaccinationCertificate Preview' />
                                                            )
                                                        ) : (
                                                            values.vaccinationCertificate && typeof values.vaccinationCertificate === 'string' ? (
                                                                values.vaccinationCertificate.includes('.pdf') ? (
                                                                    <embed src={values.vaccinationCertificate} type='application/pdf' width='100%' height='500px' />
                                                                ) : (
                                                                    <img src={values.vaccinationCertificate} alt='vaccinationCertificate' />
                                                                )
                                                            ) : null
                                                        )}

                                                    </div>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Admission Details</label>
                                                    <div className='d-flex align-items-center justify-content-center border rounded p-1 bg-light cursor-pointer' onClick={() => downloadFile(values?.pdf_file)}>
                                                        <i class="fas fa-cloud-download-alt fs-3 me-3"></i>
                                                        {values?.pdf_file ? "View admission Details" : "Admission Information Unavailable"}
                                                    </div>

                                                    <div className='col-sm-6 mb-2 mb-sm-0 profileimage'>
                                                        {
                                                            values.pdf_file && typeof values.pdf_file === 'string' ? (
                                                                values.pdf_file.includes('.pdf') ? (
                                                                    <embed src={values.pdf_file} type='application/pdf' width='100%' />
                                                                ) : (
                                                                    <img src={values.pdf_file} alt='admission pdf' />
                                                                )
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='px-4 mt-3'>
                                                <button className='cancel-button ms-2' type='button' onClick={cancelsubmit}>Cancel</button>
                                                <button className="btn defult-button ms-2" type='submit' disabled={ADMISSION_DATA?.Loading}>Submit</button>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}