import { createSlice } from '@reduxjs/toolkit'
import { candidatdelete, candidateadd, candidateget, candidategetbyid, candidateupdate } from '../middleware/CandidateMiddleware';



const CandidateSlice = createSlice({
    name: 'CandidateSlice',
    initialState: {
        loading: false,
        candidate: null,
        error: null,
        candidatedata:[],
        deleteData: null,
        getcandidate:null,
        updatecandidate:null
        
    },
    reducers: {
        clearOldState: (state) => {
            state.candidate = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(candidateadd.pending, (state) => {
                state.loading = true;
            })
            .addCase(candidateadd.fulfilled, (state, action) => {
                state.loading = false;
                state.candidate = action.payload;
                state.error = null;
            })

            .addCase(candidateadd.rejected, (state, action) => {
                state.loading = false;
                state.candidate = null;
                state.error = action.error.message
            })




            .addCase(candidateget.pending,(state) => {
                state.loading = true
                state.candidatedata=[]                
                state.deleteData = null
            })
            .addCase(candidateget.fulfilled,(state,action) => {
                state.loading = false
                state.error = null;
                state.candidatedata = action.payload                
                state.deleteData = null
                
                state.updatecandidate = null
                state.getcandidate = null
                state.candidate = null
            })
            .addCase(candidateget.rejected,(state,action)=>{
                state.error = action.payload.error
                state.loading = false
                state.candidatedata = [];

            })


            .addCase(candidatdelete.pending,(state,action)=>{
                state.loading = true
                state.deleteData = null
            })
            .addCase(candidatdelete.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteData = action.payload
                
            })
            .addCase(candidatdelete.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false     
            })



            .addCase(candidategetbyid.pending, (state)=>{
                state.loading = true;
            })
            .addCase(candidategetbyid.fulfilled,(state,action)=>{
                state.loading = true
                state.getcandidate = action.payload
                state.error = null
                state.updatecandidate=null
                
                state.candidate = null
            })
            .addCase(candidategetbyid.rejected,(state,action)=>{
                state.error = action.payload.message
            })



            .addCase(candidateupdate.pending, (state) => {
                state.loading = true
            })

            .addCase(candidateupdate.fulfilled, (state, action) => {
                state.loading = false;
                state.updatecandidate = action.payload
                state.getcandidate = null
                state.error = null
            })
            .addCase(candidateupdate.rejected, (state, action) => {
                state.error = action.payload.error
            })
    
    }

})

export const { clearOldState } = CandidateSlice.actions


export default CandidateSlice.reducer