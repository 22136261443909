import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { addResume, udpateResume } from './ResumeSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';


export default function ResumeData({ handleBack, editData }) {
    const dispatch = useDispatch();
    const [newChip, setNewChip] = useState('');
    const [initialValues, setInitialValues] = useState({
        name: '',
        email: '',
        phone: '',
        skill: [],
        current_salary: '',
        expected_salary: '',
        status: '',
        sex: '',
        notice_period: '',
        location: '',
        mode: '',
        file: '',
        id: '',
        years_of_exp: '',
        language: '',

    });


    const validationSchema = Yup.object().shape({
        name: Yup.string().required("User Name is required"),
        email: Yup.string().required("Email is required"),
        phone: Yup.string().required("Phone Number is required"),
        skill: Yup.array().min(1, "At least one Skill is required"),
        current_salary: Yup.string().required("Current Salary is required"),
        expected_salary: Yup.string().required("Expected Salary is required"),
        status: Yup.string().required("Status is required"),
        sex: Yup.string().required("Sex is required"),
        location: Yup.string().required("Location is required"),
        file: Yup.string().required("File is required"),
        mode: Yup.string().required("Preferred is required"),
        years_of_exp: Yup.string().required("Experience is required"),
        language: Yup.string().required("language is required")
    });
    let role = Cookies.get("role");
    const handleSubmit = async (values) => {
        let formData = new FormData();

        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        formData.append('skill', values.skill.join(','));
        formData.append('current_salary', values.current_salary);
        formData.append('expected_salary', values.expected_salary);
        formData.append('status', values.status);
        formData.append('notice_period', values.notice_period);
        formData.append('sex', values.sex);
        formData.append('location', values.location);
        formData.append('mode', values.mode);
        formData.append('file', values.file);
        formData.append('years_of_exp', values.years_of_exp);
        formData.append('language', values.language);


            if (editData) {
                formData.append('id', values.id);
                {role === 'intermediate' ? 
                formData.append('japanese_file', values.japanese_file):formData.append('japanese_file', '')
            }
                const response = await dispatch(udpateResume({ id: editData.id, formData })).unwrap();

                if (response.status === 200) {
                    handleBack();
                    toast.success('Candidate Resume Updated Successfully');
                } else {
                    toast.error()
                }


            } else {
                
                
                const response = await dispatch(addResume(formData));
                if (response.payload.status === 201) {
                    handleBack();
                    toast.success('User data added Successfully');
                } else {
                    toast.error(response.payload.data.message)
                }
            }
    };


    useEffect(() => {
        if (editData) {
            setInitialValues({
                id: editData.id || '',
                name: editData.name || '',
                email: editData.email || '',
                phone: editData.phone || '',
                skill: editData.skill ? editData.skill.split(',') : [], 
                current_salary: editData.current_salary || '',
                expected_salary: editData.expected_salary || '',
                status: editData.status || '',
                sex: editData.sex || '',
                notice_period: editData.email || '',
                location: editData.location || '',
                mode: editData.mode || '',
                file: editData.file_download || '',
                years_of_exp: editData.years_of_exp || '',
                language: editData.language || '',
                japanese_file:editData.japanese_file_path || ''

            });
        }
    }, [editData]);

    return (
        <>

            <div className="col-md-12">
                <div className="card p-3">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, setFieldValue }) => {
                            const handleAddChip = () => {
                                if (newChip && !values.skill.includes(newChip)) {
                                    const updatedSkills = [...values.skill, newChip];
                                    setFieldValue('skill', updatedSkills); // Update Formik state
                                    setNewChip(''); // Clear local input
                                }
                            };

                            const handleRemoveChip = (chipToRemove) => {
                                const updatedSkills = values.skill.filter(chip => chip !== chipToRemove);
                                setFieldValue('skill', updatedSkills); // Update Formik state
                            };

                            const handleKeyDown = (e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent form submission
                                    handleAddChip();
                                }
                            };

                            return (
                                <Form>
                                    <div className="row">
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Name</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter User Name'
                                                name='name'
                                                disabled={role === 'intermediate'} 
                                            />
                                            <ErrorMessage name='name' component='div' className="text-danger" />
                                        </div>

                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Email</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter User Email'
                                                name='email'
                                                disabled={role === 'intermediate'} 
                                            />
                                            <ErrorMessage name='email' component='div' className="text-danger" />
                                        </div>

                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Phone Number</label>
                                            <Field
                                                type='number'
                                                className='form-control'
                                                placeholder='Enter Phone Number'
                                                name='phone'
                                                disabled={role === 'intermediate'} 
                                            />
                                            <ErrorMessage name='phone' component='div' className="text-danger" />
                                        </div>

                                        <div className="col-md-6 px-3 py-2">
                                            <label className="form-label">Skills</label>
                                            <div className="chips-input-wrapper" style={{ position: 'relative' }}>
                                                <div className="chips-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={newChip}
                                                        onChange={(e) => setNewChip(e.target.value)}
                                                        onKeyDown={handleKeyDown}
                                                        placeholder="Add a chip and press Enter"
                                                        disabled={role === 'intermediate'} 
                                                    />
                                                    <ErrorMessage name='skill' component='div' className="text-danger" />
                                                    {values.skill.map((chip, index) => (
                                                        <div key={index} className="chip" style={{ display: 'flex', alignItems: 'center', padding: '0 8px', backgroundColor: '#007bff', color: 'white', borderRadius: '16px' }}>
                                                            {chip}
                                                            <button
                                                                type="button"
                                                                className="btn-close btn-close-white ml-2"
                                                                aria-label="Close"
                                                                onClick={() => handleRemoveChip(chip)}
                                                                disabled={role === 'intermediate'} 
                                                            ></button>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Current Salary</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter Current Salary'
                                                name='current_salary'
                                                disabled={role === 'intermediate'} 
                                            />
                                            <ErrorMessage name='current_salary' component='div' className="text-danger" />

                                        </div>
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Expected Salary</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter Expected Salary'
                                                name='expected_salary'
                                                disabled={role === 'intermediate'} 
                                            />
                                            <ErrorMessage name='expected_salary' component='div' className="text-danger" />

                                        </div>
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Status</label>
                                            <Field as="select" className='form-select' name="status"  disabled={role === 'intermediate'} >
                                                <option value="" selected hidden label="Select User Role" />
                                                <option value="Immediate Joiner">Immediate Joiner</option>
                                                <option value="Notice Period">Notice Period</option>
                                            </Field >
                                            <ErrorMessage name='status' component='div' className="text-danger" />

                                        </div>
                                        {values.status === "Notice Period" && (
                                            <div className="col-md-6 px-3 py-2">
                                                <label className='form-label'>Notice period</label>
                                                <Field
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Enter your password'
                                                    name='notice_period'
                                                    disabled={role === 'intermediate'} 
                                                />
                                                <ErrorMessage name='notice_period' component='div' className="text-danger" />

                                            </div>)}
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Sex</label>
                                            <Field as="select" className='form-select' name="sex"  disabled={role === 'intermediate'} >
                                                <option value="" selected hidden label="Select the Gender" />
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Others">Others</option>
                                            </Field >
                                            <ErrorMessage name='sex' component='div' className="text-danger" />

                                        </div>

                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Location</label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter Location'
                                                name='location'
                                                disabled={role === 'intermediate'} 
                                            />
                                            <ErrorMessage name='location' component='div' className="text-danger" />

                                        </div>
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Preferred </label>
                                            <Field as="select" className='form-select' name="mode"  disabled={role === 'intermediate'} >
                                                <option value="" selected hidden label="Select the Preferred" />
                                                <option value="Male">Remote</option>
                                                <option value="Female">Work From Office</option>
                                            </Field >
                                            <ErrorMessage name='mode' component='div' className="text-danger" />

                                        </div>
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Experience (Year) </label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter Total Experience'
                                                name='years_of_exp'
                                                disabled={role === 'intermediate'} 
                                            />
                                            <ErrorMessage name='years_of_exp' component='div' className="text-danger" />

                                        </div>
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Language </label>
                                            <Field as="select" className='form-select' name="language"  disabled={role === 'intermediate'}  >
                                                <option value="" selected hidden label="Select User Role" />
                                                <option value="N5">N5</option>
                                                <option value="N4">N4</option>
                                                <option value="N3">N3</option>
                                                <option value="N2">N2</option>
                                                <option value="N1">N1</option>
                                            </Field >
                                            <ErrorMessage name='language' component='div' className="text-danger" />

                                        </div>
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Resume</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="file"
                                                onChange={(event) => {
                                                    setFieldValue('file', event.target.files[0]);  // Set the file to Formik state
                                                }}
                                            />
                                            <ErrorMessage name='file' component='div' className="text-danger" />

                                            {values.file && typeof values.file === 'string' && (
                                                <a href={values.file} target="_blank" rel="noopener noreferrer" className="d-block mt-2">
                                                    Download Current File
                                                </a>
                                            )}
                                        </div>
                                        <div className="col-md-6 px-3 py-2">
                                            <label className='form-label'>Japanes Resume</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="japanese_file"
                                                onChange={(event) => {
                                                    setFieldValue('japanese_file', event.target.files[0]);  // Set the file to Formik state
                                                }}
                                            />
                                            <ErrorMessage name='japanese_file' component='div' className="text-danger" />

                                            {values.japanese_file && typeof values.japanese_file === 'string' && (
                                                <a href={values.japanese_file} target="_blank" rel="noopener noreferrer" className="d-block mt-2">
                                                    Download Current File
                                                </a>
                                            )}
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col ">

                                                <button className='cancel-button ms-2' onClick={handleBack}>Cancel</button>
                                                <button className="btn defult-button ms-2" type='submit'>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    )
}
