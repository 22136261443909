import { useEffect, useState } from "react";
import Header from "../../../Navbar/Header";
import Sidebar from "../../sidebar/Sidebar";
import EmployeeData from "./EmployeeData";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deletEmployee, getEmployee } from "../hrmsSlice";
import StyledDataTable from "../../../../component/resuable/StyledComponent";
import Button from "react-bootstrap/esm/Button";
import DeleteDialog from "../../../../component/resuable/deleteDialog";
import { toast } from "react-toastify";
import AddBulkemployee from "./EmployeeBulkAdd";

import DatePicker from "react-datepicker";
export default function Employee() {
    const location = useLocation();
    const companyData = location.state.row;
    const filteredDate = location.state.filterdate;
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [rowData, setRowData] = useState([]);
    const [search, setSearch] = useState('')
    const [filterdate, setFilterdate] = useState(filteredDate||null)
    const [selectedData, setselectedData] = useState("");
    const [editData, setEditData] = useState("");
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const employeeData = useSelector(state => state.hrms.employeeData);

    const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page

    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        if (companyData === null) {
            navigate('/hrms')
        }

    }, [companyData])

    function formatDate(date) {
        if (date) {
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return { month, year };
        }
        return { month: '', year: '' };
    }



    useEffect(() => {
        if (companyData !== null) {
            const { month, year } = filterdate ? formatDate(filterdate) : { month: '', year: '' };

            dispatch(getEmployee({
                id: companyData.company_id, page: currentPage, perPage: itemsPerPage, month: month,
                year: year,search:search
            }));

        }
    }, [dispatch, currentPage, itemsPerPage, filterdate,search])
    const [cellclicked, setCellclicked] = useState("");

    const handleClick = () => {
        setCellclicked('add')
        setEditData('');
    }
    const handleBack = () => {
        setCellclicked('')
        setEditData('');
    }
    const handleBackCompany = () => {
        navigate('/hrms')
    }


    useEffect(() => {
        if (employeeData.employees && Array.isArray(employeeData.employees)) {
            const formattedData = employeeData.employees.map((data) => ({
                name: data.name,
                email: data.email,
                phone_number: data.phone_number,
                account_number: data.account_number,
                total_salary: data.total_salary,
                employee_id: data.employee_id,
                basic_pay: data.basic_pay,
                employee_code: data.employee_code,
                hra: data.hra,
                other_allowances: data.other_allowances,
                salary_process: data.salary_process,
                payslip_file_path: data.payslip_file_path
            }));
            setRowData(formattedData);
        }
    }, [employeeData]);

    const handleEditClick = (data) => {
        setCellclicked('edit');
        setEditData(data);
    };


    const handleDeleteClick = (data) => {
        setselectedData(data.name)
        setDeleteId(data.employee_id);
        setEditData(data);
        setShowDeleteDialog(true);
    };


    const handleDelete = async (id) => {
        const response = await dispatch(deletEmployee(id));
        if (response.payload.status === 200) {
            toast.success("Employee Deleted successfully");
        } else {
            toast.error(response.payload.response.data.message)
        }
    };
    const columns = [
        {
            name: "S.No",

            selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
            width: '6%'
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            width: '15%'
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            width: '20%'
        }, {
            name: 'Phone',
            selector: (row) => row.phone_number,
            width: '10%'
        },

        {
            name: 'Total Salary',
            selector: (row) => row.total_salary,
            width: '10%'
        },
        {
            name: 'Salary process',
            selector: (row) => row.salary_process,
            width: '12%'
        },
        {
            name: 'Download ',
            selector: (row) => (
                row.payslip_file_path ? (
                    <a href={row.payslip_file_path} target="_blank" rel="noopener noreferrer">
                        Download
                    </a>
                ) : (
                    <span>Payroll not run</span>
                )
            ),
            width: '15%',
        },

        {
            name: "Action",
            cell: (row) => (
                <>
                    <Button variant="danger" className="table-viewcustom-button" onClick={() => handleEditClick(row)}>
                        <i className="fas fa-eye px-2"></i>
                    </Button>
                    <Button variant="primary" className="table-custom-button" onClick={() => handleDeleteClick(row)} style={{ marginRight: '8px' }} >
                        <i className="fas fa-trash px-2"></i>
                    </Button>

                </>
            ),
            button: true,

            width: '12%'
        }
    ];

    const [openEmployeeAdd, setOpenEmployeeAdd] = useState(false)


    const AddBulkEmployee = () => {
        setOpenEmployeeAdd(true)
    }
    const closeBulkEmployee = () => {
        setOpenEmployeeAdd(false)
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newPerPage) => {
        setItemsPerPage(newPerPage);  // Update rows per page
        setCurrentPage(1);  // Reset to the first page when changing rows per page
    };

    const renderMonthContent = (month, shortMonth, longMonth) => {
        const tooltipText = `Tooltip for month: ${longMonth}`;
        return <span title={tooltipText}>{shortMonth}</span>;
    };
    return (
        <div className='container-fluid'>
            <div className='row'>
                <Header />
                <div className='col-2 p-0'>
                    <Sidebar />
                </div>
                <div className='col-10 mt-2 px-3'>
                    <div className='card custom-card p-3'>
                        {cellclicked === "" && (
                            <div>
                                <div className="col-md-12">
                                    <div className="row py-3">
                                        <div className="col-md-3">
                                            <h6 className="logo-text">{companyData?.company_name}</h6>

                                        </div>
                                        <div className="col-md-4">
                                            <div className='col-md-12 d-flex justify-content-end align-items-center px-2 candidate-list'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-2'> {/* Add margin to the right for spacing */}
                                                        <DatePicker
                                                            className='form-control datefilter'
                                                            placeholderText='Select filter'
                                                            renderMonthContent={renderMonthContent}
                                                            showMonthYearPicker
                                                            dateFormat="MM/yyyy"
                                                            selected={filterdate}
                                                            onChange={(date) => {
                                                                setFilterdate(date);
                                                            }}
                                                        />
                                                    </div>
                                                    <button
                                                        className="table-viewcustom-button p-1 btn btn-primary me-2" // Add margin to the right
                                                        onClick={() => setFilterdate(null)}
                                                    >
                                                        <i className="fa fa-retweet" aria-hidden="true"></i>
                                                    </button>
                                                    <input
                                                        className='form-control search-btn'
                                                        placeholder='Search' onChange={(e) => setSearch(e.target.value)} 
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-5 text-end">


                                            <div className="d-flex justify-content-end">

                                                <button className="btn defult-button ms-2 mx-2" type="button" onClick={AddBulkEmployee}>
                                                    Add Bulk Employee
                                                </button>
                                                <button className="btn defult-button ms-2 mx-2" type="button" onClick={handleClick}>
                                                    Add New
                                                </button>
                                                <button className="cancel-button ms-2 mx-2" type="button" onClick={handleBackCompany}>
                                                    Back
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="mt-2">
                                    <StyledDataTable
                                        columns={columns}
                                        data={rowData.length > 0 ? rowData : []}
                                        pagination
                                        paginationServer
                                        paginationPerPage={itemsPerPage}
                                        paginationRowsPerPageOptions={[5, 10, 15]}
                                        paginationTotalRows={employeeData.total}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                        responsive
                                        persistTableHead={true}
                                    />
                                </div>
                            </div>
                        )}
                        {cellclicked === "add" && (
                            <EmployeeData handleBack={handleBack} companydetails={companyData} />
                        )}
                        {cellclicked === "edit" && (
                            <EmployeeData handleBack={handleBack} editData={editData} companydetails={companyData} />
                        )}
                        {showDeleteDialog && deleteId && (
                            <DeleteDialog
                                id={deleteId}
                                data={selectedData}
                                onDelete={handleDelete}
                                onClose={() => setShowDeleteDialog(false)}
                            />
                        )}

                        {openEmployeeAdd && (
                            <AddBulkemployee closeBulkEmployee={closeBulkEmployee} onClose={() => setOpenEmployeeAdd(false)} companydetails={companyData} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}