
import { useEffect, useState } from "react";

import ResumeData from "./ResumeData";
import { useDispatch, useSelector } from "react-redux";
import { deleteResume, getResume } from "./ResumeSlice";
import { Button } from "react-bootstrap";
// import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import { toast } from "react-toastify";
import DeleteDialog from "../../../component/resuable/deleteDialog";
import StyledDataTable from "../../../component/resuable/StyledComponent";
import Header from "../../Navbar/Header";
import Sidebar from "../sidebar/Sidebar";
import Cookies from 'js-cookie';

export default function IntermediateResume() {
    let role = Cookies.get("role");
    const dispatch = useDispatch();
    const [cellclicked, setCellclicked] = useState("");
    const [search, setSearch] = useState("");

    const [selectedValue, setSelectedValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [selectedData, setselectedData] = useState("");
    const [editData, setEditData] = useState("");
    const [activeTab, setActiveTab] = useState("pending");
    const handleClick = () => {
        setCellclicked('add')
        setEditData('');
    }
    const handleBack = () => {
        setCellclicked('')
        setEditData('');
    }


    const handleDelete = async (id) => {
        const response = await dispatch(deleteResume(id));
        console.log(response);
        if (response.payload.status === 200) {
            toast.success("User Data Deleted successfully");
        }
    };


    const handleEditClick = (data) => {
        setCellclicked('edit');
        setEditData(data);
    };


    const handleDeleteClick = (data) => {
        setselectedData(data.name)
        setDeleteId(data.id);
        setEditData(data);
        setShowDeleteDialog(true);
    };


    const columns = [
        {
            name: "S.No",

            selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
            width: '6%'
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            width: '10%'
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            width: '15%'
        }, {
            name: 'Number',
            selector: (row) => row.phone,
        }, {
            name: 'Skill',
            selector: (row) => row.skill,
            width: '15%'
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            width: '15%'
        },

        {
            name: 'Language',
            selector: (row) => row.language,
        },
        {
            name: 'Download',
            selector: (row) => (
                <a href={row.file_download} target="_blank" rel="noopener noreferrer">
                    Download
                </a>
            ),
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    <Button variant="danger" className="table-viewcustom-button" onClick={() => handleEditClick(row)}>
                        <i className="fas fa-eye px-2"></i>
                    </Button>
                    {role === 'admin' && (
                        <Button variant="primary" className="table-custom-button" onClick={() => handleDeleteClick(row)} style={{ marginRight: '8px' }} >
                            <i className="fas fa-trash px-2"></i>
                        </Button>)}

                </>
            ),
            button: true
        }
    ];

    const [rowData, setRowData] = useState([]);
    const candiateData = useSelector(state => state.Resume.resumeData);



    useEffect(() => {
        const fetchData = async () => {
            const status = activeTab === "pending" ? "pending" : "completed";

            await dispatch(getResume({ page: currentPage, perPage: itemsPerPage, search: search, status: selectedValue, japanese_status_filter: status }));
        };

        fetchData();
    }, [dispatch, currentPage, itemsPerPage, search, selectedValue, activeTab]);



    useEffect(() => {
        console.log(candiateData)
        if (candiateData && Array.isArray(candiateData.candidates)) {
            const formattedData = candiateData.candidates.map((user) => ({
                name: user.name,
                email: user.email,
                phone: user.phone,
                skill: user.skill,
                status: user.status,
                current_salary: user.current_salary,
                expected_salary: user.expected_salary,
                sex: user.sex,
                file_download: user.file_download,
                location: user.location,
                mode: user.mode,
                id: user.id,
                years_of_exp: user.years_of_exp,
                language: user.language,
                japanese_file_path: user.japanese_file_path
            }));
            setRowData(formattedData);
        }
    }, [candiateData]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newPerPage) => {
        setItemsPerPage(newPerPage);  // Update rows per page
        setCurrentPage(1);  // Reset to the first page when changing rows per page
    };
    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab); // Set active tab
    };
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row justify-content-center'>
                                {cellclicked === "" && (
                                    <div>
                                        <div className="col-md-12">
                                            <div className="row py-3">
                                                <div className="col-md-3">
                                                </div>
                                                <div className="col-md-5 ">
                                                    <div className="d-flex">
                                                        <input placeholder="search" className="form-control me-4" onChange={(e) => setSearch(e.target.value)} />
                                                        <select value={selectedValue} onChange={handleSelectChange} className="form-select">
                                                            <option value="" disabled selected>Select an option</option>
                                                            <option value="Immediate Joiner">Immediate Joiner</option>
                                                            <option value="Notice Period">Notice Period</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className="col-md-4 text-end">
                                                    {role === 'admin' && (
                                                        <button className='btn defult-button defult-button-head mx-2' type="buttons" onClick={handleClick}>Add New</button>)}
                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-md-5 px-4'>
                                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link custom-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => handleTabClick("pending")}>Pending Items ({candiateData?.total})</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link custom-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => handleTabClick("completed")} >completed Items ({candiateData?.total})</button>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className='row'>
                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                    <div className="mt-2">
                                                        <StyledDataTable
                                                            columns={columns}
                                                            data={rowData.length > 0 ? rowData : []}
                                                            highlightOnHover
                                                            pagination
                                                            paginationServer
                                                            paginationPerPage={itemsPerPage}
                                                            paginationRowsPerPageOptions={[5, 10, 15]}
                                                            paginationTotalRows={candiateData.total}
                                                            onChangePage={handlePageChange}
                                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                                            responsive
                                                            persistTableHead={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                    <div className="mt-2">
                                                        <StyledDataTable
                                                            columns={columns}
                                                            data={rowData.length > 0 ? rowData : []}
                                                            highlightOnHover
                                                            pagination
                                                            paginationServer
                                                            paginationPerPage={itemsPerPage}
                                                            paginationRowsPerPageOptions={[5, 10, 15]}
                                                            paginationTotalRows={candiateData.total}
                                                            onChangePage={handlePageChange}
                                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                                            responsive
                                                            persistTableHead={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}


                                {cellclicked === "edit" && (
                                    <ResumeData handleBack={handleBack} editData={editData} />
                                )}
                                {showDeleteDialog && deleteId && (
                                    <DeleteDialog
                                        id={deleteId}
                                        data={selectedData}
                                        onDelete={handleDelete}
                                        onClose={() => setShowDeleteDialog(false)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
