import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";

import { apiDelete, apiGet, apiPost, apiPut } from "../../../service/apiService";
import axios from "axios";



  const initialState = {
    Loading:false,
    resumeData: [],
  
  };

  
export const addResume = createAsyncThunk(
    'resume/addResume',
    async (param) => {
        try {
            const response = await apiPost('resume',param , {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
          });
            return response;
        } catch (error) {
            throw error;
        }
    }
);


export const udpateResume = createAsyncThunk(
  'resume/udpateResume',
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await apiPut(`resume/${id}`, formData);
      return response; 
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getResume = createAsyncThunk(
    'resume/getResume',
    async (params,{rejectWithValue}) => {
        try {
            // const response = await baseApi.get('/candidates');
            const { page, perPage,search,status,japanese_status_filter} = params;
            const response = await apiGet(`resume?page=${page}&per_page=${perPage}&search=${search}&status=${status}&japanese_file_status=${japanese_status_filter}`);
            return response.data;
        } catch (error) {
          return rejectWithValue(error);
        }
    }
);


export const deleteResume = createAsyncThunk(
  'user/deleteResume',
  async (id) => {
      try {
          const response = await apiDelete(`resume/${id}`);
          return response;
      } catch (error) {
          throw error;
      }
  }
);


const Candidate = createSlice({
    name:'Candidate',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
      builder
      .addCase(getResume.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getResume.fulfilled, (state,action) => {
        state.Loading = false;
        state.resumeData = action.payload
      })
      .addCase(getResume.rejected, (state,action) => {
        state.Loading = false;
      })

      .addCase(addResume.pending, (state) => {
        state.Loading = true;
      })
      .addCase(addResume.fulfilled, (state, action) => {
        state.Loading = false;
        if (action.payload && action.payload.status === 201 ) {
        state.resumeData = {
            ...state.resumeData,
            candidates: [
                ...state.resumeData.candidates, 
                action.payload.data 
            ]
        };
      }
    })
    
      .addCase(addResume.rejected, (state) => {
        state.Loading = false;
        
      })


      .addCase(deleteResume.pending, (state) => {
        state.Loading = true;
      })
      .addCase(deleteResume.fulfilled, (state, action) => {
        state.Loading = false;
        const deletedResumeId = action.payload.data.id;
        state.resumeData.candidates = state.resumeData.candidates.filter(resume => resume.id !== deletedResumeId);
    })
    
      .addCase(deleteResume.rejected, (state) => {
        state.Loading = false;
      })


      .addCase(udpateResume.pending, (state) => {
        state.Loading = true;
      })
      .addCase(udpateResume.fulfilled, (state, action) => {
        state.Loading = false;
        const updateCandiate = action.payload.data;
  
        state.resumeData.candidates = state.resumeData.candidates.map(user => user.id === updateCandiate.id ? updateCandiate : user );
      })

      .addCase(udpateResume.rejected, (state) => {
        state.Loading = false;

      })
    }
  })

  
export default Candidate.reducer;