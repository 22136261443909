// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-btn{
    width: 100%;
}

.custom-card{
    top: 75px !important;   
}
.custom-card label {
    font-weight: 500;
}

.secondary {
    color: rgb(182, 174, 174);
}

.error {
    color: #d61616;
}

.btn-focus-none:focus {
        outline: none !important;
        box-shadow: none !important;
}

.pdfContainer {
    position: relative;
    top: 75px !important;  
    height: 600px !important; 
}

.pdf {
    height: 100% !important;
}
.back-btn-container {
    display: flex;
    justify-content: end;
    margin-bottom: 5px;
}

.back-btn {
    background-color: #B32523 !important;
    color: #fff !important;
    border: 0;
    border-radius: 6px;
    padding: 5px 15px;
}

@media (max-width: 768px) {
    .search-btn{
        width: 90%;
    }
    .defult-button-head {
        width: unset;
        padding: 5px 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/layouts/jot/Jot.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;QACQ,wBAAwB;QACxB,2BAA2B;AACnC;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,wBAAwB;AAC5B;;AAEA;IACI,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,SAAS;IACT,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,YAAY;QACZ,iBAAiB;IACrB;AACJ","sourcesContent":[".search-btn{\r\n    width: 100%;\r\n}\r\n\r\n.custom-card{\r\n    top: 75px !important;   \r\n}\r\n.custom-card label {\r\n    font-weight: 500;\r\n}\r\n\r\n.secondary {\r\n    color: rgb(182, 174, 174);\r\n}\r\n\r\n.error {\r\n    color: #d61616;\r\n}\r\n\r\n.btn-focus-none:focus {\r\n        outline: none !important;\r\n        box-shadow: none !important;\r\n}\r\n\r\n.pdfContainer {\r\n    position: relative;\r\n    top: 75px !important;  \r\n    height: 600px !important; \r\n}\r\n\r\n.pdf {\r\n    height: 100% !important;\r\n}\r\n.back-btn-container {\r\n    display: flex;\r\n    justify-content: end;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.back-btn {\r\n    background-color: #B32523 !important;\r\n    color: #fff !important;\r\n    border: 0;\r\n    border-radius: 6px;\r\n    padding: 5px 15px;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .search-btn{\r\n        width: 90%;\r\n    }\r\n    .defult-button-head {\r\n        width: unset;\r\n        padding: 5px 20px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
