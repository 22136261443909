import {configureStore} from '@reduxjs/toolkit'
import loginreducer from './LoginSlice'
import CandidateReducer from './CandidateSlice'
import StaffReducer from './StaffSlice'
import BulkaddReducer from './BulkaddSlice'
import NotificationReducer from './NotificationSlice'
import CountReducer from './CountSlice'
import ChatReducer from './ChatSlice'
import RemarkReducer from './RemarkSlice'
import DashboardReducer from './DashboardSlice'
import ResumeReducer from '../pages/layouts/resume/ResumeSlice'
import DashboardResumeReducer from '../pages/layouts/dashboard/DashboardSlice'
import HRMSReducer from '../pages/layouts/hrms/hrmsSlice'
import HolidayReducer from '../pages/layouts/hrms/company/HolidaySlice'

export const store = configureStore({
    reducer:{
        Login:loginreducer,
        Candidate:CandidateReducer,
        Staffdata:StaffReducer,
        Bulkadd:BulkaddReducer,
        Notification:NotificationReducer,
        Count:CountReducer,
        Chat:ChatReducer,
        Remark:RemarkReducer,
        Dashboard:DashboardReducer,
        
        Resume: ResumeReducer,
        DashboardResume:DashboardResumeReducer,
        hrms:HRMSReducer,
        holiday:HolidayReducer

    }

})