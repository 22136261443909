import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiDelete, apiGet, apiPost, apiPut } from "../../../service/apiService";



const initialState = {
  Loading: false,
  admissionList: [],
  postAdmissionData: [],
  admissionDataById:[],
  updateAdmissionData:[],
  deleteAdmissionData:[]

};

export const getAdmissionList = createAsyncThunk(
  'japaneseAdmission/getAdmissionList',
  async (params, { rejectWithValue }) => {
      try {
          const { page, search } = params;
          const response = await apiGet(`japanese-admission?pageNo=${page}&searchText=${search}`);
          return response.data;
      } catch (error) {
          return rejectWithValue(error);
      }
  }
);

export const getAdmissionDataById = createAsyncThunk(
  'japaneseAdmission/getAdmissionDataById',
  async (param, { rejectWithValue }) => {
      try {
          const response = await apiGet(`japanese-admission/${param}`);
          return response.data;
      } catch (error) {
          return rejectWithValue(error);
      }
  }
);


export const postAdmission = createAsyncThunk(
  'japaneseAdmission/postAdmission',
  async (param, { rejectWithValue }) => {
    try {
      const response = await apiPost('japanese-admission', param, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateAdmission = createAsyncThunk(
  'japaneseAdmission/updateAdmission',
   async ({id, data}, { rejectWithValue }) => {
    try {
      const response = await apiPut(`japanese-admission/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred';
      return rejectWithValue(errorMessage);
    }
});

export const deleteAdmission = createAsyncThunk(
  'japaneseAdmission/deleteAdmission',
  async (id) => {
    try {
      const response = await apiDelete(`japanese-admission/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const japaneseAdmissionSlice = createSlice({
  name: 'japaneseAdmission',
  initialState,
  reducers: {
    clearUpdateAdmission: (state) => {
      state.updateAdmissionData = [];
  }
  },

  extraReducers: (builder) => {
    builder
      
      .addCase(getAdmissionList.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getAdmissionList.fulfilled, (state, action) => {
        state.Loading = false;
        state.admissionList = action.payload
      })
      .addCase(getAdmissionList.rejected, (state, action) => {
        state.Loading = false;
      })

      .addCase(getAdmissionDataById.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getAdmissionDataById.fulfilled, (state, action) => {
        state.Loading = false;
        state.admissionDataById = action.payload
      })
      .addCase(getAdmissionDataById.rejected, (state, action) => {
        state.Loading = false;
      })

      .addCase(postAdmission.pending, (state) => {
        state.Loading = true;
      })
      .addCase(postAdmission.fulfilled, (state, action) => {
        state.Loading = false;
        state.postAdmissionData = action.payload
      })
      .addCase(postAdmission.rejected, (state, action) => {
        state.Loading = false;
      })
      
      .addCase(updateAdmission.pending, (state) => {
        state.Loading = true;
      })
      .addCase(updateAdmission.fulfilled, (state, action) => {
        state.Loading = false;
        state.updateAdmissionData = action.payload
      })
      .addCase(updateAdmission.rejected, (state, action) => {
        state.Loading = false;
      })

      .addCase(deleteAdmission.pending, (state) => {
        state.Loading = true;
      })
      .addCase(deleteAdmission.fulfilled, (state, action) => {
        state.Loading = false;
        state.deleteAdmissionData = action.payload
      })
      .addCase(deleteAdmission.rejected, (state, action) => {
        state.Loading = false;
      })


  }
})

export const { clearUpdateAdmission } = japaneseAdmissionSlice.actions

export default japaneseAdmissionSlice.reducer;