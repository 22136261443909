import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appliedExamsListGet } from './JotSlice';
import Pagination from '../../../component/Pagination';
import StyledDataTable from '../../../component/resuable/StyledComponent';


const User_list = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchdata = props.search
    const filterdate = props.filter

    const [currentPage, setCurrentPage] = useState(1);
    const [rowData, setRowData] = useState([]);

    const { appliedExamsList } = useSelector(state => state.jot);
    const appliedExamListData = appliedExamsList?.data;

    const totalRecords = appliedExamsList?.no_of_records || 0;
    const pageSize = 10;

    useEffect(() => {
        const filter = filterdate !== null ? filterdate : ""
        const search = searchdata !== undefined ? searchdata : ""
        const params = {
            page: currentPage,
            search: search,
            filter: filter
        }
        dispatch(appliedExamsListGet(params));
    }, [currentPage, searchdata, filterdate])

    const handleEditClick = (id) => {
        navigate(`edit/${id}`)
    }

    const columns = [

        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,

        },
        {
            name: 'Name',
            selector: (row) => row.first_name, sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email, sortable: true,
        },
        {
            name: 'Phone',
            selector: (row) => row.phone, sortable: true,
        },
        {
            name: 'Gender',
            selector: (row) => row.gender, sortable: true,
        },
        {
            name: 'D.O.B',
            selector: (row) => row.dob, sortable: true,
        },
        {
            name: 'Exam Level',
            selector: (row) => row.exam_level, sortable: true,
        },
        {
            name: 'Address',
            selector: (row) => row.address, sortable: true,
        },
        {
            name: 'State',
            selector: (row) => row.state, sortable: true,
        },
        {
            name: 'City',
            selector: (row) => row.city, sortable: true,
        },
        {
            name: 'Post Code',
            selector: (row) => row.postal_code, sortable: true,
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    <Button className='table-viewcustom-button ' onClick={() => handleEditClick(row.user_id)}><i class="fas fa-edit px-2"></i></Button>
                </>
            ),
            button: true,
        },


    ];

    useEffect(() => {
        if (appliedExamListData && Array.isArray(appliedExamListData)) {
            const formattedData = appliedExamListData.map((data) => ({
                first_name: data.first_name + " " + data.last_name,
                email: data.email,
                phone: data.phone,
                gender: data.gender,
                dob: data.dob,
                exam_level: data.exam_level,
                address: data.address,
                state: data.state,
                city: data.city,
                postal_code: data.postal_code,
                user_id: data.user_id,
            }));
            setRowData(formattedData);
        }
    }, [appliedExamsList]);

    return (
        <>
            <div className='' >
                <div className="mt-2">
                    <StyledDataTable
                        columns={columns}
                        data={rowData.length > 0 ? rowData : []}
                        highlightOnHover
                        responsive
                        persistTableHead={true}
                    />
                </div>
            </div>


            <div >
                {appliedExamListData?.length > 1 ?
                    <Pagination
                        className="pagination-bar"
                        currentPage={appliedExamsList?.current_page}
                        totalCount={totalRecords}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                    :
                    ''
                }
            </div></>
    )
}

export default User_list