import { createSlice} from '@reduxjs/toolkit'
import { login, logout } from '../middleware/Middleware';


const LoginSlice = createSlice({
    name:'Login',
    initialState:{
        loading:false,
        user:null,
        error:null,
        userlogout:null
    },
    extraReducers:(builder)=>{
        builder
    .addCase(login.pending,(state,action)=>{
        state.loading = true;
    })
    .addCase(login.fulfilled,(state,action)=>{
        state.loading = false;            
        state.user=action.payload;
        state.error=null;
    })
    
    .addCase(login.rejected,(state,action)=>{
        state.loading = false;            
        state.user=null;
        state.error=action.error.message
    })


    .addCase(logout.pending,(state)=>{
        state.loading = true;
    })
    .addCase(logout.fulfilled,(state,action)=>{
        state.loading = false;            
        state.userlogout=action.payload;
        state.error=null;
    })
    
    .addCase(logout.rejected,(state,action)=>{
        state.loading = false;            
        state.userlogout=null;
        state.error=action.error.message
    })
}

})

export default LoginSlice.reducer


