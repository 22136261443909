export const endPoint = {
    login: "login",
    candidate: "candidate",
    staff: "staff",
    bulkadd: "candidate/bulk/add",
    notification: "pending_users",
    logout: "logout",
    download_candidate: "download_candidates",
    staffcount: "candidate_count",
    get_staff: "user_list",
    group: "group",
    groupname: "groupname",
    send_message: "send_message",
    add_group: "group",
    japan: "titp_registration",
    remark: "candidate/fetch/details",
    status_update: "candidate/fetch/status",
    admin_count: "candidate_count/admin",
    over_count: "candidate/overall",
    group_msg_delete: "group_del",
    msg_delete: "message_del",
    category: "category",
    category_staff: "category/staff",
    trade: "trade",
    staff_report: "staff/attendance",
    trade_report: "trade/attendance",
    announcement_group: "announcement/group",
    announcement: "announcement",
    announcement_buldadd: "announcement/bulk/add",
    mapping_add: 'multiple_add',
    mapping_get: 'candidate/announcement',
    msg_approval: 'create_template_message',
    content_approval: 'content_approvals',
    get_status_by_body: 'get_status_by_body',
    whatspp_send_message: 'whatspp_send_message',
    delete_content_template: 'delete_content_template',
    resource: "resource_registration",
    Israel: "israel_registration",
    admission: "admissions",
    singapore: "singapore",
};



