// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-btn{
    width: 100%;
}

.custom-card{
    top: 75px !important;   
}
.custom-card label {
    font-weight: 500;
}

.secondary {
    color: rgb(182, 174, 174);
}

.error {
    color: #d61616;
}


@media (max-width: 768px) {
    .search-btn{
        width: 90%;
    }
    .defult-button-head {
        width: unset;
        padding: 5px 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/layouts/jot/Jot.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;;AAGA;IACI;QACI,UAAU;IACd;IACA;QACI,YAAY;QACZ,iBAAiB;IACrB;AACJ","sourcesContent":[".search-btn{\r\n    width: 100%;\r\n}\r\n\r\n.custom-card{\r\n    top: 75px !important;   \r\n}\r\n.custom-card label {\r\n    font-weight: 500;\r\n}\r\n\r\n.secondary {\r\n    color: rgb(182, 174, 174);\r\n}\r\n\r\n.error {\r\n    color: #d61616;\r\n}\r\n\r\n\r\n@media (max-width: 768px) {\r\n    .search-btn{\r\n        width: 90%;\r\n    }\r\n    .defult-button-head {\r\n        width: unset;\r\n        padding: 5px 20px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
