import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../component/Pagination';
import StyledDataTable from '../../../component/resuable/StyledComponent';
import LoadingSpinner from '../../../component/resuable/LoadingSpinner';
import { deleteAdmission, getAdmissionList } from './admissionSlice';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const AdmissionList = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchdata = props.search

    const [currentPage, setCurrentPage] = useState(1);
    const [rowData, setRowData] = useState([]);
    const [admissionModifideData, setAdmissionModifideData] = useState({
            deleteModel: false,
            deleteIdData: null,
            currentPage: 1,
            totalCount: 0,
            pageSize: 10,
        });

        const ADMISSION_DATA = useSelector(state => state.admission);
        const { admissionList } = useSelector(state => state.admission);
        const admissionListData = admissionList?.candidates;

        console.log(admissionList)
    
        const pageSize = 10;
    
        useEffect(() => {
            const search = searchdata !== undefined ? searchdata : ""
            const params = {
                page: currentPage,
                search,
            }
            dispatch(getAdmissionList(params));
        }, [currentPage, dispatch, searchdata])
    
        const handleEditClick = (id) => {
            navigate(`edit/${id}`)
        }

        const deletefunction = async (admissionId) => {
            const search = searchdata !== undefined ? searchdata : ""
               try {
                 const response = await dispatch(deleteAdmission(admissionId));
                 const params = {
                    page: currentPage,
                    search,
                }
                dispatch(getAdmissionList(params));
           
                 toast(response.payload.data.message, {
                   type: "success"
                 });
               } catch (error) {
                 if (error.response && error.response.data && error.response.data.message) {
                   toast.error(error.response.data.message);
                 } else {
                   toast.error("An error occurred while deleting the admission.");
                 }
               }
             };

             const columns = [

                {
                    name: "S.No",
                    selector: (row, index) => index + 1,
                    sortable: true,
                    width: "7%"
        
                },
                {
                    name: 'Name',
                    selector: (row) => row.name !== null ? row.name : "N/A", sortable: true,
                },
                {
                    name: 'Admission Number',
                    selector: (row) => row.admission_number !== null ? row.admission_number : "N/A", sortable: true,
                },
                {
                    name: 'Admission Date',
                    selector: (row) => row.date_of_admission !== null ? row.date_of_admission : "N/A", sortable: true,
                },
                {
                    name: 'Trade',
                    selector: (row) => row.trade !== null ? row.trade : "N/A", sortable: true,
                },
                {
                    name: 'Application Status',
                    selector: (row) => row.applied !== null ? row.applied : "N/A", sortable: true,
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <>
                            <Button className='table-viewcustom-button ' onClick={() => handleEditClick(row.id)}><i class="fas fa-edit px-2"></i></Button>
                            <Button className='table-custom-button m-1' onClick={() => setAdmissionModifideData((prs) => {
                                return {
                                    ...prs,
                                    deleteIdData: {id:row.id, name: row.name},
                                    deleteModel: true
                                };
                            })
                            }
        
                            ><i className='fa fa-trash px-3'></i></Button>
                        </>
                    ),
                    button: true,
                    width: "13%"
                },
        
        
            ];
            useEffect(() => {
                if (admissionListData && Array.isArray(admissionListData)) {
                    const formattedData = admissionListData.map((data) => ({
                        name: data.name,
                        admission_number: data.admission_number,
                        date_of_admission: data.date_of_admission,
                        trade: data.trade,
                        applied: data.applied,
                        id: data.id,
                    }));
                    setRowData(formattedData);
                }
            }, [admissionList, admissionListData]);
            
            return (
                <>
                    {ADMISSION_DATA?.loading && <LoadingSpinner />}
                    <div className='' >
                        <div className="mt-2">
                            <StyledDataTable
                                columns={columns}
                                data={rowData.length > 0 ? rowData : []}
                                highlightOnHover
                                responsive
                                persistTableHead={true}
                            />
                        </div>
                    </div>

                    <div >
                {admissionList?.totalPages > 0 ?
                    <Pagination
                    className="pagination-bar"
                    currentPage={admissionList?.currentPage}  // Corrected property
                    totalCount={admissionList?.totalCandidates}  // Corrected property
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                />
                    :
                    ''
                }
            </div>
            <Modal show={admissionModifideData.deleteModel} onClick={() => setAdmissionModifideData((prs) => {
                return {
                    ...prs,
                    deleteModel: false,
                    deleteIdData: null,
                }
            })}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete operation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this admission <b>{admissionModifideData?.deleteIdData?.name}</b>?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setAdmissionModifideData((prs) => {
                        return {
                            ...prs,
                            deleteModel: false,
                            deleteIdData: null,
                        }
                    })}>
                        Close
                    </Button>
                    <Button className="model-save-button" onClick={() => deletefunction(admissionModifideData?.deleteIdData?.id)}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            
            </>
    )
}
export default AdmissionList