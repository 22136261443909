import React, { useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../middleware/Middleware';

import { useNavigate } from 'react-router-dom'
import { LoginErrorLabel } from '../../config/labels/ErrorLabel';
import { LoginLabel } from '../../config/labels/PageLabels';
import { toast } from 'react-toastify';


import Cookies from 'js-cookie';

export default function Login() {


  const dispatch = useDispatch()
  const navigate = useNavigate()


  const { error, user } = useSelector((state) => state.Login);

  useEffect(() => {
    if (user?.status === 200) {
      toast('User login successfully', {
        type: "success"
      });
     
      
      const { access_token, role, user_id } = user.data;
      const expirationDate = new Date(new Date().getTime() + 25 * 24 * 60 * 60 * 1000);
      Cookies.set('access_token', access_token, { expires: expirationDate, path: '/' });
      Cookies.set('role', role, { expires: expirationDate, path: '/' });
      Cookies.set('user_id', user_id, { expires: expirationDate, path: '/' });

      if (role !== 'manager'){
        navigate('/dashboard')
      }else{
        navigate('/remarks')
      }
    }else{
   
      toast(user?.data?.message, {
        type: "error"
      });
    }

  }, [user]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(LoginErrorLabel.email_required),
    password: Yup.string().required(LoginErrorLabel.password_required)
  })


  const handlesubmit = (values) => {
    const data = {username: values.username.trim(),password: values.password.trim(), fcmToken:""}
    dispatch(login(data));
  };
  return (
    <div className='bg'>
      <div className='container'>
        <div className='d-flex align-middle justify-content-center align-items-center vh-100'>
          <div className='card card-width card-border' >
            <div className='row p-4'>
              <div className='img-fluid text-center'>
                <img src={require('../../assests/logo.png')} alt='not found' className='login-logo' />
              </div>
              <h2 className='text-center'>Welcome Back</h2>

              <Formik
                initialValues={{ username: '', password: '', 
                fcmToken:'' }}
                validationSchema={validationSchema}
                onSubmit={handlesubmit}
              >

                <Form>
                  <div className='px-3 py-2'>
                    <label className='form-label'>{LoginLabel.login}</label>
                    <Field type='text' className='form-control' placeholder='Enter your name' name='username' />
                    <ErrorMessage name='username' component='div' className="text-danger" />
                  </div>

                  <div className='px-3 py-2'>
                    <label className='form-label'>{LoginLabel.password}</label>
                    <Field type='password' className='form-control' placeholder='Enter your password' name='password' />
                    <ErrorMessage name='password' component='div' className="text-danger" />
                  </div>
                  <div>
                  </div>
                  
                  <div className='text-center mt-2'>
                    <button className="btn defult-button" type="submit">Login</button>
                  </div>

                </Form>
              </Formik>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}