import React, { useEffect, useState } from 'react'

import Sidebar from '../sidebar/Sidebar'
import '../sidebar/Sidebar.css'
import Header from '../../Navbar/Header';
import { clearstaff } from '../../../store/StaffSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { staffDelete, staffget } from '../../../middleware/StaffMiddleware';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Pagination from '../../../component/Pagination';

export default function Staff() {
  const navicate = useNavigate()
  const dispatch = useDispatch()

  const staffData = useSelector(state => state.Staffdata);



  const [search, setSearch] = useState()

  const [staffModifideData, setStaffModifideData] = useState({
    deleteModel: false,
    deleteIdData: null,
    staffDataList: null,
    currentPage: 1,
    totalCount: 0,
    pageSize: 10,
    search: ''

  });


  const addstaff = () => {
    navicate('new')
  }


  useEffect(() => {
    dispatch(clearstaff())
    if (staffData?.staff?.status === 200) {
      setStaffModifideData((prs) => {
        return {
          ...prs,
          staffDataList: staffData?.staff?.data,
          deleteModel: false,
          deleteIdData: null,
          totalCount: staffData?.staff?.data?.totalStaff,

        }
      })

    }
    if (staffData?.deleteData?.status === 200) {
      toast(staffData?.deleteData?.data?.message, {
        type: "success",
        autoClose: 2000
      });
      dispatch(staffget())
    } else if (staffData?.deleteData?.message === "Network Error") {
      toast(staffData?.deleteData?.message, {
        type: "error",
        autoClose: 2000
      });
    }
    else {
      toast(staffData?.deleteData?.response?.data?.message, {
        type: "error",
        autoClose: 2000
      });
    }

  }, [dispatch, staffData])



  useEffect(() => {
    if (staffModifideData.currentPage) {

      getoveralldata();
    }
  }, [staffModifideData.currentPage, search])

  const deleteStaffData = () => {
    dispatch(staffDelete(staffModifideData?.deleteIdData?.id))
  }

  const setCurrentPage = (page) => {
    setStaffModifideData((prs) => {
      return {
        ...prs,
        currentPage: page,

      }
    })

  }


  const getoveralldata = () => {

    const obj = {};
    obj['pageNo'] = staffModifideData.currentPage;
    obj['count'] = staffModifideData.pageSize;
    if (search) {
      obj['searchText'] = search;
    }
    dispatch(staffget(obj))
  }







  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <Header />
          <div className='col-2 p-0'>
            <Sidebar />
          </div>
          <div className='col-10 mt-2 px-3'>
            <div className='card custom-card'>
              <div className='row'>
                <div className='col-md-6'></div>

                <div className='col-md-6 d-flex justify-content-end align-items-center p-2'>

                  <input className='mx-2 form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} />
                  <button className='btn defult-button defult-button-head mx-2' type="buttons" onClick={() => addstaff()}>Add New</button>
                                               
                </div>
              </div>
              <div className='table-responsive'>
                <table className="table table-striped-custom table-hover">
                  <thead className='table-head'>
                    <tr>
                      <th className='text-center'>S.No</th>
                      <th className='text-nowrap'>User Id</th>
                      <th>Name</th>
                      <th className='text-nowrap'>Contact Number</th>
                      <th>Role</th>
                      <th className='text-nowrap'>Last Login Time</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>

                    {staffModifideData?.staffDataList?.staff.length !== 0 ?
                      (staffModifideData?.staffDataList?.staff && staffModifideData?.staffDataList?.staff.map((data, index) => (
                        <tr key={index}>
                          <td className='text-center'>{index + 1 + (staffModifideData.currentPage - 1) * staffModifideData.pageSize}</td>
                          <td>{data.id}</td>
                          <td>{data.name}</td>
                          <td>{data.contactNumber}</td>
                          <td>
                            {data.role.charAt(0).toUpperCase() + data.role.slice(1)}
                          </td>
                          <td>{data.lastLoginTime}</td>
                          <td>
                            <div className='d-flex'>
                              <Button className='table-viewcustom-button'><Link to={`/user/edit/${data.id}`}><i className="fa fa-eye px-3" aria-hidden="true"></i></Link></Button>
                              <Button className='table-custom-button' variant="primary" onClick={() => setStaffModifideData((prs) => {
                                return {
                                  ...prs,
                                  deleteIdData: data,
                                  deleteModel: true
                                };
                              })
                              }
                              ><i className='fa fa-trash px-3'></i></Button>

                            </div>
                          </td>
                        </tr>
                      ))) :
                      <tr>
                        <td colSpan="7" className='text-center'>No data available</td>
                      </tr>}

                  </tbody>
                </table>

              </div>
              <div >
                {staffModifideData?.totalCount > 0 ?
                  <Pagination
                    className="pagination-bar"
                    currentPage={staffModifideData.currentPage}
                    totalCount={staffModifideData.totalCount}
                    pageSize={staffModifideData.pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                  :
                  ''
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={staffModifideData.deleteModel} onClick={() => setStaffModifideData((prs) => {
        return {
          ...prs,
          deleteModel: false,
          deleteIdData: null,
        }
      })}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm delete operation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete this staff <b>{staffModifideData?.deleteIdData?.name}</b>?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setStaffModifideData((prs) => {
            return {
              ...prs,
              deleteModel: false,
              deleteIdData: null,
            }
          })}>
            Close
          </Button>
          <Button className="model-save-button" onClick={() => deleteStaffData()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <NotificationModal show={showModal} onClose={handleCloseModal} /> */}
    </>
  )
}
