import { createAsyncThunk } from '@reduxjs/toolkit';
import { endPoint } from '../config/endPoint';
import { apiPost } from '../service/apiService';


export const login = createAsyncThunk("login", async (payload) => {
    const response = await apiPost(endPoint.login , payload, "");
    return response;
});

export const logout = createAsyncThunk("logout", async (payload) => {
    const response = await apiPost(endPoint.logout, payload,"")
    return response
})



