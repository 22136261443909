import { useEffect, useState } from 'react';
import candidates_icon from '../../../assests/images/candidates.png';
import N1_image from '../../../assests/images/N1_image.png';
import N2_image from '../../../assests/images/N2_image.png';
import N3_image from '../../../assests/images/N3_image.png';
import N4_image from '../../../assests/images/N4_image.png';
import N5_image from '../../../assests/images/N5_image.png';
import { useDispatch, useSelector } from 'react-redux';
import { getCandidateDetails } from './DashboardSlice';
import DatePicker from "react-datepicker";




export default function DashboardCandidate() {
    const dispatch = useDispatch()
    const [filterdate, setFilterdate] = useState(null)
    const [filterByResultValue, setFilterByResultValue] = useState("")
    const candidateData = useSelector((state) => state.DashboardResume.candidateData);

    function formatDate(date) {
        if (date) {
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${year}`;
        }
    }
    const formattedfilterdate = filterdate ? formatDate(filterdate) : '';

    const resultOptions = ["Passed", "Failed", "Awaiting results"]

    const handleFilterByResult = (e) => {
        setFilterByResultValue(e.target.value)
    }

    const handleResetFilterByResult = () => {
        setFilterByResultValue("")
    }

    useEffect(() => {
        const filter = filterdate !== null ? formattedfilterdate : ""
        const result_type = filterByResultValue
        const params = {
            filter,
            result_type
        }
        dispatch(getCandidateDetails(params));
    }, [filterdate, filterByResultValue, formattedfilterdate, dispatch])
    return (
        <>
            <div className='row mb-3'>
                <div className='row d-flex justify-content-end p-3'>
                    <div className="col-12 p-0">
                        <div className='d-flex ps-2'>

                            <div className='d-flex me-md-3'>
                                <select className="form-select text-secondary custom-filter-element" value={filterByResultValue} aria-label="Small select example" onChange={(e) => handleFilterByResult(e)}>
                                    <option value="" selected>Select result type</option>

                                    {
                                        resultOptions.map((result) => (
                                            <option key={result} value={result}>{result}</option>
                                        ))
                                    }
                                </select>

                                <button className="table-viewcustom-button p-1 btn btn-primary btn-focus-none" onClick={handleResetFilterByResult}><i class="fa fa-retweet" aria-hidden="true"></i></button>
                            </div>
                            <div className='d-flex justify-content-end '>

                                <DatePicker
                                    className="custom-filter-year"
                                    placeholderText="Select year"
                                    showYearPicker
                                    dateFormat="yyyy"
                                    selected={filterdate}
                                    onChange={(date) => {
                                        setFilterdate(date);
                                    }}
                                />
                                <button className="table-viewcustom-button p-1 btn btn-primary btn-focus-none" onClick={() => setFilterdate(null)}><i class="fa fa-retweet" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card dashboard4 p-3 d-flex flex-row align-items-center">
                        <img src={candidates_icon} alt="candidates" className="img-fluid me-3" style={{ width: '50px', height: '50px' }} />
                        <div>
                            <h5>{candidateData?.total}</h5>
                            <p>Total Students</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card dashboard1 p-3 d-flex flex-row align-items-center">
                        <img src={N1_image} alt="candidates" className="img-fluid me-3" style={{ width: '50px', height: '50px' }} />
                        <div>
                            <h5>{candidateData?.exam_counts?.N1}</h5>
                            <p>Total Students</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card dashboard2 p-3 d-flex flex-row align-items-center">
                        <img src={N2_image} alt="candidates" className="img-fluid me-3" style={{ width: '50px', height: '50px' }} />
                        <div>
                            <h5>{candidateData?.exam_counts?.N2}</h5>
                            <p>Total Students</p>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className='row mb-5'>
            <div className="col-md-4">
                    <div className="card dashboard3 p-3 d-flex flex-row align-items-center">
                        <img src={N3_image} alt="candidates" className="img-fluid me-3" style={{ width: '50px', height: '50px' }} />
                        <div>
                            <h5>{candidateData?.exam_counts?.N3}</h5>
                            <p>Total Students</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                <div className="card dashboard2 p-3 d-flex flex-row align-items-center">
                        <img src={N4_image} alt="candidates" className="img-fluid me-3" style={{ width: '50px', height: '50px' }} />
                        <div>
                            <h5>{candidateData?.exam_counts?.N4}</h5>
                            <p>Total Students</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                <div className="card dashboard4 p-3 d-flex flex-row align-items-center">
                        <img src={N5_image} alt="candidates" className="img-fluid me-3" style={{ width: '50px', height: '50px' }} />
                        <div>
                            <h5>{candidateData?.exam_counts?.N5}</h5>
                            <p>Total Students</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}