import { useSelector } from 'react-redux';
import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import Header from '../../Navbar/Header';
import Sidebar from '../sidebar/Sidebar';
import "./Jot.css"
import User_list from './User_list';

const Jot = () => {
    const [search, setSearch] = useState()
    const [filterdate, setFilterdate] = useState(null)

    const data = useSelector(state => state.Candidate);

    const renderMonthContent = (month, shortMonth, longMonth) => {
        const tooltipText = `Tooltip for month: ${longMonth}`;
        return <span title={tooltipText}>{shortMonth}</span>;
    };
    function formatDate(date) {
        if (date) {
            const month = String(date.getMonth()+1).padStart(2,'0');
            const year = date.getFullYear();
            return `${year}-${month}`;
        }
    }
    const formattedfilterdate = filterdate ? formatDate(filterdate) : '';

    return (
        <div className='container-fluid'>
            <div className='row'>
                <Header />
                <div className='col-2 p-0'>
                    <Sidebar />
                </div>
                <div className='col-10 mt-2 ps-3'>
                    <div className='card custom-card'>
                        <div className='row d-flex p-3 '>
                            <div className='d-flex justify-content-end'>
                                <DatePicker className='form-control datefilter' placeholderText='Select filter'
                                    renderMonthContent={renderMonthContent}
                                    showMonthYearPicker
                                    dateFormat="MM/yyyy"
                                    selected={filterdate}
                                    onChange={(date) => {
                                        setFilterdate(date);
                                    }}
                                />
                                <button className="table-viewcustom-button p-1 btn btn-primary" onClick={() => setFilterdate(null)}><i class="fa fa-retweet" aria-hidden="true"></i></button>
                                <div className='ms-3'>
                                    <input className='form-control search-btn' placeholder='Search' onChange={(e) => setSearch(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className='row p-3'>
                            <User_list data={data} filter={formattedfilterdate} search={search}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Jot