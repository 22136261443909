import React, { useEffect, useState } from 'react'
import Header from '../../Navbar/Header'
import Sidebar from '../sidebar/Sidebar'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import {remarksErrorLabel } from '../../../config/labels/ErrorLabel';
import { useDispatch, useSelector } from 'react-redux';
import { remarkget,  statusupate } from '../../../middleware/RemarkMiddleware';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import Pagination from '../../../component/Pagination';
import { candidateget } from '../../../middleware/CandidateMiddleware';
import Cookies from 'js-cookie';

export default function Remarks() {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [remarkdata, setRemarkdata] = useState({})
  const [remarkdate, setRemarkdate] = useState('')
  const [formikObj, setFormikObj] = useState(null);
  const [status, setStatus] = useState('');
  const [result, setResult] = useState('');
  const [employeername, setEmployeername] = useState(null);
  const role = Cookies.get("role");

  
  const candidatedata = useSelector(state => state.Candidate?.candidatedata);
  const [search, setSearch] = useState()
  const [canditateModifideData, setCanditateModifideData] = useState({
    deleteModel: false,
    deleteIdData: null,
    candidateDataList: null,
    currentPage: 1,
    totalCount: 0,
    pageSize: 10,
    search: ''
  });


  useEffect(() => {
    if (candidatedata?.status === 200) {
      setCanditateModifideData((prs) => {
        return {
          ...prs,
          candidateDataList: candidatedata?.data,
          deleteModel: false,
          deleteIdData: null,
          totalCount: candidatedata?.data?.totalCandidates,

        }
      })
    }   

  }, [candidatedata])



  useEffect(() => {
    if (canditateModifideData.currentPage) {

      getoveralldata();
    }
  }, [canditateModifideData.currentPage, search])



  const setCurrentPage = (page) => {
    setCanditateModifideData((prs) => {
      return {
        ...prs,
        currentPage: page,

      }
    })

  }


  const getoveralldata = () => {
    const obj = {};
    obj['pageNo'] = canditateModifideData.currentPage;
    obj['count'] = canditateModifideData.pageSize;
    if (search) {
      obj['searchText'] = search;
    }
    dispatch(candidateget(obj))
  }


  const handleClose = (formik) => {
    setShow(false);
    formikObj.setValues({ name: '', passportNumber: '' });
    formikObj.resetForm();
    setStatus('')
  }



  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }


  function remarksDate(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
}

  const handleDateChange = (date) => {
    setRemarkdate(date)
  };
  const datesubmit = async (data, formik) => {
    const formattedremarkdate = remarkdate ? formatDate(remarkdate) : '';
    const formData = new FormData();
    if (formattedremarkdate) {
      formData.append('releaseDate', formattedremarkdate)
    }
    formData.append('status', status)
    if (employeername) {
      formData.append('employerName', employeername)
    }
    if (result) {
      formData.append('result', result)
    }
    const param = { id: data.id, data: formData }
    let response = await dispatch(statusupate(param))

    if (response?.payload?.status === 200) {
      toast(response?.payload?.data?.message, {
        type: "success",
        autoClose: 2000
      });
    }
    setShow(false)
    formikObj.setValues({ name: '', passportNumber: '' });
    formikObj.resetForm();
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(remarksErrorLabel.username_required),
    passportNumber: Yup.string().required(remarksErrorLabel.passport_number_required)
  })

  const handlesubmit = async (values, formik) => {
    let response = await dispatch(remarkget(values))
    if (response?.payload?.status === 200) {
      setRemarkdata(response?.payload?.data?.candidate)
      setStatus(response?.payload?.data?.candidate?.status)
      setEmployeername(response?.payload?.data?.candidate?.employerName)
      setRemarkdate(response?.payload?.data?.candidate?.releaseDate)
      setShow(true)
      setFormikObj(formik);


    } else {
      toast(response?.payload?.data?.message, {
        type: "error",
        autoClose: 2000
      });
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
    setRemarkdate('')
    setEmployeername('')
  };
  const handleresult = (event) =>{
    setResult(event.target.value)
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <Header />
          <div className='col-2 p-0'>
            <Sidebar />
          </div>
          <div className='col-10 mt-2 px-3'>
            <div className='card custom-card'>
              <Formik
                initialValues={{ name: '', passportNumber: '' }}
                validationSchema={validationSchema}
                onSubmit={handlesubmit}
              >
                {(formik) => (
                  <Form>
                    <div className='row px-4 mt-2'>
                      <div className='col-sm-6'>
                        <label className='form-label'>Candidate Name</label>
                        <Field type='text' className='form-control' placeholder='Enter Candidate Name' name='name' />
                        <ErrorMessage name='name' component='div' className="text-danger" />
                      </div>
                      <div className='col-sm-6'>
                        <label className='form-label'>Candidate Passort Number</label>
                        <Field type='text' className='form-control' placeholder='Enter Candidate Passport Number' name='passportNumber' />
                        <ErrorMessage name='passportNumber' component='div' className="text-danger" />
                      </div>
                    </div>


                    <div className=' m-4'>
                      <button className="btn defult-button" type="submit">Find the User</button>
                    </div>

                  </Form>
                )}
              </Formik>
            </div>
            {role === "admin" ?
            <div className='card custom-card my-2'>
              <div className='row'>
                <div className='col-md-8'></div>

                <div className='col-md-4 d-flex justify-content-end align-items-center p-2'>

                  <input className='mx-2 form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} />
                </div>
              </div>
              <div className='table-responsive' >
                <table className="table table-striped-custom table-hover">
                  <thead className='table-head'>
                    <tr>
                      <th className='text-center'>S.No</th>
                      <th>Name</th>
                      <th className='text-nowrap'>Passport No</th>
                      <th className='text-nowrap'>Test Month</th>
                      <th>Status</th>
                      <th>Result</th>
                      <th>Employeer/Released</th>
                    </tr>
                  </thead>
                  <tbody>
                  {canditateModifideData?.candidateDataList?.candidates.length !== 0 ?
                      (canditateModifideData?.candidateDataList?.candidates && canditateModifideData?.candidateDataList?.candidates.map((data, index) => (
                        <tr key={index}>
                          <td className='text-center'>{index + 1 + (canditateModifideData.currentPage - 1) * canditateModifideData.pageSize}</td>
                          <td>{data.name}</td>
                          <td>{data.passportNumber}</td>
                          <td>{data.testMonth !== null ? remarksDate(data.testMonth) : ''}</td>
                          <td>{data.result}</td>
                          <td>{data.status}</td>
                          
                          <td>
                             {data.status === 'IPA' && data.employerName}
                            {data.status === 'RELEASED' && data.releaseDate}
                          </td>

                        </tr>
                      ))) :
                      <tr>
                        <td colSpan="6" className='text-center'>No data available</td>
                      </tr>}

                  </tbody>
                </table>
              </div>
              <div >
                {canditateModifideData?.totalCount > 0 ?
                  <Pagination
                    className="pagination-bar"
                    currentPage={canditateModifideData.currentPage}
                    totalCount={canditateModifideData.totalCount}
                    pageSize={canditateModifideData.pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                  :
                  ''
                }
              </div> 
            </div> : "" }

          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='update-status'>
            <div className='row'>
              <div className='col'>
                <label className='my-1'>Name</label>
                <p className="mb-1"><strong>{remarkdata.name}</strong></p>
              </div>
              <div className='col'>
                <label className='my-1'>Passport Number</label>
                <p className="mb-1"><strong>{remarkdata.passportNumber}</strong></p>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <label className='my-1'>Date of Birth</label>
                <p className="mb-1"><strong>{remarkdata.dob ? remarksDate(remarkdata.dob) : ''}</strong></p>
              </div>
              <div className='col'>
                <label className='my-1'>Test Month</label>
                <p className="mb-1"><strong>{remarkdata.testMonth ? remarksDate(remarkdata.testMonth):''}</strong></p>
              </div>
            </div>
            {remarkdata.testMonth !== null ?
              <div className='row'>
                <div className='col py-2'>
                  <label className='form-label'>Status </label>
                  <select className='form-select' onChange={handleStatusChange}  value={status}>
                    <option value="" disabled selected>Select the status</option>
                    <option value="IPA">IPA</option>
                    <option value="RELEASED">Released</option>
                    <option value="RETEST">Retest</option>
                    <option value="PENDING">Pending</option>
                    <option value="RESULT">Result</option>
                  </select>

                </div>
                {status === 'IPA' ?
                  <div className='col py-2'>
                    <label className='form-label'>Employeer Name</label>
                    <input className="form-control" placeholder='Enter Employeer name' onChange={(e) => setEmployeername(e.target.value)} value={employeername} />
                  </div> : ''}
                {status === 'RELEASED' ?
                  <div className='col py-2'>
                    <label className='form-label'>Release Date</label>
                    <DatePicker
                      placeholderText='Enter the Remark date'
                      className='form-control'
                      name="remarkDate"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      dateFormat='dd/MM/yyyy'
                      selected={remarkdate ? new Date(remarkdate) : null} // Updated this line
     
                      onChange={handleDateChange}
                    />
                  </div> : ''}
                  {status === 'RESULT' ?
                  <div className='col py-2'>
                    <label className='form-label'>Result</label>
                  <select className='form-select' onChange={handleresult}  value={result}>
                  <option value="" disabled selected>Select the Result</option>
                  <option value="PASS">Pass</option>
                  <option value="FAIL">Fail</option>
                </select>
                </div>
                :''}
                {status === '' || status === 'PENDING' ?
                  <div className='col'></div> : ''}

              </div> :
              <p className='text-danger'>Sorry! You cannot update the <strong>Remark value</strong> because the <strong></strong>test month is not filled.</p>}

          </div>
        </Modal.Body>


        <Modal.Footer>

          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {remarkdata.testMonth !== null ?
            <Button className="model-save-button" onClick={() => datesubmit(remarkdata)}>
              Submit
            </Button> : ''}
        </Modal.Footer>
      </Modal>


    </>
  )
}



