import { createSlice } from "@reduxjs/toolkit";
import { overallcountget } from "../middleware/DashboardMiddleware";

const DashboardSlice = createSlice({
    name: 'Dashboard',
    initialState: {
        loading: false,
        overall: null,
        error: null
    },
    extraReducers: (builder) => {
        builder


        .addCase(overallcountget.pending, (state) => {
            state.loading = true;
            state.deleteData = null
        })
        .addCase(overallcountget.fulfilled, (state, action) => {
            state.loading = false
            state.error = null;
            state.overall = action.payload
            
        })
        .addCase(overallcountget.rejected, (state, action) => {
            state.error = action.payload.error
            state.loading = false
            state.overall = null;
        })

    }
})


export default DashboardSlice.reducer