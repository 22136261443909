
import { useEffect, useState } from "react";

import ResumeData from "./ResumeData";
import { useDispatch, useSelector } from "react-redux";
import { deleteResume, getResume } from "./ResumeSlice";
import { Button } from "react-bootstrap";
// import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import { toast } from "react-toastify";
import DeleteDialog from "../../../component/resuable/deleteDialog";
import StyledDataTable from "../../../component/resuable/StyledComponent";
import Header from "../../Navbar/Header";
import Sidebar from "../sidebar/Sidebar";

import Cookies from 'js-cookie';

export default function Resume() {
    
    let role = Cookies.get("role");
    const dispatch = useDispatch();
    const [cellclicked, setCellclicked] = useState("");
    const [search, setSearch] = useState("");

    const [selectedValue, setSelectedValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [selectedData, setselectedData] = useState("");
    const [editData, setEditData] = useState("");
    const handleClick = () => {
        setCellclicked('add')
        setEditData('');
    }
    const handleBack = () => {
        setCellclicked('')
        setEditData('');
    }


    const handleDelete = async (id) => {
        const response = await dispatch(deleteResume(id));
        console.log(response);
        if (response.payload.status === 200) {
            toast.success("User Data Deleted successfully");
        }
    };


    const handleEditClick = (data) => {
        setCellclicked('edit');
        setEditData(data);
    };


    const handleDeleteClick = (data) => {
        setselectedData(data.name)
        setDeleteId(data.id);
        setEditData(data);
        setShowDeleteDialog(true);
    };


    const columns = [
        {
            name: "S.No",

            selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
            width: '6%'
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            width: '10%'
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            width: '15%'
        }, {
            name: 'Number',
            selector: (row) => row.phone,
        }, {
            name: 'Skill',
            selector: (row) => row.skill,
            width: '15%'
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            width: '15%'
        },
       
        {
            name: 'Language',
            selector: (row) => row.language,
        },
        {
            name: 'Download',
            selector: (row) => (
                <a href={row.file_download} target="_blank" rel="noopener noreferrer">
                    Download
                </a>
            ),
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                <Button variant="danger" className="table-viewcustom-button" onClick={() => handleEditClick(row)}>
                        <i className="fas fa-eye px-2"></i>
                    </Button>
                    {role === 'admin'&& (
                    <Button variant="primary"  className="table-custom-button" onClick={() => handleDeleteClick(row)} style={{ marginRight: '8px' }} >
                        <i className="fas fa-trash px-2"></i>
                    </Button>)}
                   
                </>
            ),
            button: true
        }
    ];

    const [rowData, setRowData] = useState([]);
    const candiateData = useSelector(state => state.Resume.resumeData);



    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getResume({ page: currentPage, perPage: itemsPerPage, search: search, status: selectedValue }));
        };

        fetchData();
    }, [dispatch, currentPage, itemsPerPage, search, selectedValue]);



    useEffect(() => {
        console.log(candiateData)
        if (candiateData && Array.isArray(candiateData.candidates)) {
            const formattedData = candiateData.candidates.map((user) => ({
                name: user.name,
                email: user.email,
                phone: user.phone,
                skill: user.skill,
                status: user.status,
                current_salary: user.current_salary,
                expected_salary: user.expected_salary,
                sex: user.sex,
                file_download: user.file_download,
                location: user.location,
                mode: user.mode,
                id: user.id,
                years_of_exp: user.years_of_exp,
                language: user.language,
                japanese_file_path:user.japanese_file_path
            }));
            setRowData(formattedData);
        }
    }, [candiateData]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newPerPage) => {
        setItemsPerPage(newPerPage);  // Update rows per page
        setCurrentPage(1);  // Reset to the first page when changing rows per page
    };
    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };


    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-2 px-3'>
                        <div className='card custom-card'>
                            <div className='row justify-content-center'>
                                {cellclicked === "" && (
                                    <div>
                                        <div className="col-md-12">
                                            <div className="row py-3">
                                                <div className="col-md-3">
                                                </div>
                                                <div className="col-md-5 ">
                                                    <div className="d-flex">
                                                        <input placeholder="search" className="form-control me-4" onChange={(e) => setSearch(e.target.value)} />
                                                        <select value={selectedValue} onChange={handleSelectChange} className="form-select">
                                                            <option value="" disabled selected>Select an option</option>
                                                            <option value="Immediate Joiner">Immediate Joiner</option>
                                                            <option value="Notice Period">Notice Period</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className="col-md-4 text-end">

                                                    <button className='btn defult-button defult-button-head mx-2' type="buttons" onClick={handleClick}>Add New</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-2">
                                            <StyledDataTable
                                                columns={columns}
                                                data={rowData.length > 0 ? rowData : []}
                                                highlightOnHover
                                                pagination
                                                paginationServer     // Ensure server-side pagination is enabled
                                                paginationPerPage={itemsPerPage}
                                                paginationRowsPerPageOptions={[5, 10, 15]}
                                                paginationTotalRows={candiateData.total}
                                                onChangePage={handlePageChange}  // This should trigger when the page changes
                                                onChangeRowsPerPage={handleRowsPerPageChange}
                                                responsive
                                                persistTableHead={true}
                                            />




                                        </div>
                                    </div>
                                )}

                                {cellclicked === "add" && (
                                    <ResumeData handleBack={handleBack} />
                                )}
                                {cellclicked === "edit" && (
                                    <ResumeData handleBack={handleBack} editData={editData} />
                                )}
                                {showDeleteDialog && deleteId && (
                                    <DeleteDialog
                                        id={deleteId}
                                        data={selectedData}
                                        onDelete={handleDelete}
                                        onClose={() => setShowDeleteDialog(false)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
