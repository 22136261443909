import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiDelete, apiGet, apiPost, apiPut } from "../../../service/apiService";



const initialState = {
  Loading: false,
  companyData: [],
  employeeData: []

};


export const addCompany = createAsyncThunk(
  'hrms/addCompany',
  async (param, { rejectWithValue }) => {
    try {
      const response = await apiPost('company', param, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateCompany = createAsyncThunk('hrms/updateCompany', async (companyData, thunkAPI) => {
  try {
    const response = await apiPut(`company/${companyData.company_id}`, companyData); // Assuming your API uses PUT
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
});



export const getCompany = createAsyncThunk(
  'hrms/getCompany',
  async (params, { rejectWithValue }) => {
    try {
      const { month,year} = params;
      const response = await apiGet(`company?month=${month}&year=${year}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



export const deleteCompany = createAsyncThunk(
  'hrms/deleteCompany',
  async (id) => {
    try {
      const response = await apiDelete(`company/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const deletecompanyPayroll = createAsyncThunk(
  'hrms/deletePayroll',
  async (id) => {
    try {
      const response = await apiDelete(`payrun/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const salaryCalculator = createAsyncThunk(
  'hrms/salaryCalculator',
  async (amount, { rejectWithValue }) => {
    try {
      const response = await apiPost(`calculate_salary`, {
        amount  // Wrap the amount in a data object
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



// employee details

export const addEmployee = createAsyncThunk(
  'hrms/addEmployee',
  async (param, { rejectWithValue }) => {
    try {
      const response = await apiPost(`employee`, param);
     
      return response;
    } catch (error) {
     
        return rejectWithValue('Error: ' + error.message);
    
    }
  }
);
export const getEmployee = createAsyncThunk(
  'hrms/getEmployee',
  async (params, { rejectWithValue }) => {
    try {
      const { id,page, perPage,month,year,search} = params;

      let url = `company/employees/${id}?page=${page}&per_page=${perPage}`;

      if (month) {
          url += `&month=${month}`;
      }
      if (year) {
          url += `&year=${year}`;
      }
      if (search) {
          url += `&search=${search}`;
      }

      const response = await apiGet(url);
     
      return response;
    } catch (error) {
     
        return rejectWithValue('Error: ' + error.message);
    
    }
  }
);

export const deletEmployee = createAsyncThunk(
  'hrms/deletEmployee',
  async (id,{ rejectWithValue }) => {
    try {
      const response = await apiDelete(`employee/${id}`);
      return response;
    } catch (error) {
      return rejectWithValue('Error: ' + error.message);
    }
  }
);


export const udpateEmployee = createAsyncThunk(
  'hrms/udpateEmployee',
  async ({ data, employee_id }, { rejectWithValue }) => { // Destructure the parameters
    try {
      const response = await apiPut(`employee/${employee_id}`, data); // Use the destructured data
      return response; 
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const employeeBulkAdd = createAsyncThunk(
  'hrms/employeeBulkAdd',
  async ({ formData, companyId }, { rejectWithValue }) => {
    try {
      const response = await apiPost(`employees/bulk_upload/${companyId}`, formData);
      return response; 
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const updateattendance = createAsyncThunk(
  'hrms/updateattendance',
  async ({ formData,param }, { rejectWithValue }) => {
    const { month,year} = param;
    try {
      const response = await apiPost(`attendances/bulk_upload?month=${month}&year=${year}`, formData);
      return response; 
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



export const submitPaySlip = createAsyncThunk(
  'hrms/submitPaySlip',
  async ({ company_id }, { rejectWithValue }) => {
    try {
      const response = await apiGet(`send_payslip_emails?company_id=${company_id}`);
      return response; 
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


const HRMS = createSlice({
  name: 'HRMS',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.Loading = false;
        state.companyData = action.payload
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.Loading = false;
      })

      .addCase(addCompany.pending, (state) => {
        state.Loading = true;
      })
      .addCase(addCompany.fulfilled, (state, action) => {
        state.Loading = false;
        if (action.payload.data && action.payload.data.company_name) {
          state.companyData = {
            ...state.companyData,
            companies: [
              ...state.companyData.companies,
              action.payload.data
            ]
          };
        }

      })

      .addCase(addCompany.rejected, (state) => {
        state.Loading = false;

      })

      .addCase(deleteCompany.pending, (state) => {
        state.Loading = true;
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.Loading = false;
        if(action.payload.status === 200){

        const deletedCompanyId = action.payload.data.company_id;
        state.companyData.companies = state.companyData.companies.filter(company => company.company_id !== deletedCompanyId);}
      })

      .addCase(deleteCompany.rejected, (state) => {
        state.Loading = false;
      })




      .addCase(updateCompany.fulfilled, (state, action) => {
        state.Loading = false;
        const updatedCompany = action.payload.data;
        if (action.payload.request.status === 200) {
          state.companyData.companies = state.companyData.companies.map(company =>
            company.company_id === updatedCompany.company_id ? updatedCompany : company
          );
        }
      })
      .addCase(updateCompany.rejected, (state) => {
        state.Loading = false;
      })



      .addCase(addEmployee.pending, (state) => {
        state.Loading = true;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.Loading = false;
        if (action?.payload?.status === 201) {
          state.employeeData = {
            ...state.employeeData,
            employees: [
              ...state?.employeeData?.employees,
              action?.payload?.data?.employee
            ]
          };
        }
       


        
      })
      .addCase(addEmployee.rejected, (state) => {
        state.Loading = false;
      })
      .addCase(getEmployee.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.Loading = false;
        state.employeeData = action.payload.data;
      })
      .addCase(getEmployee.rejected, (state) => {
        state.Loading = false;
      })



      .addCase(deletEmployee.pending, (state) => {
        state.Loading = true;
      })
      .addCase(deletEmployee.fulfilled, (state, action) => {
        state.Loading = false;
        if(action.payload.status === 200 ){
        const deleteEmployeeId = action.payload.data.employee_id;
        state.employeeData.employees = state.employeeData.employees.filter(employee => employee.employee_id !== deleteEmployeeId);
      }
    })
    
      .addCase(deletEmployee.rejected, (state) => {
        state.Loading = false;
      })

      .addCase(udpateEmployee.pending, (state) => {
        state.Loading = true;
      })
      .addCase(udpateEmployee.fulfilled, (state, action) => {
        state.Loading = false;
        const updateCandiate = action.payload.data;
        state.employeeData = {
          ...state.employeeData,
          employees: state.employeeData.employees.map(employee =>
              employee.employee_id === updateCandiate.employee_id ? updateCandiate : employee
          )
      };
      })

      .addCase(udpateEmployee.rejected, (state) => {
        state.Loading = false;
      })









      
  }
})


export default HRMS.reducer;