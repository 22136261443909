import React from 'react';
import DataTable, { TableProps } from 'react-data-table-component';

// Define the custom styles
const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#E2E8F0', 
            color: '#212529', 
            fontWeight: 'bold', 
            fontSize: '16px'
        }
    },
    rows: {
        style: {
            fontSize: '16px' // Set font size for row cells
        }
    },
    
   
    pagination: {
        style: {
            borderTop: '1px solid #ddd', 
        }
    }
};

// Define a type for the props


const StyledDataTable = ({
    columns,
    data,
    customClassName = '',
    ...rest
}) => {
    return (
        <DataTable
        
            columns={columns}
            data={data}
            customStyles={customStyles}
            
            className={`custom-data-table ${customClassName}`}
            {...rest}
        />
    );
};

export default StyledDataTable;
