// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-bg{
    position: fixed;
    overflow: hidden;
    height: 100%; 
    background-color: #2F3C4E;
    width: 16.5%;
    top: 75px;
}
.sidebar-bg ul li p {
  cursor: pointer;
}
.sidebar-bg ul li a, .sidebar-bg ul li p{
    color: #fff;
    text-decoration: none;
    line-height: 3rem;
}
.submenu li a {
 line-height: 2.20rem !important;
}
.sidebar-bg ul li i {
    color:#fff
}
.sidebar-bg ul{
    list-style: none;     
    padding: 0;
}

.sidebar-bg ul li.active, .sidebar-bg ul li p.active {
    background-color: #B32523;
  }

.sidebar.open li span {
    display: none;
} 
.submenu {
  background: #2F3C4E;
}

@media (max-width: 1200px) {
  .sidebar-bg {
    width: 18%
  }
}
@media (max-width: 768px) {
  .sidebar-bg li span{
    display: none;
  }
  .sidebar-bg {
    width: 10%
  }
}
  @media (max-width: 480px) {
    .sidebar-bg {
      width: 15%
    }
    .sidebar-bg li span{
      display: none;
    }
    .sidebar-bg ul li i {
        font-size: 20px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/layouts/sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,SAAS;AACb;AACA;EACE,eAAe;AACjB;AACA;IACI,WAAW;IACX,qBAAqB;IACrB,iBAAiB;AACrB;AACA;CACC,+BAA+B;AAChC;AACA;IACI;AACJ;AACA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,yBAAyB;EAC3B;;AAEF;IACI,aAAa;AACjB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE;EACF;AACF;AACA;EACE;IACE,aAAa;EACf;EACA;IACE;EACF;AACF;EACE;IACE;MACE;IACF;IACA;MACE,aAAa;IACf;IACA;QACI,eAAe;IACnB;EACF","sourcesContent":[".sidebar-bg{\r\n    position: fixed;\r\n    overflow: hidden;\r\n    height: 100%; \r\n    background-color: #2F3C4E;\r\n    width: 16.5%;\r\n    top: 75px;\r\n}\r\n.sidebar-bg ul li p {\r\n  cursor: pointer;\r\n}\r\n.sidebar-bg ul li a, .sidebar-bg ul li p{\r\n    color: #fff;\r\n    text-decoration: none;\r\n    line-height: 3rem;\r\n}\r\n.submenu li a {\r\n line-height: 2.20rem !important;\r\n}\r\n.sidebar-bg ul li i {\r\n    color:#fff\r\n}\r\n.sidebar-bg ul{\r\n    list-style: none;     \r\n    padding: 0;\r\n}\r\n\r\n.sidebar-bg ul li.active, .sidebar-bg ul li p.active {\r\n    background-color: #B32523;\r\n  }\r\n\r\n.sidebar.open li span {\r\n    display: none;\r\n} \r\n.submenu {\r\n  background: #2F3C4E;\r\n}\r\n\r\n@media (max-width: 1200px) {\r\n  .sidebar-bg {\r\n    width: 18%\r\n  }\r\n}\r\n@media (max-width: 768px) {\r\n  .sidebar-bg li span{\r\n    display: none;\r\n  }\r\n  .sidebar-bg {\r\n    width: 10%\r\n  }\r\n}\r\n  @media (max-width: 480px) {\r\n    .sidebar-bg {\r\n      width: 15%\r\n    }\r\n    .sidebar-bg li span{\r\n      display: none;\r\n    }\r\n    .sidebar-bg ul li i {\r\n        font-size: 20px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
