import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiDelete, apiGet, apiPost, apiPut } from "../../../../service/apiService";
import axios from "axios";
import { toast } from "react-toastify";



const initialState = {
  Loading: false,
  holidayData: [],



};


export const addholiday = createAsyncThunk(
  'hrms/addholiday',
  async (param, { rejectWithValue }) => {
    try {
      const response = await apiPost('holidays', param);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

export const getHoliday = createAsyncThunk(
    'hrms/getHoliday',
    async (_,{ rejectWithValue }) => {
      try {
        const response = await apiGet(`holidays`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

export const updateHoliday = createAsyncThunk('hrms/updateHoliday', async (holldayData, thunkAPI) => {
  try {
    const response = await apiPut(`holidays/${holldayData.holiday_id}`, holldayData); // Assuming your API uses PUT
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
});


export const deleteHoliday= createAsyncThunk(
  'hrms/deleteHoliday',
  async (id) => {
    try {
      const response = await apiDelete(`holidays/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}
const HOLIYDAY = createSlice({
  name: 'HOLIYDAY',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHoliday.pending, (state) => {
        state.Loading = true;
      })
      .addCase(getHoliday.fulfilled, (state, action) => {
        state.Loading = false;
        state.holidayData = action.payload
      })
      .addCase(getHoliday.rejected, (state, action) => {
        state.Loading = false;
      })


     

      .addCase(addholiday.pending, (state) => {
        state.Loading = true;
      })
      .addCase(addholiday.fulfilled, (state, action) => {
        state.Loading = false;
        if(action.payload.status === 201){
            const holidayDate = new Date(action.payload.data.holiday_date);
            const formattedDate = formatDate(holidayDate);
            const newHoliday = {
                ...action.payload.data,
                holiday_date: formattedDate,
            };
    
            state.holidayData.push(newHoliday);
        }
      })

      .addCase(addholiday.rejected, (state) => {
        state.Loading = false;

      })

      .addCase(deleteHoliday.pending, (state) => {
        state.Loading = true;
      })
      .addCase(deleteHoliday.fulfilled, (state, action) => {
        state.Loading = false;
        const deletedCompanyId = action.payload.data.holiday_id;
        state.holidayData = state.holidayData.filter(company => company.holiday_id !== deletedCompanyId);
      })

      .addCase(deleteHoliday.rejected, (state) => {
        state.Loading = false;
      })

      .addCase(updateHoliday.fulfilled, (state, action) => {
        state.Loading = false;
        const updatedCompany = action.payload.data;
        if (action.payload.request.status === 200) {
          state.holidayData = state.holidayData.map(holiday =>
            holiday.holiday_id === updatedCompany.holiday_id ? updatedCompany : holiday
          );
        }
      })
      .addCase(updateHoliday.rejected, (state) => {
        state.Loading = false;
      })      
  }
})


export default HOLIYDAY.reducer;