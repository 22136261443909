import React, { useEffect, useState } from "react";
import Header from "../Navbar/Header";
import Sidebar from "../layouts/sidebar/Sidebar";

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { staffUpdate, staffgetbyid } from "../../middleware/StaffMiddleware";
import { candidateErrorLabel } from "../../config/labels/ErrorLabel";
import { useNavigate } from "react-router-dom";
import { clearstaff } from "../../store/StaffSlice";

export default function Profile() {
    const dispatch = useDispatch();
    const [password, setPassword] = useState('')
    
  const navigate = useNavigate()
    const [userData, setuserData] = useState({
        id: '',
        name: '',
        password: '',
        contactNumber: '',
        profile_image: null,
    });
    const staffSchema = Yup.object().shape({

        name: Yup.string().required(candidateErrorLabel.name_required),
    })
    const userProfile = useSelector((state) => state.Staffdata.getstaff);
    
  const staffDataupdate = useSelector(state => state.Staffdata.updatestaff);


    useEffect(() => {
        dispatch(staffgetbyid('admin'));
    }, [])

    useEffect(() => {
        if (userProfile?.status === 200) {

            setuserData(userProfile?.data)
            const standardizedBase64 = userProfile?.data?.password.replace(/-/g, "+").replace(/_/g, "/");
            setPassword(atob(standardizedBase64));
        }
    }, [userProfile])

   



    const Updatestaff =  (values) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('contactNumber', values.contactNumber);
        if (values.profileImage) {
          formData.append('profileImage', values.profileImage); // Append the image file
        }
        formData.append('password',password)
        formData.append('profile_image',values.profile_image)
        const param = { id: 'admin', data: formData }
        dispatch(staffUpdate(param));
        

    }


    useEffect(() => {
        if (staffDataupdate?.status === 200) {
          toast(staffDataupdate?.data?.message, {
            type: "success"
          });

          navigate(-1);
        } else {
          if (staffDataupdate?.response?.status === 400) {
            toast(staffDataupdate?.response?.data?.message, {
              type: "error"
            });
          }
        }
      }, [staffDataupdate])
    const cancelsubmit = () => {
        navigate(-1);
      }
    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>
                    <Header />
                    <div className='col-2 p-0'>
                        <Sidebar />
                    </div>
                    <div className='col-10 mt-3 px-3'>
                        <div className='card custom-card'>
                            <div className='row mb-3 mt-2'>
                                <Formik

                                    initialValues={userData}
                                    enableReinitialize
                                    validationSchema={staffSchema}

                                    onSubmit={Updatestaff}
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>User Id</label>
                                                    <Field placeholder='User Id' className='form-control' name="name" value={values.id} disabled />
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Name</label>
                                                    <Field placeholder='Enter the name' className='form-control' name="name" value={values.name} />
                                                </div>
                                            </div>
                                            <div className='row px-4 mt-0 mt-sm-2'>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Password</label>
                                                    <input className='form-control' value={password}  onChange={(event)=> setPassword(event.target.value)}/>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Contact Number</label>
                                                    <Field placeholder='Enter the contact Number' className='form-control' value={values.contactNumber} />
                                                </div>
                                            </div>

                                            <div className='row px-4 mt-0 mt-sm-2'>

                                                <div className='col-sm-6 mb-2 mb-sm-0'>
                                                    <label className='form-label'>Image</label>
                                                    <input
                                                            type="file"
                                                            className='form-control'
                                                            onChange={(event) => {
                                                                setFieldValue('profile_image', event.currentTarget.files[0]);
                                                            }}
                                                        />
                                                    <div className='col profileimage'>

                                                    </div>
                                                </div>
                                                <div className='col-sm-6 mb-2 mb-sm-0'>

                                                </div>
                                            </div>



                                            <div className='px-4 mt-3'>
                                                <button className='cancel-button ms-2' onClick={cancelsubmit}>Cancel</button>
                                                <button className="btn defult-button ms-2" type='submit'>Submit</button>

                                            </div>
                                        </Form>)}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
