import { createSlice } from '@reduxjs/toolkit'
import { staffadd, staffDelete, staffget, staffgetbyid, staffUpdate } from '../middleware/StaffMiddleware'

const StaffSlice = createSlice({
    name: 'Staff',
    initialState: {
        loading: false,
        staff: null,
        error: null,
        deleteData: null,
        getstaff : null,
        updatestaff:null
    },
    reducers: {
        clearstaff: (state) => {
            state.staff = null;
            state.updatestaff = null
            state.getstaff = null
            state.deleteData = null
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(staffadd.pending, (state) => {
                state.loading = true;
            })

            .addCase(staffadd.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.staff = action.payload;
            })
            .addCase(staffadd.rejected, (state, action) => {
                state.loading = false;
                state.staff = null;
                state.error = action.payload;
            })



            .addCase(staffget.pending, (state) => {
                state.loading = true;
                state.deleteData = null
            })
            .addCase(staffget.fulfilled, (state, action) => {
                state.deleteData = null
                state.loading = false
                state.error = null;
                state.staff = action.payload
                
                state.updatestaff = null
                state.getstaff = null
            })
            .addCase(staffget.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
                state.staff = null;
            })


            .addCase(staffDelete.pending, (state) => {
                state.loading = true
                state.deleteData = null
            })

            .addCase(staffDelete.fulfilled, (state, action) => {
                state.loading = false;
                state.deleteData = action.payload
            })
            .addCase(staffDelete.rejected, (state, action) => {
                state.error = action.payload.error
                state.loading = false
            })



            
            .addCase(staffUpdate.pending, (state) => {
                state.loading = true
            })

            .addCase(staffUpdate.fulfilled, (state, action) => {
                state.loading = false;
                state.updatestaff = action.payload
                state.getstaff = null
                state.error = null
            })
            .addCase(staffUpdate.rejected, (state, action) => {
                state.error = action.payload.error
            })



            .addCase(staffgetbyid.pending, (state)=>{
                state.loading = true;
            })
            .addCase(staffgetbyid.fulfilled,(state,action)=>{
                state.loading = true
                state.getstaff = action.payload
                state.error = null
                state.updatestaff=null
            })
            .addCase(staffgetbyid.rejected,(state,action)=>{
                state.error = action.payload.message
            })



    }
})



export const { clearstaff } = StaffSlice.actions


export default StaffSlice.reducer
